import { debounce } from 'lodash';

import { pressKey } from '../../utils/keyboard';

export const appAdjustDestinationPath = (returnValue, type, params) => {
  if ('same_index_same_level' === type) {
    const { flattenedTree, sourceIndex, level, sourcePath } = params;
    if (level === sourcePath.length - 1) {
      // moved exactly one level to the left
      const itemId = flattenedTree[sourceIndex].item.id;

      const triggerMove = debounce(
        () => {
          const item = document.querySelector(
            '.item[data-item-id="' + itemId + '"]'
          );
          if (item) {
            const inputEl = item.querySelector('input[name*="name"]');
            if (inputEl) {
              // Shift + Tab
              // keyCode 9 = Tab
              pressKey(inputEl, 9, 'shift');
              inputEl.focus();
            }
          }
        },
        100,
        { maxWait: 500 }
      );

      triggerMove();
    }
  }

  return returnValue;
};
