//import { /*Link,*/ Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { del } from '../../actions/category/delete';
import { reset, retrieve, update } from '../../actions/category/update';
import { checkCurrentUserCan } from '../CurrentUserCan';
import TreeForm from './TreeForm';

class UpdateTree extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    retrieveLoading: PropTypes.bool.isRequired,
    retrieveError: PropTypes.string,
    updateLoading: PropTypes.bool.isRequired,
    updateError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleteError: PropTypes.string,
    updated: PropTypes.object,
    deleted: PropTypes.object,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent('/categories/root'));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  render() {
    /*
    if (this.props.deleted) return <Redirect to=".." />;
    */

    const item = this.props.updated ? this.props.updated : this.props.retrieved;

    return (
      <div className={'update-category-tree'}>
        {/*
        <h1>Edit {item && item['@id']}</h1>
        */}

        {/*
        {this.props.created && (
          <div className="alert alert-success" role="status">
            {this.props.created['@id']} created.
          </div>
        )}
        */}
        {/*
        {this.props.created && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <i className="fa fa-check"></i>
              </div>
            </div>
          </div>
        )}
        */}
        {/*
        {this.props.updated && (
          <div className="alert alert-success" role="status">
            {this.props.updated['@id']} updated.
          </div>
        )}
        */}
        {/*
        {this.props.updated && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <i className="fa fa-check"></i>
              </div>
            </div>
          </div>
        )}
        */}
        {/*
        {(this.props.retrieveLoading ||
          this.props.updateLoading ||
          this.props.deleteLoading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        */}
        {(this.props.retrieveLoading ||
          // this.props.updateLoading ||
          this.props.deleteLoading) && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                Loading...
              </div>
            </div>
          </div>
        )}
        {this.props.retrieveError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.retrieveError}
          </div>
        )}
        {this.props.updateError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.updateError}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.deleteError}
          </div>
        )}

        {item && (
          <TreeForm
            onSubmit={values =>
              checkCurrentUserCan(
                this.props.currentUserAccount,
                'category:edit'
              )
                ? this.props.update(item, values)
                : values
            }
            initialValues={item}
            displayAddButton={this.props.displayAddButton}
            displayEditItemButton={this.props.displayEditItemButton}
            displayDeleteItemButton={this.props.displayDeleteItemButton}
            displaySelectItemButton={this.props.displaySelectItemButton}
            lastSelectedFieldIndex={this.props.lastSelectedFieldIndex}
            selectRootItem={this.props.selectRootItem}
            selectTreeItem={this.props.selectTreeItem}
            trackSelectTreeItem={this.props.trackSelectTreeItem}
            allowEnterKey={this.props.allowEnterKey}
            dragOverTreeItem={this.props.dragOverTreeItem}
            dropTreeItem={this.props.dropTreeItem}
            isDraggingEnabled={this.props.isDraggingEnabled}
          />
        )}
        {/*
        <Link to=".." className="btn btn-primary">
          Back to list
        </Link>
        <button onClick={this.del} className="btn btn-danger">
          Delete
        </button>
        */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUserAccount: state.account.show.retrieved,
  retrieved: state.category.update.retrieved,
  retrieveError: state.category.update.retrieveError,
  retrieveLoading: state.category.update.retrieveLoading,
  updateError: state.category.update.updateError,
  updateLoading: state.category.update.updateLoading,
  deleteError: state.category.del.error,
  deleteLoading: state.category.del.loading,
  eventSource: state.category.update.eventSource,
  created: state.category.create.created,
  deleted: state.category.del.deleted,
  updated: state.category.update.updated
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTree);
