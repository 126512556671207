import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Col';
import Col from 'react-bootstrap/Col';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  connectServiceRedirectLanding,
  responseFacebook,
  responseGoogle
} from '../../actions/account/connectService';
import { login, reset } from '../../actions/account/login';
import { FB_ID } from '../../config/facebook';
import { GOOGLE_ID } from '../../config/google';
import { getLoginRedirectPath, isLoggedIn } from '../../utils/auth';
import { VersionLink as Link } from '../VersionLink';
import LoginForm from './LoginForm';

class Login extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    loggedin: PropTypes.object,
    login: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    connectServiceRedirectLanding();

    if (this.props.loggedin || isLoggedIn())
      return <Redirect to={getLoginRedirectPath()} />;

    return (
      <Row>
        <Col
          sm={{ span: 8, offset: 2 }}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 4, offset: 4 }}
        >
          <Card className={'mt-5'}>
            <Card.Body>
              <Card.Title>Sign In to your Lista Account</Card.Title>
              <Card.Subtitle className={'mb-4'}>
                <Link to="/register" className={'small card-link'}>
                  or create your account
                </Link>
              </Card.Subtitle>

              {this.props.loading && (
                <div className="alert alert-info" role="status">
                  Loading...
                </div>
              )}
              {this.props.error && (
                <div className="alert alert-danger" role="alert">
                  <span
                    className="fa fa-exclamation-triangle"
                    aria-hidden="true"
                  />{' '}
                  {this.props.error}
                </div>
              )}

              <LoginForm onSubmit={this.props.login} values={this.props.item} />

              {!!FB_ID && (
                <FacebookLogin
                  appId={FB_ID}
                  autoLoad={false}
                  fields="id,first_name,last_name,name,email,picture.type(large)"
                  callback={this.props.responseFacebook}
                  size="small"
                  icon="fa-facebook-square"
                  buttonStyle={{ textTransform: 'inherit', width: '100%' }}
                />
              )}

              {!!GOOGLE_ID && (
                <GoogleLogin
                  clientId={GOOGLE_ID}
                  buttonText="Login with Google"
                  onSuccess={this.props.responseGoogle}
                  onFailure={this.props.responseGoogle}
                  className={'btn-block mt-3'}
                />
              )}

              <Link to={'/resetting/request'} className={'small card-link'}>
                Forgot Password?
              </Link>
            </Card.Body>
            {/*
          <Card.Footer className={'text-center'}>
            <Link to="/terms" className={'small card-link'}>
              Terms of Service
            </Link>
            <Link to="/privacy" className={'small card-link'}>
              Privacy Policy
            </Link>
          </Card.Footer>
          */}
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const { loggedin, error, loading } = state.account.login;
  return { loggedin, error, loading };
};

const mapDispatchToProps = dispatch => ({
  responseFacebook: response => dispatch(responseFacebook(response)),
  responseGoogle: response => dispatch(responseGoogle(response)),
  login: values => dispatch(login(values)),
  reset: () => dispatch(reset())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
