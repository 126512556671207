import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { del } from '../../actions/usedprocedure/delete';
import { exportPdfUsedProcedure } from '../../actions/usedprocedure/exportPdf';
import { reset, retrieve, update } from '../../actions/usedprocedure/update';
import Form from './Form';

class Update extends Component {
  state = {
    usedProcedureModals: {},
    isSticky: false
  };

  static propTypes = {
    retrieved: PropTypes.object,
    retrieveLoading: PropTypes.bool.isRequired,
    retrieveError: PropTypes.string,
    updateLoading: PropTypes.bool.isRequired,
    updateError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleteError: PropTypes.string,
    updated: PropTypes.object,
    deleted: PropTypes.object,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    window.removeEventListener('scroll', () => this.handleScroll);
  }

  handleScroll = () => {
    if (this._stickyHeader) {
      this.setState({
        isSticky: window.scrollY > 86
        //isSticky: this._stickyHeader.getBoundingClientRect().top <= 0
      });
    }
  };

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  delNow = (item, event) => {
    event.target.disabled = true;
    event.target.innerHTML += '<span class="fa fa-spinner fa-spin"></span>';

    this.props.del(item);
  };

  setShow = (usedProcedure, value) => {
    this.setState({
      usedProcedureModals: {
        ...this.state.usedProcedureModals,
        [usedProcedure['@id']]: value
      }
    });
  };

  handleClose = usedProcedure => this.setShow(usedProcedure, false);

  handleShow = usedProcedure => this.setShow(usedProcedure, true);

  exportPdfUsedProcedure = (item, event) => {
    event.preventDefault();
    this.props.exportPdfUsedProcedure(item);
  };

  render() {
    if (this.props.deleted) return <Redirect to=".." />;

    const item = this.props.updated ? this.props.updated : this.props.retrieved;

    return (
      <div className={'update-used-procedure mt-3'}>
        {/*
        <h1>Edit {item && item['@id']}</h1>
        */}

        {/*
        {this.props.created && (
          <div className="alert alert-success" role="status">
            {this.props.created['@id']} created.
          </div>
        )}
        */}
        {/*
        {this.props.updated && (
          <div className="alert alert-success" role="status">
            {this.props.updated['@id']} updated.
          </div>
        )}
        */}
        {/*
        {(this.props.retrieveLoading ||
          this.props.updateLoading ||
          this.props.deleteLoading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        */}
        {(this.props.retrieveLoading ||
          //this.props.updateLoading ||
          this.props.deleteLoading) && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                Loading...
              </div>
            </div>
          </div>
        )}
        {this.props.retrieveError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.retrieveError}
          </div>
        )}
        {this.props.updateError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.updateError}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.deleteError}
          </div>
        )}

        <div
          className={`form-actions-wrapper${
            this.state.isSticky ? ' yellow-sticky' : ''
          }`}
          ref={ref => (this._stickyHeader = ref)}
        >
          <div className={'form-actions'}>
            {/*
            <Link to=".." className="btn btn-primary pull-left">
              All Procedures In Use
            </Link>
            */}
            <button
              onClick={() =>
                this.handleShow({
                  '@id': 'delete_' + item['@id']
                })
              }
              className="btn btn-danger pull-right"
            >
              Delete
            </button>
            <Modal
              show={
                (item &&
                  this.state.usedProcedureModals['delete_' + item['@id']]) ||
                false
              }
              onHide={() =>
                this.handleClose({
                  '@id': 'delete_' + item['@id']
                })
              }
              centered
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Are you sure you want to delete this Active Procedure?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>This will delete:</p>
                <ul>
                  <li>This Active Procedure</li>
                  <li>All Recorded Progress in this Active Procedure</li>
                </ul>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className={'btn'}
                  onClick={() =>
                    this.handleClose({
                      '@id': 'delete_' + item['@id']
                    })
                  }
                >
                  Cancel
                </button>
                <button
                  className={'btn btn-danger'}
                  onClick={event => this.delNow(item, event)}
                >
                  Delete Active Procedure
                </button>
              </Modal.Footer>
            </Modal>

            {/*<button*/}
            {/*  onClick={event => this.exportPdfUsedProcedure(item, event)}*/}
            {/*  className="btn btn-info btn-yellow"*/}
            {/*>*/}
            {/*  <span className={"fa fa-download"}></span>*/}
            {/*  Export PDF*/}
            {/*</button>*/}
          </div>
        </div>

        {item && (
          <Form
            onSubmit={values => this.props.update(item, values)}
            initialValues={item}
            exportPDFButton={
              <button
                onClick={event => this.exportPdfUsedProcedure(item, event)}
                className="btn btn-info btn-yellow export-pdf-button"
              >
                <span className={'fa fa-download'}></span>
                Export PDF
              </button>
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.usedprocedure.update.retrieved,
  retrieveError: state.usedprocedure.update.retrieveError,
  retrieveLoading: state.usedprocedure.update.retrieveLoading,
  updateError: state.usedprocedure.update.updateError,
  updateLoading: state.usedprocedure.update.updateLoading,
  deleteError: state.usedprocedure.del.error,
  deleteLoading: state.usedprocedure.del.loading,
  eventSource: state.usedprocedure.update.eventSource,
  created: state.usedprocedure.create.created,
  deleted: state.usedprocedure.del.deleted,
  updated: state.usedprocedure.update.updated
});

const mapDispatchToProps = dispatch => ({
  exportPdfUsedProcedure: item => dispatch(exportPdfUsedProcedure(item)),
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(Update);
