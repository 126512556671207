import { CardElement } from '@stripe/react-stripe-js';
import React, { Component } from 'react';

class StripeCreditCardForm extends Component {
  handleSubmit = async event => {
    // Block native form submission.
    event.preventDefault();

    const { stripe, stripeElements } = this.props;

    if (!stripe || !stripeElements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = stripeElements.getElement(CardElement);

    const form = event.target;

    this.onPaymentSubmit(event);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          line1: (form.line1 && form.line1.value) || undefined,
          line2: (form.line2 && form.line2.value) || undefined,
          city: (form.city && form.city.value) || undefined,
          state: (form.state && form.state.value) || undefined,
          postal_code: (form.postal_code && form.postal_code.value) || undefined
        },
        name: (form.name && form.name.value) || undefined
      }
    });

    if (error) {
      return this.paymentNotUpdated(error, paymentMethod);
    } else {
      this.onPreAttachPaymentMethod(event, paymentMethod);
      return this.attachPaymentMethod(paymentMethod).then(retrieved => {
        cardElement.clear();
        this.onPrePaymentUpdated(retrieved, paymentMethod);
        return this.paymentUpdated(retrieved, paymentMethod);
      });
    }
  };

  onPaymentSubmit = event => {
    if (typeof this.props.onPaymentSubmit === 'function') {
      return this.props.onPaymentSubmit(event);
    }

    return true;
  };

  onPreAttachPaymentMethod = (event, paymentMethod) => {
    if (typeof this.props.onPreAttachPaymentMethod === 'function') {
      return this.props.onPreAttachPaymentMethod(event, paymentMethod, this);
    }

    return true;
  };

  attachPaymentMethod = paymentMethod => {
    if (typeof this.props.attachPaymentMethod === 'function') {
      return this.props.attachPaymentMethod(paymentMethod);
    }

    return new Promise((resolve, reject) => resolve(null));
  };

  onPrePaymentUpdated = (retrieved, paymentMethod) => {
    if (typeof this.props.onPrePaymentUpdated === 'function') {
      return this.props.onPrePaymentUpdated(retrieved, paymentMethod, this);
    }

    return true;
  };

  paymentUpdated = (retrieved, paymentMethod) => {
    if (typeof this.props.paymentUpdated === 'function') {
      return this.props.paymentUpdated(retrieved, paymentMethod);
    }

    return true;
  };

  paymentNotUpdated = (error, paymentMethod) => {
    if (typeof this.props.paymentNotUpdated === 'function') {
      return this.props.paymentNotUpdated(error, paymentMethod);
    }

    return false;
  };

  isPaymentSubmitting = () => {
    if (typeof this.props.isPaymentSubmitting === 'function') {
      return this.props.isPaymentSubmitting();
    }

    return false;
  };

  isPaymentSuccess = () => {
    if (typeof this.props.isPaymentSuccess === 'function') {
      return this.props.isPaymentSuccess();
    }

    return false;
  };

  isPaymentError = () => {
    if (typeof this.props.isPaymentError === 'function') {
      return this.props.isPaymentError();
    }

    return false;
  };

  cardElementOptions = () => {
    if (typeof this.props.cardElementOptions === 'function') {
      return this.props.cardElementOptions();
    }

    return {
      hidePostalCode: true
    };
  };

  cardElementContainerProps = () => {
    const defaultProps = {
      className: 'credit-card-field',
      style: { width: '100%' }
    };

    if (typeof this.props.cardElementContainerProps === 'function') {
      return this.props.cardElementContainerProps(defaultProps);
    }

    return defaultProps;
  };

  render() {
    const { stripe } = this.props;
    const isPaymentSubmitting = this.isPaymentSubmitting();
    const isPaymentSuccess = this.isPaymentSuccess();
    const isPaymentError = this.isPaymentError();
    const cardElementOptions = this.cardElementOptions();
    const cardElementContainerProps = this.cardElementContainerProps();

    return (
      <>
        <div {...cardElementContainerProps}>
          <CardElement options={cardElementOptions} />
        </div>
        {!!isPaymentError && (
          <div className={'invalid-feedback'} style={{ display: 'block' }}>
            {isPaymentError.message}
          </div>
        )}
      </>
    );
  }
}

export default StripeCreditCardForm;
