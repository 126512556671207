export function refreshLastSavedTime(updated) {
  if (!!updated.updatedAtDateTime) {
    setTimeout(() => {
      let elems = document.getElementsByClassName('last-saved-time');
      if (elems && elems.length) {
        elems[0].innerHTML = updated.updatedAtDateTime;
      }
    }, 100);
  }
}
