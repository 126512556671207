import './DrawerToggleButton.css';

import React from 'react';

const drawerToggleButton = props => (
  <button className="btn btn-info toggle-button" onClick={props.click}>
    {/*
    <div className="toggle-button__line" />
    <div className="toggle-button__line" />
    <div className="toggle-button__line" />
    */}
    {props.children}
  </button>
);

export default drawerToggleButton;
