import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { connect } from 'react-redux';

import { del } from '../../actions/procedure/delete';
import { reset } from '../../actions/usedprocedure/list';
import { getPageWithFilterValues } from '../../utils/listFilter';
import CurrentUserCan from '../CurrentUserCan';
import { VersionLink as Link } from '../VersionLink';

class UsedProcedure extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  // getPageWithNotCompletedFilter = page => {
  //   let queryString =
  //     'completed_status.label=Incomplete&completed_status.value=incomplete';
  //   // let queryString = 'completed_status.value=incomplete';
  //   let url = page + (page.indexOf('?') === -1 ? '?' : '&') + queryString;
  //   return url;
  // };

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  getPageWithFilterValues = page => {
    return getPageWithFilterValues(
      'usedprocedureFilterValues',
      page,
      '/used_procedures'
    );
  };

  del = item => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      this.props.del(item);
    }
  };

  render() {
    return (
      <div className="main-section template-wrapper">
        <div className="d-flex">
          <div className="list-icon used-procedure-list-icon"></div>
          {/* <h2 className="col-header">Procedures in Use</h2> */}
          {/*<h2 className="col-header">Active Procedures</h2>*/}
          <h2 className="col-header">Active Case Workflows</h2>
        </div>

        <div className="usedprocedure-list-table-wrapper">
          <table className="table table-responsive table-striped table-hover">
            <thead>
              <tr>
                <th>Title</th>
                <th>Progress</th>
                <th>Due Date</th>

                <th colSpan={2}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.usedProcedure &&
                this.props.usedProcedure['hydra:member'].map(item => (
                  <tr key={item['@id']}>
                    <th scope="row">
                      <Link
                        to={`used_procedures/edit/${encodeURIComponent(
                          item['@id']
                        )}`}
                      >
                        {item['procedureName'].length < 21
                          ? item['procedureName']
                          : `${item['procedureName'].substring(0, 18)}...`}
                      </Link>
                    </th>

                    <td>
                      <ProgressBar
                        className={
                          parseFloat(item['completedProgressPercentage']) < 100
                            ? parseFloat(item['completedProgressPercentage']) <
                              40
                              ? 'progress-bar-low'
                              : 'progress-bar-mid'
                            : 'progress-bar-completed'
                        }
                        now={item['completedProgressPercentage']}
                      />
                    </td>
                    <td>{item['dueAtDate']}</td>
                    <td>
                      {parseFloat(item['completedProgressPercentage']) < 100 ? (
                        <Link
                          className={'primary-action'}
                          to={`used_procedures/edit/${encodeURIComponent(
                            item['@id']
                          )}`}
                        >
                          <span className="fa fa-edit" aria-hidden="true" />{' '}
                          <span>Continue</span>
                        </Link>
                      ) : (
                        <Link
                          className={'primary-action'}
                          to={`used_procedures/edit/${encodeURIComponent(
                            item['@id']
                          )}`}
                        >
                          <span className="fa fa-eye" aria-hidden="true" />{' '}
                          <span>Review</span>
                        </Link>
                      )}
                    </td>

                    <td>
                      <CurrentUserCan
                        perform={'usedprocedure:delete'}
                        yes={() => (
                          <DropdownButton
                            id={
                              'dropdown-basic-button-' +
                              item['@id'].replace(/[^\d]*/, '')
                            }
                            title={
                              <span className={'fa fa-ellipsis-v'}>
                                <span className={'sr-only'}>Actions</span>
                              </span>
                            }
                          >
                            <Link
                              className={'dropdown-item delete-action'}
                              to={'.'}
                              onClick={() => this.del(item)}
                            >
                              <span
                                className="fa fa-trash"
                                aria-hidden="true"
                              />{' '}
                              <span>Delete</span>
                            </Link>
                          </DropdownButton>
                        )}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="button-wrapper">
          <div className="col-item">
            <Link
              to={`/used_procedures/`}
              className="btn btn-primary view-all-btn"
            >
              View All&nbsp;&nbsp;&nbsp;
              <span className="fa fa-arrow-right" aria-hidden="true" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUserAccount: state.account.show.retrieved,
    procedureCloned: state.procedure.clone.cloned
  };
};

const mapDispatchToProps = dispatch => ({
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(UsedProcedure);
