import { SubmissionError } from 'redux-form';

import { getToken, login, logout } from '../../utils/auth';
import { fetch } from '../../utils/dataAccess';

export function switchActive(item) {
  return dispatch => {
    return fetch(item['@id'] + '/switch')
      .then(response => response.json())
      .then(retrieved => {
        // reset current session
        let token = getToken();
        if (!!retrieved['@_token']) {
          token = retrieved['@_token'];
        }
        logout();
        login(token);

        // list
        dispatch({ type: 'CATEGORY_LIST_RESET' });
        dispatch({ type: 'ORGANIZATION_LIST_RESET' });
        dispatch({ type: 'PROCEDURE_LIST_RESET' });
        dispatch({ type: 'PROCEDURESTEP_LIST_RESET' });
        dispatch({ type: 'TAG_LIST_RESET' });
        dispatch({ type: 'USEDPROCEDURE_LIST_RESET' });
        dispatch({ type: 'USEDPROCEDURESTEP_LIST_RESET' });
        dispatch({ type: 'USER_LIST_RESET' });
        // show
        dispatch({ type: 'CATEGORY_SHOW_RESET' });
        dispatch({ type: 'ORGANIZATION_SHOW_RESET' });
        dispatch({ type: 'PROCEDURE_SHOW_RESET' });
        dispatch({ type: 'PROCEDURESTEP_SHOW_RESET' });
        dispatch({ type: 'TAG_SHOW_RESET' });
        dispatch({ type: 'USEDPROCEDURE_SHOW_RESET' });
        dispatch({ type: 'USEDPROCEDURESTEP_SHOW_RESET' });
        dispatch({ type: 'USER_SHOW_RESET' });
        // update
        dispatch({ type: 'CATEGORY_UPDATE_RESET' });
        dispatch({ type: 'ORGANIZATION_UPDATE_RESET' });
        dispatch({ type: 'PROCEDURE_UPDATE_RESET' });
        dispatch({ type: 'PROCEDURESTEP_UPDATE_RESET' });
        dispatch({ type: 'TAG_UPDATE_RESET' });
        dispatch({ type: 'USEDPROCEDURE_UPDATE_RESET' });
        dispatch({ type: 'USEDPROCEDURESTEP_UPDATE_RESET' });
        dispatch({ type: 'USER_UPDATE_RESET' });
        // custom
        dispatch({ type: 'ACCOUNT_INVITE_RESET' });
        dispatch({ type: 'ACCOUNT_RESETTING_REQUEST_RESET' });
        dispatch({ type: 'PROCEDURE_CLONE_RESET' });

        return { retrieved };
      })
      .catch(e => {
        if (e instanceof SubmissionError) {
          throw e;
        }
      });
  };
}
