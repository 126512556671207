import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'USEDPROCEDURE_EXPORT_PDF_ERROR', error };
}

export function loading(loading) {
  return { type: 'USEDPROCEDURE_EXPORT_PDF_LOADING', loading };
}

export function success(exported) {
  return { type: 'USEDPROCEDURE_EXPORT_PDF_SUCCESS', exported };
}

export function exportPdfUsedProcedure(item) {
  return dispatch => {
    dispatch(loading(true));

    let headers = new Headers();
    headers.set('Accept', 'application/pdf');
    headers.set('Content-Type', 'application/pdf');

    return (
      fetch(item['@id'] + '/export/pdf', { method: 'POST', headers })
        // https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
        // 1. Convert the data into 'blob'
        .then(response => response.blob())
        .then(blob => {
          // 2. Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `used-procedure-${item['@id'].replace('/used_procedures/', '')}.pdf`
          );
          // 3. Append to html page
          document.body.appendChild(link);
          // 4. Force download
          link.click();
          // 5. Clean up and remove the link
          link.parentNode.removeChild(link);

          dispatch(loading(false));
        })
        .catch(e => {
          dispatch(loading(false));
          dispatch(error(e.message));
        })
    );
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'USEDPROCEDURE_EXPORT_PDF_RESET' });
    dispatch(success(null));
  };
}
