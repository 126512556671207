import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.css';
//import '@fortawesome/fontawesome-free/css/all.css';
import 'font-awesome/css/font-awesome.css';

import './assets/css/App.css';

import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { reducer as form } from 'redux-form';
import thunk from 'redux-thunk';

import { PrivateLayout as PrivateRoute } from './components/PrivateLayout';
import Dashboard from './Dashboard';
// Import your reducers and routes here
import FirstExperience from './FirstExperience';
import Home from './Home';
import LandingLegal from './LandingLegal';
// import reducers
import account from './reducers/account/';
import cart from './reducers/cart/';
import cartitem from './reducers/cartitem/';
import category from './reducers/category/';
import checkout from './reducers/checkout/';
import invite from './reducers/invite/';
import order from './reducers/order/';
import organization from './reducers/organization/';
import procedure from './reducers/procedure/';
import procedurestep from './reducers/procedurestep/';
import purchasableprocedure from './reducers/purchasableprocedure/';
import resetting from './reducers/resetting/';
import tag from './reducers/tag/';
import usedprocedure from './reducers/usedprocedure/';
import usedprocedurestep from './reducers/usedprocedurestep/';
import user from './reducers/user/';
// import routes
import accountRoutes from './routes/account';
import cartRoutes from './routes/cart';
import cartitemRoutes from './routes/cartitem';
import categoryRoutes from './routes/category';
import checkoutRoutes from './routes/checkout';
import inviteRoutes from './routes/invite';
import orderRoutes from './routes/order';
import organizationRoutes from './routes/organization';
import procedureRoutes from './routes/procedure';
import procedurestepRoutes from './routes/procedurestep';
import purchasableprocedureRoutes from './routes/purchasableprocedure';
import resettingRoutes from './routes/resetting';
import tagRoutes from './routes/tag';
import usedprocedureRoutes from './routes/usedprocedure';
import usedprocedurestepRoutes from './routes/usedprocedurestep';
import userRoutes from './routes/user';
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    /* Add your reducers here */
    account,
    cart,
    cartitem,
    category,
    checkout,
    invite,
    order,
    organization,
    procedure,
    procedurestep,
    purchasableprocedure,
    resetting,
    tag,
    usedprocedure,
    usedprocedurestep,
    user
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={Home} strict={true} exact={true} />
        <PrivateRoute
          path="/dashboard"
          component={Dashboard}
          strict={true}
          exact={true}
        />
        <Route
          path="/legal"
          component={LandingLegal}
          strict={true}
          exact={true}
        />
        <Route
          path="/first-experience"
          component={FirstExperience}
          strict={true}
          exact={true}
        />
        {/* Add your routes here */}
        {accountRoutes}
        {cartRoutes}
        {cartitemRoutes}
        {categoryRoutes}
        {checkoutRoutes}
        {inviteRoutes}
        {orderRoutes}
        {organizationRoutes}
        {procedureRoutes}
        {procedurestepRoutes}
        {purchasableprocedureRoutes}
        {resettingRoutes}
        {tagRoutes}
        {usedprocedureRoutes}
        {usedprocedurestepRoutes}
        {userRoutes}
        <Route render={() => <h1>{/*Not Found*/}</h1>} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
