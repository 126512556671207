import React from 'react';

//import { Route } from 'react-router-dom';
import { PrivateLayout as Route } from '../components/PrivateLayout';
import {
  CompletedList,
  Create,
  List,
  Show,
  Update
} from '../components/usedprocedure/';

export default [
  <Route
    path="/used_procedures/create"
    component={Create}
    exact
    key="create"
  />,
  <Route
    path="/used_procedures/edit/:id"
    component={Update}
    exact
    key="update"
  />,
  <Route path="/used_procedures/show/:id" component={Show} exact key="show" />,
  <Route path="/used_procedures/" component={List} exact strict key="list" />,
  <Route
    path="/used_procedures/:page"
    component={List}
    exact
    strict
    key="page"
  />,
  <Route
    path="/used_procedures/completed/"
    component={CompletedList}
    exact
    strict
    key="completed_list"
  />,
  <Route
    path="/used_procedures/completed/:page"
    component={CompletedList}
    exact
    strict
    key="completed_list_page"
  />
];
