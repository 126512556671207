import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'PROCEDURE_CLONE_ERROR', error };
}

export function loading(loading) {
  return { type: 'PROCEDURE_CLONE_LOADING', loading };
}

export function success(cloned) {
  return { type: 'PROCEDURE_CLONE_SUCCESS', cloned };
}

export function clone(item) {
  return dispatch => {
    dispatch(loading(true));

    return fetch(item['@id'] + '/clone', { method: 'POST' })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => dispatch(success(retrieved)))
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'PROCEDURE_CLONE_RESET' });
    dispatch(success(null));
  };
}
