import { SubmissionError } from 'redux-form';

import { loginFromCookie } from '../../utils/auth';
import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'ACCOUNT_LOGIN_ERROR', error };
}

export function loading(loading) {
  return { type: 'ACCOUNT_LOGIN_LOADING', loading };
}

export function connectServiceSuccess(loggedin) {
  window.location.href = loggedin.oauth_url;
  return { type: 'ACCOUNT_CONNECT_SERVICE_SUCCESS', loggedin };
}

export function connectServiceRedirectLanding() {
  loginFromCookie();
}

export function connectService(service) {
  return dispatch => {
    dispatch(loading(true));

    return fetch(
      '/connect/' +
        service +
        '?_return_url=1&_target_path=' +
        window.location.href,
      { method: 'GET' }
    )
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => dispatch(connectServiceSuccess(retrieved)))
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function responseFacebook(fbResponse) {
  return connectService('facebook');
}

export function responseGoogle(response) {
  return connectService('google');
}
