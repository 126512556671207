import { SubmissionError } from 'redux-form';

import { fetch } from '../../utils/dataAccess';

export function userActivateOrDeactivateError(userActivateOrDeactivateError) {
  return {
    type: 'USER_ACTIVATE_OR_DEACTIVATE_ERROR',
    userActivateOrDeactivateError
  };
}

export function userActivateOrDeactivateLoading(
  userActivateOrDeactivateLoading
) {
  return {
    type: 'USER_ACTIVATE_OR_DEACTIVATE_LOADING',
    userActivateOrDeactivateLoading
  };
}

export function userActivateOrDeactivateSuccess(
  userActivateOrDeactivateSuccess
) {
  return {
    type: 'USER_ACTIVATE_OR_DEACTIVATE_SUCCESS',
    userActivateOrDeactivateSuccess
  };
}

export function activateOrDeactivate(item) {
  return dispatch => {
    dispatch(userActivateOrDeactivateError(null));
    dispatch(userActivateOrDeactivateLoading(true));

    return fetch(
      item['@id'] +
        '/' +
        (!!item['@_organizationCurrentEnabledStatus']
          ? 'deactivate'
          : 'activate')
    )
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        dispatch(userActivateOrDeactivateLoading(false));
        dispatch(userActivateOrDeactivateSuccess(retrieved));
      })
      .catch(e => {
        dispatch(userActivateOrDeactivateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(userActivateOrDeactivateError(e.errors._error));
          throw e;
        }

        dispatch(userActivateOrDeactivateError(e.message));
      });
  };
}
