import { combineReducers } from 'redux';

import { login } from '../../utils/auth';

export function retrieveError(state = null, action) {
  switch (action.type) {
    case 'ACCOUNT_RESETTING_REQUEST_RETRIEVE_ERROR':
      return action.retrieveError;

    case 'ACCOUNT_RESETTING_REQUEST_RESET':
      return null;

    default:
      return state;
  }
}

export function retrieveLoading(state = false, action) {
  switch (action.type) {
    case 'ACCOUNT_RESETTING_REQUEST_RETRIEVE_LOADING':
      return action.retrieveLoading;

    case 'ACCOUNT_RESETTING_REQUEST_RESET':
      return false;

    default:
      return state;
  }
}

export function retrieved(state = null, action) {
  switch (action.type) {
    case 'ACCOUNT_RESETTING_REQUEST_RETRIEVE_SUCCESS':
      return action.retrieved;

    case 'ACCOUNT_RESETTING_REQUEST_RESET':
      return null;

    default:
      return state;
  }
}

export function error(state = null, action) {
  switch (action.type) {
    case 'ACCOUNT_RESETTING_RESET_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'ACCOUNT_RESETTING_RESET_LOADING':
      return action.loading;

    default:
      return state;
  }
}

export function resetConfirmed(state = null, action) {
  switch (action.type) {
    case 'ACCOUNT_RESETTING_RESET_SUCCESS':
      if (action.resetConfirmed && action.resetConfirmed.token) {
        login(action.resetConfirmed.token); // The JWT token is stored in the browser's local storage
      }
      return action.resetConfirmed;

    default:
      return state;
  }
}

export default combineReducers({
  retrieveError,
  retrieveLoading,
  retrieved,
  error,
  loading,
  resetConfirmed
});
