import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { Field, change as changeFormField, reduxForm } from 'redux-form';

import { isAdmin } from '../../utils/auth';
import CurrentUserCan from '../CurrentUserCan';

class Form extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  handleChangeFirstName = event => {
    const name = this.firstNameInput.value + ' ' + this.lastNameInput.value;
    this.nameInput.value = name;
    this.props.dispatch(changeFormField('user', 'name', name));
  };

  handleChangeLastName = event => {
    const name = this.firstNameInput.value + ' ' + this.lastNameInput.value;
    this.nameInput.value = name;
    this.props.dispatch(changeFormField('user', 'name', name));
  };

  renderHiddenField = data => {
    return (
      <input
        {...data.input}
        type={'hidden'}
        step={data.step}
        id={`user_${data.input.name}`}
        ref={data.innerRef || undefined}
      />
    );
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group`}>
        <label
          htmlFor={`user_${data.input.name}`}
          className="form-control-label"
        >
          {data.label || data.input.name}
        </label>
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`user_${data.input.name}`}
          ref={data.innerRef || undefined}
          onChange={data.onChange || data.input.onChange}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderSelectField = data => {
    return (
      <div className={`form-group user-${data.input.name}-wrapper`}>
        <label
          htmlFor={`user_${data.input.name}`}
          className="form-control-label"
        >
          {data.label || data.input.name}
        </label>
        <AsyncSelect
          {...data.input}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`user_${data.input.name}`}
          className={`user-${data.input.name}`}
          classNamePrefix={`user-${data.input.name}`}
          defaultOptions
          onBlur={event => {
            event.preventDefault(); // https://stackoverflow.com/questions/48419984/how-to-have-searchable-dropdown-in-redux-form
          }}
          onChange={(newValue, actionMeta) => {
            data.input.onChange(newValue, actionMeta);
          }}
          isMulti={false}
          isClearable={true}
          loadOptions={data.loadOptions}
          // setting the value explicitly so that it will appear on page load
          value={
            data.input.value === ''
              ? undefined
              : //: [{ label: data.input.value, value: data.input.value }]
                data.input.value
          }
          getOptionLabel={this.getOptionLabel}
          getOptionValue={this.getOptionValue}
        />
      </div>
    );
  };

  getOptionLabel = option => {
    // seems like nested object values due to renderSelectField
    if (
      option.label &&
      Array.isArray(option.label) &&
      option.label[0] &&
      option.label[0].label
    ) {
      return option.label[0].label;
    }
    if (option.label && option.label.label) {
      return option.label.label;
    }

    return option.label;
  };

  getOptionValue = option => {
    // seems like nested object values due to renderSelectField
    if (
      option.value &&
      Array.isArray(option.value) &&
      option.value[0] &&
      option.value[0].value
    ) {
      return option.value[0].value;
    }
    if (option.value && option.value.value) {
      return option.value.value;
    }

    return option.value;
  };

  getRoleOptions = inputValue => {
    let roles = [
      {
        label: 'as user (can only use existing procedures)',
        value: 'user'
      }
    ];

    if (isAdmin()) {
      roles = [
        {
          label: 'as user (can only use existing procedures)',
          value: 'user'
        },
        {
          label: 'as manager (can create & edit procedures, invite users)',
          value: 'manager'
        },
        {
          label: 'as administrator (can do all, including delete)',
          value: 'admin'
        }
      ];
    }

    return new Promise(dispatch => {
      return dispatch(roles);
    });
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={this.renderHiddenField}
          name="name"
          label="Name"
          type="text"
          placeholder="e.g. Jane Doe"
          required={true}
          innerRef={ref => (this.nameInput = ref)}
        />
        <Field
          component={this.renderField}
          name="firstName"
          label="First Name"
          type="text"
          placeholder="e.g. Jane"
          required={true}
          innerRef={ref => (this.firstNameInput = ref)}
          onChange={this.handleChangeFirstName}
        />
        <Field
          component={this.renderField}
          name="lastName"
          label="Last Name"
          type="text"
          placeholder="e.g. Doe"
          required={true}
          innerRef={ref => (this.lastNameInput = ref)}
          onChange={this.handleChangeLastName}
        />
        <Field
          component={this.renderField}
          name="email"
          label="Email"
          type="email"
          placeholder="janedoe@example.com"
          required={true}
        />
        <CurrentUserCan
          perform={'user:edit_attributes'}
          yes={() => (
            <Field
              component={this.renderField}
              name="company"
              label="Company"
              type="text"
              placeholder="e.g. Acme Corp."
            />
          )}
        />
        <CurrentUserCan
          perform={'user:edit_attributes'}
          yes={() => (
            <Field
              component={this.renderSelectField}
              name="asRole"
              label="Role"
              type="select"
              placeholder="Role"
              loadOptions={this.getRoleOptions}
            />
          )}
        />
        <CurrentUserCan
          perform={'user:edit_attributes'}
          yes={() => (
            <Field
              component={this.renderField}
              name="plainPassword"
              label="Password"
              type="password"
              placeholder=""
            />
          )}
        />

        <button type="submit" className="btn btn-cta">
          Update
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'user',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Form);
