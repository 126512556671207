import 'react-quill/dist/quill.snow.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactQuill from 'react-quill';
import { Field, reduxForm } from 'redux-form';

import CurrentUserCan from '../CurrentUserCan';
import DrawerToggleButton from '../drawer/SideDrawer/DrawerToggleButton';
import DeleteTreeItemWithModal from '../procedurestep/DeleteTreeItemWithModal';
import FileAttachment from '../procedurestep/FileAttachment';
import { PurchasableProcedureForm } from '../purchasableprocedure/PurchasableProcedureForm';
import DeleteFilesModal from './DeleteFilesModal';
import ProcedureStepTreeFormFields from './ProcedureStepTreeFormFields';

class Form extends Component {
  state = {
    hasFirstItem: (this.props.initialValues
      ? this.props.initialValues.procedureSteps
      : []
    ).length,
    isDeleteFilesModalOpen: false,
    deleteFilesModalForStepId: ''
  };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    // Auto save at interval
    const interval = setInterval(() => {
      this.getButtonComponent().click();
    }, 30000);
    this.setState(prevState => {
      return { interval: interval };
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group`}>
        {data.input.name === 'name' ? (
          <label
            htmlFor={`procedure_${data.input.name}`}
            className={
              'form-control-label w-100 text-center editing-using-procedure-label '
            }
          >
            {/* Editing Procedure Template */}
            {/*Editing Template*/}
            Editing Case Workflow
          </label>
        ) : (
          ' '
        )}
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`procedure_${data.input.name}`}
          style={data.style}
          onClick={event => this.handleClick(event, data)}
          onBlur={() => this.handleBlur()}
          autoFocus={data.autoFocus || false}
          readOnly={data.readOnly || false}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderDescriptionField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group`}>
        <label
          htmlFor={`procedure_${data.input.name}`}
          className="form-control-label"
        >
          {data.label || data.input.name}
        </label>
        <ReactQuill
          {...data.input}
          required={data.required}
          placeholder={data.placeholder}
          id={`procedure_${data.input.name}`}
          style={data.style}
          onKeyUp={event => {
            this.handleDescriptionChange(event, data);
            //data.input.onKeyUp(event);
          }}
          onClick={event => this.handleClick(event, data)}
          onBlur={() => this.handleBlur()}
          autoFocus={data.autoFocus || false}
          readOnly={data.readOnly || false}
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline'],
              ['link'],
              [{ color: [] }]
            ]
          }}
          formats={['bold', 'italic', 'underline', 'link', 'color']}
          ref={ref => this.attachDescriptionField(ref, data.input.name)}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderCategoryField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    let labelValue = 'Assign New Folder';
    if (data.input.value) {
      labelValue = data.input.value;
    }

    let label = (
      <span
        className={'categoryName-label'}
        style={{
          whiteSpace: 'nowrap'
        }}
      >
        <span className={'fa fa-folder-open-o'} /> <span>{labelValue}</span>
      </span>
    );

    return (
      <div
        className={`form-group`}
        style={{
          position: 'relative'
        }}
      >
        {label}
        {/*
        <label
          htmlFor={`procedure_${data.input.name}`}
          className="form-control-label"
        >
          {data.input.name}
        </label>
        */}
        <input
          {...data.input}
          type={'hidden'}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`procedure_${data.input.name}`}
          style={data.style}
          onClick={event => this.handleClick(event, data)}
          onBlur={() => this.handleBlur()}
          autoFocus={data.autoFocus || false}
          readOnly={data.readOnly || false}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderLastSavedText = data => {
    let time = data.input.value;

    if (time) {
      return (
        <span className={'last-saved-wrapper'}>
          <span className={'last-saved-text'}>Last Saved at:&nbsp;</span>
          <span className={'last-saved-time'}>{time}</span>
        </span>
      );
    }

    return 'Save';
  };

  attachNode = node => {
    this._button = node;
  };

  getButtonComponent = () => {
    return this._button;
  };

  handleClick = (event, data) => {
    if (
      data &&
      data.input &&
      data.input.name &&
      data.input.name === 'categoryName'
    ) {
      this.props.drawerToggleClickHandler();
    }
  };

  handleBlur = () => {
    if (
      typeof this.props.allowAutoSave !== 'undefined' &&
      !this.props.allowAutoSave
    ) {
      return;
    }

    this.getButtonComponent().click();
  };

  handleDescriptionChange = (event, data) => {
    let editor = this.getDescriptionFieldComponent(data.input.name);
    if (!editor) {
      return;
    }

    let elem = event.target;
    // ql-editor / ql-container / quill / form-group / procedure-description / procedure-description-container / procedure-description-wrapper / extends-tree-item
    let wrapper =
      elem.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode;
    let toggle = wrapper.querySelector('.procedure-description-wrapper');

    if (!!toggle) {
      wrapper.classList.remove('with-description');
      toggle.classList.remove('with-description');

      let value = editor.getEditor().getText();

      if (!!value && !elem.classList.contains('ql-blank')) {
        wrapper.className += ' with-description';
        toggle.className += ' with-description';
      }
    }
  };

  handleFileRemoved = (file, component) => {
    if (!component.getUploaderComponent().props.uppy.getFiles().length) {
      let elem = component.getUploaderComponent().container;
      // div / procedure-attachment / procedure-attachment-container / procedure-attachment-wrapper / extends-tree-item
      let wrapper = elem.parentNode.parentNode.parentNode.parentNode;
      let toggle = wrapper.querySelector('.procedure-attachment-wrapper');

      if (!!toggle) {
        wrapper.classList.remove('with-files');
        toggle.classList.remove('with-files');
      }
    }
  };

  handleFileUploadComplete = (results, component) => {
    if (!!results.successful.length) {
      let elem = component.getUploaderComponent().container;
      // div / procedure-attachment / procedure-attachment-container / procedure-attachment-wrapper / extends-tree-item
      let wrapper = elem.parentNode.parentNode.parentNode.parentNode;
      let toggle = wrapper.querySelector('.procedure-attachment-wrapper');

      if (!!toggle) {
        wrapper.classList.remove('with-files');
        toggle.classList.remove('with-files');

        wrapper.className += ' with-files';
        toggle.className += ' with-files';
      }

      results.successful.forEach(file => {
        component.updateFileSource(file.id);
      });
    }
  };

  preventFormSubmit = event => {
    return this.getTreeComponent().preventFormSubmit(event);
  };

  setHasFirstItem = (status = true) => {
    this.setState({ hasFirstItem: status });
  };

  attachTree = node => {
    this._procedureStepTree = node;
  };

  getTreeComponent = () => {
    return this._procedureStepTree;
  };

  pressFormSubmit = () => {
    if (typeof this.props.pressFormSubmit === 'function') {
      this.props.pressFormSubmit();
    }
  };

  getSelectItemActionComponent = (data, index, clickHandler, fieldName) => {
    let hasDescription = !!data.description;
    let hasFiles =
      !!data.procedureStepFiles &&
      Object.keys(data.procedureStepFiles).length > 0;
    let descriptionFieldName = 'procedureSteps[' + index + '][description]';

    return (
      <span className={'extends-tree-item-wrapper right-side'}>
        <span
          className={
            'extends-tree-item right-side' +
            (hasDescription ? ' with-description' : '') +
            (hasFiles ? ' with-files' : '')
          }
        >
          <Accordion
            className={
              'procedure-description-wrapper' +
              (hasDescription ? ' with-description' : '')
            }
          >
            <Accordion.Toggle
              eventKey="0"
              className={'procedure-description-toggle'}
              as={'span'}
              onClick={event => {
                let elem = event.target.parentNode.parentNode.parentNode;

                Array.from(
                  document.getElementsByClassName('extends-tree-item')
                ).forEach(
                  //collection of description and file container
                  function (element, index, array) {
                    if (
                      element.classList.contains('isOpen') &&
                      element.parentNode.children[1] !==
                        elem.parentNode.children[1]
                    ) {
                      //if element in collection != element event.target ok to remove isOpen
                      //.procedure-description-container
                      if (
                        element.children &&
                        element.children[0] &&
                        element.children[0].children &&
                        element.children[0].children[0] &&
                        element.children[0].children[1] &&
                        element.children[0].children[1].classList.contains(
                          'show'
                        )
                      ) {
                        //.procedure-description-toggle
                        element.children[0].children[0].click();
                      }

                      //.procedure-attachment-container
                      if (
                        element.children &&
                        element.children[1] &&
                        element.children[1].children &&
                        element.children[1].children[0] &&
                        element.children[1].children[1] &&
                        element.children[1].children[1].classList.contains(
                          'show'
                        )
                      ) {
                        //.procedure-attachment-toggle
                        element.children[1].children[0].click();
                      }
                      element.classList.remove('isOpen');
                    }
                  }
                );

                //.procedure-attachment-container
                if (
                  elem.children &&
                  elem.children[1] &&
                  elem.children[1].children &&
                  elem.children[1].children[0] &&
                  elem.children[1].children[1] &&
                  elem.children[1].children[1].classList.contains('show')
                ) {
                  //.procedure-attachment-toggle btn
                  elem.children[1].children[0].click();
                }

                if (elem.classList.contains('isOpen')) {
                  elem.classList.remove('isOpen');
                } else {
                  if (elem.classList.contains('extends-tree-item-wrapper')) {
                    // do not add isOpen class on element that includes multiple accordions
                  } else {
                    elem.className += ' isOpen';
                  }

                  setTimeout(() => {
                    this.getDescriptionFieldComponent(
                      descriptionFieldName
                    ).focus();
                  }, 100);
                }
              }}
            >
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={'tooltip-description'}>Add Description</Tooltip>
                }
              >
                <span className={'fa fa-info-circle'} title={'Add Description'}>
                  {''}
                </span>
              </OverlayTrigger>
            </Accordion.Toggle>
            <Accordion.Collapse
              eventKey="0"
              className={'procedure-description-container'}
            >
              <div className={'procedure-description'}>
                <Field
                  component={this.renderDescriptionField}
                  name={descriptionFieldName}
                  label={'Description'}
                />
                <Accordion.Toggle
                  eventKey="0"
                  className={'procedure-description-toggle-secondary'}
                  as={'span'}
                  onClick={event => {
                    let elem =
                      event.target.parentNode.parentNode.parentNode.parentNode
                        .parentNode;

                    // the Accordion.Toggle does not seem to work within the Accordion.Collapse, so trigger the other one
                    let toggleElem = elem.querySelector(
                      '.procedure-description-toggle'
                    );
                    if (!!toggleElem) {
                      toggleElem.click();
                    }

                    if (elem.classList.contains('isOpen')) {
                      elem.classList.remove('isOpen');
                    } else {
                      // only allow the other toggle to add the isOpen class
                      //elem.className += ' isOpen';

                      setTimeout(() => {
                        this.getDescriptionFieldComponent(
                          descriptionFieldName
                        ).focus();
                      }, 100);
                    }
                  }}
                >
                  <button className={'btn btn-primary'}>Save</button>
                </Accordion.Toggle>
              </div>
            </Accordion.Collapse>
          </Accordion>
          <Accordion
            className={
              'procedure-attachment-wrapper' + (hasFiles ? ' with-files' : '')
            }
          >
            <Accordion.Toggle
              eventKey="0"
              className={'procedure-attachment-toggle'}
              as={'span'}
              onClick={event => {
                let elem = event.target.parentNode.parentNode.parentNode;

                Array.from(
                  document.getElementsByClassName('extends-tree-item')
                ).forEach(
                  //collection of description and file containers
                  function (element, index, array) {
                    if (
                      element.classList.contains('isOpen') &&
                      element.parentNode.children[1] !==
                        elem.parentNode.children[1]
                    ) {
                      //if element in collection != element event.target ok to remove isOpen
                      //.procedure-attachment-container
                      if (
                        element.children &&
                        element.children[1] &&
                        element.children[1].children &&
                        element.children[1].children[0] &&
                        element.children[1].children[1] &&
                        element.children[1].children[1].classList.contains(
                          'show'
                        )
                      ) {
                        //.procedure-attachment-toggle
                        element.children[1].children[0].click();
                      }

                      //.procedure-description-container
                      if (
                        element.children &&
                        element.children[0] &&
                        element.children[0].children &&
                        element.children[0].children[0] &&
                        element.children[0].children[1] &&
                        element.children[0].children[1].classList.contains(
                          'show'
                        )
                      ) {
                        //.procedure-description-toggle
                        element.children[0].children[0].click();
                      }
                      element.classList.remove('isOpen');
                    }
                  }
                );

                //.procedure-description-container
                if (
                  elem.children &&
                  elem.children[0] &&
                  elem.children[0].children &&
                  elem.children[0].children[0] &&
                  elem.children[0].children[1] &&
                  elem.children[0].children[1].classList.contains('show')
                ) {
                  //.procedure-description-toggle btn
                  elem.children[0].children[0].click();
                }

                if (elem.classList.contains('isOpen')) {
                  elem.classList.remove('isOpen');
                } else {
                  if (elem.classList.contains('extends-tree-item-wrapper')) {
                    // do not add isOpen class on element that includes multiple accordions
                  } else {
                    elem.className += ' isOpen';
                  }
                }
              }}
            >
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={'tooltip-attachments'}>Attach Files</Tooltip>
                }
              >
                <span className={'fa fa-paperclip'} title={'Attach Files'}>
                  {''}
                </span>
              </OverlayTrigger>
            </Accordion.Toggle>
            <Accordion.Collapse
              eventKey="0"
              className={'procedure-attachment-container'}
            >
              <div className={'procedure-attachment'}>
                <FileAttachment
                  originalData={data}
                  originalIndex={index}
                  originalFieldName={fieldName}
                  onFileRemoved={this.handleFileRemoved}
                  onComplete={this.handleFileUploadComplete}
                />
                <Accordion.Toggle
                  eventKey="0"
                  className={'procedure-attachment-toggle-secondary'}
                  as={'span'}
                  onClick={event => {
                    let elem =
                      event.target.parentNode.parentNode.parentNode.parentNode
                        .parentNode;

                    // the Accordion.Toggle does not seem to work within the Accordion.Collapse, so trigger the other one
                    let toggleElem = elem.querySelector(
                      '.procedure-attachment-toggle'
                    );
                    if (!!toggleElem) {
                      toggleElem.click();
                    }

                    if (elem.classList.contains('isOpen')) {
                      elem.classList.remove('isOpen');
                      // when the element is closed, update the tree data
                      this.getTreeComponent().getTreeComponent().updateTree();
                    } else {
                      // only allow the other toggle to add the isOpen class
                      //elem.className += ' isOpen';
                    }
                  }}
                >
                  <button className={'btn btn-primary'}>Save</button>
                </Accordion.Toggle>
                {/* Alternative file delete modal */}
                {/*<button*/}
                {/*  className={'btn btn-secondary ml-2'}*/}
                {/*  onClick={() => this.handleOpenDeleteFilesModal(data)}*/}
                {/*>*/}
                {/*  Remove File(s)*/}
                {/*</button>*/}
              </div>
            </Accordion.Collapse>
          </Accordion>
        </span>
      </span>
    );
  };

  handleOpenDeleteFilesModal = data => {
    this.setState({
      isDeleteFilesModalOpen: true,
      deleteFilesModalForStepId: data['@id']
    });
  };

  handleCloseDeleteFilesModal = () => {
    this.setState({
      isDeleteFilesModalOpen: false,
      deleteFilesModalForStepId: ''
    });
  };

  getDeleteItemActionComponent = (item, index, clickHandler, fieldName) => {
    let inputName = fieldName + '[' + index + '][name]';

    return (
      <DeleteTreeItemWithModal
        confirmNowClickHandler={clickHandler}
        inputName={inputName}
      />
    );
  };

  _descriptionField = [];

  attachDescriptionField = (node, index) => {
    this._descriptionField[index] = node;
  };

  getDescriptionFieldComponent = index => {
    return this._descriptionField[index];
  };

  getSidebarComponent = () => {
    return (
      <CurrentUserCan
        perform={'purchasableProcedure:create'}
        yes={() => {
          if (
            this.props.retrievedProcedure &&
            this.props.retrievedProcedure.purchasableProcedure &&
            this.props.retrievedProcedure.purchasableProcedure
              .purchasedProcedure !== null
          ) {
            return <></>;
          }
          return (
            <PurchasableProcedureForm
              changeFormField={this.props.changeFormField}
              initialValues={this.props.initialValues.purchasableProcedure}
              getButtonComponent={this.getButtonComponent}
              retrieveProcedure={this.props.retrieveProcedure}
              retrievedProcedure={this.props.retrievedProcedure}
            />
          );
        }}
      />
    );
  };

  render() {
    if (!this.state.hasFirstItem) {
      setTimeout(() => {
        if (!this.state.hasFirstItem) {
          let steps = document.getElementsByClassName('procedure-step');
          if (steps.length > 0) {
            this.setHasFirstItem(true);
            // there are already steps on the page, focus on last input
            // procedure-step / form-group / input
            //steps[steps.length - 1].children[0].children[0].focus();  // disabled for now
          } else {
            // add first step automatically
            document.getElementById('add-procedure-step').click();
          }
        }
      }, 100);
    }

    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyDown={this.preventFormSubmit}
      >
        {this.state.isDeleteFilesModalOpen === true && (
          <DeleteFilesModal
            isOpen={this.state.isDeleteFilesModalOpen}
            handleClose={this.handleCloseDeleteFilesModal}
            data={this.state.deleteFilesModalForStepId}
            saveButtonComponent={this.getButtonComponent}
          />
        )}
        <div className={'procedure-update-actions-wrapper'}>
          <button
            type="submit"
            id={'new-submit-button'}
            className="btn"
            ref={this.attachNode.bind(this)}
          >
            Save
          </button>
          <button
            onClick={this.pressFormSubmit}
            type="submit"
            className="btn btn-primary procedure-submit"
            disabled={this.props.submitting || this.props.pristine}
          >
            {this.props.submitting ? (
              'Saving...'
            ) : this.props.pristine ? (
              // Stored
              <Field
                component={this.renderLastSavedText}
                name="updatedAtDateTime"
                type="text"
                readOnly={true}
              />
            ) : (
              // Save
              <Field
                component={this.renderLastSavedText}
                name="updatedAtDateTime"
                type="text"
                readOnly={true}
              />
            )}
          </button>
          <span className={'divider-vertical'} />
          <div className={'select-category-button-wrapper'}>
            <div
              className={'select-category-button'}
              style={{
                display: 'inline-block'
              }}
            >
              <DrawerToggleButton click={this.props.drawerToggleClickHandler}>
                <div
                  className={'folder-label-wrapper'}
                  style={{
                    display: 'inline-block'
                  }}
                >
                  <Field
                    component={this.renderCategoryField}
                    name="categoryName"
                    type="text"
                    placeholder="Assign New Folder"
                    style={{
                      backgroundColor: 'transparent',
                      border: 0,
                      textAlign: 'right'
                    }}
                    autoFocus={!this.state.hasFirstItem}
                    readOnly={true}
                  />
                </div>
              </DrawerToggleButton>
            </div>
          </div>
        </div>
        <Field
          component={this.renderField}
          name="name"
          type="text"
          placeholder="Please name your procedure."
          required={true}
          style={{
            border: 0,
            fontWeight: 600,
            textAlign: 'center',
            width: '100%',
            fontSize: '36px',
            color: '#272C30'
          }}
          autoFocus={!this.state.hasFirstItem}
        />

        <ProcedureStepTreeFormFields
          initialValues={this.props.initialValues}
          change={this.props.change}
          dispatch={this.props.dispatch}
          getButtonComponent={this.getButtonComponent}
          allowAutoSave={this.props.allowAutoSave}
          hasFirstItem={this.state.hasFirstItem}
          setHasFirstItem={this.setHasFirstItem}
          getSelectItemActionComponent={this.getSelectItemActionComponent}
          getDeleteItemActionComponent={this.getDeleteItemActionComponent}
          disableDeleteDefaultConfirmation={true}
          getProcedureStepNameAtIndex={this.props.getProcedureStepNameAtIndex}
          sidebarComponent={this.getSidebarComponent()}
          ref={this.attachTree.bind(this)}
        />

        <Field
          component={this.renderField}
          name="description"
          type="text"
          placeholder="Notes (Optional)"
          style={{
            border: 0,
            marginTop: '4rem',
            marginBottom: '8rem',
            textAlign: 'center',
            width: '100%'
          }}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'procedure',
  /* setting updateUnregisteredFields: true should allow the moveItemBackOneLevelAtIndex to update the form data */
  updateUnregisteredFields: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Form);
