import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import Tus from '@uppy/tus';
import { isEqual } from 'lodash';
import React, { Component } from 'react';

import { ENTRYPOINT } from '../../config/entrypoint';
import { applyAuthHeader } from '../../utils/auth';
import { fetch } from '../../utils/dataAccess';
import FileActions from '../../utils/uppy/FileActions';

class FileAttachment extends Component {
  state = {
    endpoint:
      ENTRYPOINT +
      (this.props.originalData && this.props.originalData['@id']
        ? this.props.originalData['@id']
        : '') +
      '/files/'
  };

  constructor(props) {
    super(props);
    this.initializeUppy(props);
  }

  initializeUppy = props => {
    const instanceId =
      props.originalData && props.originalData['@id']
        ? 'uppy_' + props.originalData['@id'].replace(/\//g, '_')
        : 'uppy';

    this.instanceId = instanceId;

    const meta =
      props.originalData && props.originalData['@id']
        ? { '@id': props.originalData['@id'] }
        : {};

    let headers = {};
    let hdrs = new Headers();
    applyAuthHeader(hdrs);
    let keyVals = [...hdrs.entries()];
    keyVals.forEach(([key, val]) => {
      headers[key] = val;
    });

    this.uppy = Uppy({
      id: instanceId,
      onBeforeFileAdded: this.onBeforeFileAdded,
      onBeforeUpload: this.onBeforeUpload,
      locale: {
        strings: {
          dropPasteImport: 'Drop files here, paste or %{browse}'
        }
      },
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*']
      },
      meta: meta
    })
      .use(Tus, {
        endpoint: this.state.endpoint,
        headers: headers
      })
      .use(FileActions, {
        id: 'remote_' + this.instanceId,
        title: 'remote'
      })
      .on('file-added', this.onFileAdded)
      .on('file-removed', this.onFileRemoved)
      .on('complete', this.onComplete);

    if (props.originalData && props.originalData['purchasableProcedureFile']) {
      let purchasableProcedureFile =
        props.originalData['purchasableProcedureFile'];

      let fileHdrs = new Headers();
      fileHdrs.set('Accept', purchasableProcedureFile.filetype);
      fileHdrs.set('Content-Type', purchasableProcedureFile.filetype);
      fetch(purchasableProcedureFile.location + '/get', {
        headers: fileHdrs
      })
        .then(response => response.blob())
        .then(blob => {
          let addedFileId = this.uppy.addFile({
            id: purchasableProcedureFile.token,
            name: purchasableProcedureFile.name,
            type: blob.type,
            data: blob,
            meta: {
              token: purchasableProcedureFile.token,
              location: purchasableProcedureFile.location
            },
            source: 'remote_' + this.instanceId
          });
          this.uppy.setFileState(addedFileId, {
            //uploadURL: ENTRYPOINT + purchasableProcedureFile.location,
            progress: {
              uploadStarted: true,
              uploadComplete: true
            }
          });

          const plugin = this.uppy.getPlugin('remote_' + this.instanceId);
          if (plugin) {
            plugin.applyJsToFileItem(addedFileId);
          }
        });

      this.uppy.getFiles().forEach(file => {
        if (file.source === 'remote_' + this.instanceId) {
          this.uppy.setFileState(file.id, {
            progress: {
              uploadStarted: true,
              uploadComplete: true
            }
          });
        }
      });
    }
  };

  destroyUppy = () => {
    if (this.uppy) {
      this.uppy.close();
      this.uppy = null;
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentDidMount() {
    this.props.setUppy(this.uppy);
  }

  componentWillUnmount() {
    this.destroyUppy();
  }

  onBeforeFileAdded = (currentFile, files) => {
    if (typeof this.props.onBeforeFileAdded === 'function') {
      this.props.onBeforeFileAdded(currentFile, files, this);
    }
  };

  onBeforeUpload = files => {
    if (typeof this.props.onBeforeUpload === 'function') {
      this.props.onBeforeUpload(files, this);
    }
  };

  onFileAdded = file => {
    if (typeof this.props.onFileAdded === 'function') {
      this.props.onFileAdded(file, this);
    }
  };

  onFileRemoved = file => {
    if (typeof this.props.onFileRemoved === 'function') {
      this.props.onFileRemoved(file, this);
    }
  };

  onComplete = results => {
    if (typeof this.props.onComplete === 'function') {
      this.props.onComplete(results, this);
    }
  };

  updateFileSource = fileId => {
    this.uppy.setFileState(fileId, {
      source: 'remote_' + this.instanceId
    });
    setTimeout(() => {
      const plugin = this.uppy.getPlugin('remote_' + this.instanceId);
      if (plugin) {
        plugin.applyJsToFileItem(fileId);
      }
    }, 1000);
  };

  attachNode = node => {
    this._uploader = node;
  };

  getUploaderComponent = () => {
    return this._uploader;
  };

  updateEndpoint = () => {
    this.setState({
      endpoint:
        ENTRYPOINT +
        (this.props.originalData && this.props.originalData['@id']
          ? this.props.originalData['@id']
          : '') +
        '/files/'
    });
  };

  render() {
    this.updateEndpoint();

    return (
      <DashboardModal
        uppy={this.uppy}
        open={this.props.isModalOpen}
        onRequestClose={this.props.onRequestClose}
        closeModalOnClickOutside={true}
        width={'auto'}
        height={300}
        proudlyDisplayPoweredByUppy={false}
        showLinkToFileUploadResult={false}
        plugins={['remote_' + this.instanceId]}
        ref={ref => this.attachNode(ref)}
      />
    );
  }
}

export default FileAttachment;
