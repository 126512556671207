import { combineReducers } from 'redux';

import { getFilterValues, setFilterValues } from '../../utils/listFilter';

export function error(state = null, action) {
  switch (action.type) {
    case 'CATEGORY_DELETE_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'CATEGORY_DELETE_LOADING':
      return action.loading;

    default:
      return state;
  }
}

export function deleted(state = null, action) {
  switch (action.type) {
    case 'CATEGORY_DELETE_SUCCESS':
      // reset procedure filter category value if it was deleted
      let procedureFilterValues = getFilterValues('procedureFilterValues');
      if (
        procedureFilterValues &&
        procedureFilterValues.category &&
        procedureFilterValues.category.id &&
        action.deleted['@id'] &&
        procedureFilterValues.category.id === action.deleted['@id']
      ) {
        procedureFilterValues.category.id = '';
        setFilterValues('procedureFilterValues', procedureFilterValues);
      }

      // reset usedprocedure filter category value if it was deleted
      let usedprocedureFilterValues = getFilterValues(
        'usedprocedureFilterValues'
      );
      if (
        usedprocedureFilterValues &&
        usedprocedureFilterValues.procedure &&
        usedprocedureFilterValues.procedure.category &&
        usedprocedureFilterValues.procedure.category.id &&
        action.deleted['@id'] &&
        usedprocedureFilterValues.procedure.category.id ===
          action.deleted['@id']
      ) {
        usedprocedureFilterValues.procedure.category.id = '';
        setFilterValues('usedprocedureFilterValues', usedprocedureFilterValues);
      }

      // reset last category if it was just deleted
      let lastProcedureCategoryData = getFilterValues('lastProcedureCategory');
      if (
        lastProcedureCategoryData &&
        lastProcedureCategoryData['@id'] &&
        action.deleted['@id'] &&
        lastProcedureCategoryData['@id'] === action.deleted['@id']
      ) {
        setFilterValues('lastProcedureCategory', {});
      }

      return action.deleted;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, deleted });
