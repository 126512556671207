import React from 'react';

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <footer>
      <div>
        &copy; Copyright {year} Ashbury Systems, LLC. All rights reserved.
        <ul>
          <li>
            <a
              href={'https://www.lista.io/terms-of-use'}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              Terms Of Use
            </a>
          </li>
          <li>|</li>
          <li>
            <a
              href={'https://www.lista.io/privacy-policy'}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li>|</li>
          <li>
            <a href="mailto:help@lista.io" rel="noopener noreferrer">
              help@lista.io
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
