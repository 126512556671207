import React from 'react';

import { Create, List, Show, Update } from '../components/order/';
import { PrivateLayout as Route } from '../components/PrivateLayout';

export default [
  <Route path="/orders/create" component={Create} exact key="create" />,
  <Route path="/orders/edit/:id" component={Update} exact key="update" />,
  <Route path="/orders/show/:id" component={Show} exact key="show" />,
  <Route path="/orders/" component={List} exact strict key="list" />,
  <Route path="/orders/:page" component={List} exact strict key="page" />
];
