import './SideDrawer.css';

import React from 'react';

const sideDrawer = props => {
  let drawerClasses = 'side-drawer';
  if (props.show) {
    drawerClasses = 'side-drawer open';
  }
  return <div className={drawerClasses}>{props.children}</div>;
};

export default sideDrawer;
