import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import CategoryTreeFormFields from './CategoryTreeFormFields';

class TreeForm extends Component {
  state = {
    hasFirstItem: (this.props.initialValues
      ? this.props.initialValues.rootChildren
      : []
    ).length
  };

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    let wrapperClass = 'form-group';
    if (!this.props.lastSelectedFieldIndex) {
      wrapperClass += ' selected-item';
      // add styling class to parent element
      setTimeout(() => {
        // input / form-group / tree-root-item
        let selectedParents = document.getElementsByClassName(
          'selected-item-parent'
        );
        Object.keys(selectedParents).map(spIndex => {
          if (!!selectedParents[spIndex]) {
            selectedParents[spIndex].className = selectedParents[
              spIndex
            ].className.replace('selected-item-parent', '');
          }
          return spIndex;
        });
        let selectedEls = document.getElementsByName(data.input.name);
        if (selectedEls.length > 0 && selectedEls[0]) {
          let selectedEl = selectedEls[0];
          let parentEl = selectedEl.parentNode.parentNode;
          if (parentEl.className.indexOf('selected-item-parent') === -1) {
            parentEl.className += ' selected-item-parent';
          }
        }
      }, 100);
    }

    return (
      <div className={wrapperClass}>
        {/*
      <div className={`form-group`}>
        */}
        {/*
        <label
          htmlFor={`category_${data.input.name}`}
          className="form-control-label"
        >
          {data.input.name}
        </label>
        */}
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`category_${data.input.name}`}
          style={data.style}
          onClick={event => this.handleClick(event, data)}
          onBlur={() => this.handleBlur()}
          autoFocus={data.autoFocus || false}
          readOnly={true}
          value={
            data.input.name === 'name'
              ? 'All Folders/Categories'
              : data.input.value
          }
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  reapplySelectedTreeRootItem = () => {
    // re-apply selected classes
    let selectedEls = document.getElementsByName('name');
    if (selectedEls.length > 0 && selectedEls[0]) {
      let selectedEl = selectedEls[0];
      if (selectedEl.parentNode.className.indexOf('selected-item') === -1) {
        selectedEl.parentNode.className += ' selected-item';
      }
      // input / form-group / tree-root-item
      let parentEl = selectedEl.parentNode.parentNode;
      if (parentEl.className.indexOf('selected-item-parent') === -1) {
        parentEl.className += ' selected-item-parent';
      }
    }
  };

  attachNode = node => {
    this._button = node;
  };

  getButtonComponent = () => {
    return this._button;
  };

  handleClick = (event, data) => {
    if (typeof this.props.selectRootItem === 'function') {
      this.props.selectRootItem(event, data, this);
    }
  };

  handleBlur = () => {
    //this.getButtonComponent().click();
  };

  preventFormSubmit = event => {
    if (
      typeof this.props.allowEnterKey !== 'undefined' &&
      !this.props.allowEnterKey
    ) {
      // when the normal Enter key logic is disabled, perform this instead
      if (event.keyCode === 13 || event.key === 'Enter') {
        let editableElements = document.getElementsByClassName('isEditable');
        if (
          editableElements &&
          editableElements.length > 0 &&
          editableElements[0]
        ) {
          let parent = editableElements[0].parentNode;
          if (parent) {
            let editableEl = parent.querySelector('.edit-tree-item');
            if (editableEl) {
              editableEl.click();
            }
          }
        }
      }
    }

    return this.getTreeComponent().preventFormSubmit(event);
  };

  setHasFirstItem = (status = true) => {
    this.setState({ hasFirstItem: status });
  };

  attachTree = node => {
    this._categoryTree = node;
  };

  getTreeComponent = () => {
    return this._categoryTree;
  };

  pressFormSubmit = () => {
    if (typeof this.props.pressFormSubmit === 'function') {
      this.props.pressFormSubmit();
    }
  };

  editTreeItem = (data, index, event, component) => {
    setTimeout(() => {
      this.getButtonComponent().click();
    }, 100);
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        onKeyDown={this.preventFormSubmit}
      >
        <button
          type="submit"
          className="pull-right"
          style={{
            border: 0,
            height: 0,
            margin: 0,
            padding: 0,
            overflow: 'hidden',
            width: 0
          }}
          ref={this.attachNode.bind(this)}
        >
          Submit
        </button>
        <button
          onClick={this.pressFormSubmit}
          type="submit"
          className="btn btn-primary pull-right"
          disabled={this.props.submitting || this.props.pristine}
        >
          {this.props.submitting
            ? 'Saving...'
            : this.props.pristine
            ? 'Stored'
            : 'Save'}
        </button>
        <div
          className={'tree-root-item'}
          style={{
            display: 'inline-block',
            marginTop: '1rem',
            width: '100%'
          }}
        >
          <span
            className={'fa fa-folder-open-o pull-left'}
            style={{
              display: 'inline-block',
              fontSize: '16px',
              height: '40px',
              lineHeight: '40px',
              paddingLeft: '10px',
              verticalAlign: 'middle'
            }}
          >
            {''}
          </span>
          <Field
            component={this.renderField}
            name="name"
            type="text"
            placeholder="Input Category Name"
            required={true}
            style={{
              border: 0,
              //textAlign: 'center',
              width: '65%'
              //fontSize: '30px'
            }}
            autoFocus={!this.state.hasFirstItem}
          />
        </div>

        <CategoryTreeFormFields
          initialValues={this.props.initialValues}
          change={this.props.change}
          dispatch={this.props.dispatch}
          getButtonComponent={this.getButtonComponent}
          hasFirstItem={this.state.hasFirstItem}
          setHasFirstItem={this.setHasFirstItem}
          displayAddButton={this.props.displayAddButton}
          displayEditItemButton={this.props.displayEditItemButton}
          displayDeleteItemButton={this.props.displayDeleteItemButton}
          displaySelectItemButton={this.props.displaySelectItemButton}
          lastSelectedFieldIndex={this.props.lastSelectedFieldIndex}
          editTreeItem={this.editTreeItem}
          selectTreeItem={this.props.selectTreeItem}
          trackSelectTreeItem={this.props.trackSelectTreeItem}
          allowEnterKey={this.props.allowEnterKey}
          dragOverTreeItem={this.props.dragOverTreeItem}
          dropTreeItem={this.props.dropTreeItem}
          isDraggingEnabled={this.props.isDraggingEnabled}
          ref={this.attachTree.bind(this)}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'category',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(TreeForm);
