import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';

import { retrieve } from '../../actions/procedurestep/show';
import imageUpload from '../../assets/img/temp-upload-image.png';
import { fetch } from '../../utils/dataAccess';
import SecureImage from '../SecureImage';

class DeleteFilesModal extends Component {
  state = {
    isModalOpen: this.props.isOpen,
    isConfirmationModalOpen: false,
    selectedFilesForDelete: []
  };

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    deletedItem: PropTypes.object,
    getProcedureStep: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.getProcedureStep(this.props.data);
  }

  handleDeleteModalClose = () => {
    this.setState({ isModalOpen: false, isConfirmationModalOpen: false });
    if (typeof this.props.handleClose === 'function') {
      this.props.handleClose();
    }
  };

  handleCloseConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  handleOpenConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: true });
  };

  deleteSelectedFiles = async () => {
    if (typeof this.props.saveButtonComponent === 'function') {
      this.props.saveButtonComponent().click();
    }

    for (const file of this.state.selectedFilesForDelete) {
      await this.handleFileDelete(file);
    }

    this.refreshPage();
  };

  handleFileDelete = async file => {
    await fetch(file.location, {
      method: 'DELETE'
    }).then(async response => {
      if (this.props.retrieved.procedureStepFiles < 1) {
        this.handleDeleteModalClose();
      }
    });
  };

  refreshPage = () => {
    window.location.reload(false);
  };

  addOrRemoveFromBatch = file => {
    let newBatch = this.state.selectedFilesForDelete;
    if (newBatch.includes(file)) {
      const index = newBatch.indexOf(file);
      if (index !== -1) {
        newBatch.splice(index, 1);
      }
    } else {
      newBatch.push(file);
    }

    this.setState(prevState => {
      return {
        ...prevState,
        selectedFilesForDelete: newBatch
      };
    });
  };

  render() {
    let files;
    files = this.props.retrieved && this.props.retrieved.procedureStepFiles;

    let filesArray = [];
    files &&
      Object.keys(files).forEach(key => {
        let file = files[key];
        file.id = parseInt(key);
        filesArray.push(files[key]);
      });

    if (!filesArray || filesArray.length < 1) {
      return (
        <div>
          <Modal
            show={this.props.isOpen}
            onHide={this.handleDeleteModalClose}
            centered
            size="small"
            id={'delete-files-modal'}
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              There are currently no files attached to this step.
            </Modal.Body>
            <Modal.Footer>
              <button
                className={'btn btn-primary'}
                onClick={this.handleDeleteModalClose}
              >
                Okay
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }

    return (
      <div>
        {/* File Select Modal */}
        <Modal
          show={this.props.isOpen}
          onHide={this.handleDeleteModalClose}
          centered
          size={filesArray && filesArray.length > 3 ? 'lg' : ''}
          id={'delete-files-modal'}
        >
          <Modal.Header closeButton>
            <Modal.Title>Which file(s) would you like to delete?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={'d-flex flex-row justify-content-around mt-2'}>
              {filesArray &&
                filesArray.length > 0 &&
                filesArray.map(file => {
                  return (
                    <div
                      className={
                        'd-flex flex-column uppy-DashboardItem-previewImg'
                      }
                    >
                      <SecureImage
                        file={file}
                        zoom={false}
                        alt={'Uploaded image'}
                        id={'file_' + file.id}
                        src={imageUpload}
                        attributes={{
                          width: 125,
                          style: { cursor: 'pointer' }
                        }}
                      />
                      <div className={'align-self-center'}>
                        <label className={'checkbox-container'}>
                          <input type="checkbox" name="key" value="value" />
                          <span
                            className="checkmark"
                            onClick={e => this.addOrRemoveFromBatch(file)}
                          ></span>
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={'btn btn-secondary'}
              onClick={this.handleDeleteModalClose}
            >
              Cancel
            </button>
            <button
              disabled={this.state.selectedFilesForDelete.length === 0}
              className={'btn btn-danger'}
              onClick={() => this.handleOpenConfirmationModal()}
            >
              Delete Selected Files
            </button>
          </Modal.Footer>
        </Modal>

        {/* Confirmation Modal */}
        <Modal
          show={this.state.isConfirmationModalOpen}
          onHide={this.handleCloseConfirmationModal}
          // centered
          backdrop="static"
          keyboard={false}
          id={'delete-files-confirmation-modal'}
          size="small"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Are you sure you want to delete these selected files?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>This action cannot be undone.</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={'btn btn-secondary'}
              onClick={this.handleCloseConfirmationModal}
            >
              Cancel
            </button>
            <button
              className={'btn btn-danger'}
              onClick={event => {
                this.deleteSelectedFiles();
              }}
            >
              Confirm Delete
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource } = state.procedurestep.show;

  return { retrieved, loading, error, eventSource };
};

const mapDispatchToProps = dispatch => ({
  getProcedureStep: procedureStepId => dispatch(retrieve(procedureStepId))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFilesModal);
