import React from 'react';

//import { Route } from 'react-router-dom';
import { PublicLayout as PublicRoute } from '../components/PublicLayout';
import { ResettingRequest, ResettingReset } from '../components/resetting/';

export default [
  <PublicRoute
    path="/resetting/request"
    component={ResettingRequest}
    exact
    key="resettingRequest"
  />,
  <PublicRoute
    path="/resetting/reset/:token"
    component={ResettingReset}
    exact
    key="resettingReset"
  />
];
