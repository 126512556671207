import { combineReducers } from 'redux';

import { login } from '../../utils/auth';

export function error(state = null, action) {
  switch (action.type) {
    case 'ACCOUNT_LOGIN_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'ACCOUNT_LOGIN_LOADING':
      return action.loading;

    default:
      return state;
  }
}

export function loggedin(state = null, action) {
  switch (action.type) {
    case 'ACCOUNT_LOGIN_SUCCESS':
      if (action.loggedin && action.loggedin.token) {
        login(action.loggedin.token); // The JWT token is stored in the browser's local storage
      }
      return action.loggedin;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, loggedin });
