import { combineReducers } from 'redux';

import { login } from '../../utils/auth';

export function error(state = null, action) {
  switch (action.type) {
    case 'ORGANIZATION_CREATE_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'ORGANIZATION_CREATE_LOADING':
      return action.loading;

    default:
      return state;
  }
}

export function created(state = null, action) {
  switch (action.type) {
    case 'ORGANIZATION_CREATE_SUCCESS':
      if (action.created && action.created['@_token']) {
        login(action.created['@_token']); // The JWT token is stored in the browser's local storage
      }
      return action.created;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, created });
