import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { create, reset } from '../../actions/organization/create';
import { VersionLink as Link } from '../VersionLink';
import Form from './Form';

class Create extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    created: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    if (this.props.created && !!this.props.inModal) {
      if (typeof this.props.inModalComplete === 'function') {
        return this.props.inModalComplete(this.props.created, true);
      }
    }

    if (this.props.created)
      return (
        <Redirect
          to={`edit/${encodeURIComponent(this.props.created['@id'])}`}
        />
      );

    return (
      <div>
        {!this.props.inModal && <h1>New Organization</h1>}

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        <Form
          onSubmit={this.props.create}
          initialValues={this.props.item}
          isCreate={true}
        />
        {!this.props.inModal && (
          <Link to="." className="btn btn-primary">
            Back to list
          </Link>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { created, error, loading } = state.organization.create;
  return { created, error, loading };
};

const mapDispatchToProps = dispatch => ({
  create: values => dispatch(create(values)),
  reset: () => dispatch(reset())
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
