import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, change as changeFormField, reduxForm } from 'redux-form';

class InviteConfirmForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  handleChangeFirstName = event => {
    const name = this.firstNameInput.value + ' ' + this.lastNameInput.value;
    this.nameInput.value = name;
    this.props.dispatch(changeFormField('inviteConfirm', 'name', name));
  };

  handleChangeLastName = event => {
    const name = this.firstNameInput.value + ' ' + this.lastNameInput.value;
    this.nameInput.value = name;
    this.props.dispatch(changeFormField('inviteConfirm', 'name', name));
  };

  renderHiddenField = data => {
    return (
      <input
        {...data.input}
        type={'hidden'}
        step={data.step}
        id={`inviteConfirm_${data.input.name}`}
        readOnly={data.readOnly || false}
        ref={data.innerRef || undefined}
      />
    );
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group`}>
        <label
          htmlFor={`inviteConfirm_${data.input.name}`}
          className="form-control-label"
        >
          {data.label || data.input.name}
        </label>
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`inviteConfirm_${data.input.name}`}
          readOnly={data.readOnly || false}
          disabled={data.disabled || undefined}
          ref={data.innerRef || undefined}
          onChange={data.onChange || data.input.onChange}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={this.renderHiddenField}
          name="name"
          label="Name"
          type="text"
          placeholder="e.g. Jane Doe"
          required={true}
          readOnly={!!this.props.isExistingUser}
          innerRef={ref => (this.nameInput = ref)}
        />
        <Field
          component={this.renderField}
          name="firstName"
          label="First Name"
          type="text"
          placeholder="e.g. Jane"
          required={true}
          readOnly={!!this.props.isExistingUser}
          innerRef={ref => (this.firstNameInput = ref)}
          onChange={this.handleChangeFirstName}
        />
        <Field
          component={this.renderField}
          name="lastName"
          label="Last Name"
          type="text"
          placeholder="e.g. Doe"
          required={true}
          readOnly={!!this.props.isExistingUser}
          innerRef={ref => (this.lastNameInput = ref)}
          onChange={this.handleChangeLastName}
        />
        <Field
          component={this.renderField}
          //name="company"
          name="invitedOrganizationName"
          label="Company"
          type="text"
          placeholder="e.g. Acme Corp."
          readOnly={!!this.props.isExistingUser}
          disabled={true}
        />
        <Field
          component={this.renderField}
          name="email"
          label="Email"
          type="email"
          placeholder="janedoe@example.com"
          //required={true}
          readOnly={!!this.props.isExistingUser}
          disabled={true}
        />
        <Field
          component={this.renderField}
          name="plainPassword"
          label="Password"
          type="password"
          placeholder=""
          required={true}
        />

        <button type="submit" className="btn btn-block btn-secondary mb-3">
          {!!this.props.isExistingUser
            ? 'Accept Invitation'
            : 'Complete Registration'}
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'inviteConfirm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(InviteConfirmForm);
