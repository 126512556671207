import { SubmissionError } from 'redux-form';

import { fetch } from '../../utils/dataAccess';
import { trackRegistrationClick } from '../../utils/tracking';

export function error(error) {
  return { type: 'ACCOUNT_REGISTER_ERROR', error };
}

export function loading(loading) {
  return { type: 'ACCOUNT_REGISTER_LOADING', loading };
}

export function success(registered) {
  if (registered) {
    trackRegistrationClick(registered);
  }

  return { type: 'ACCOUNT_REGISTER_SUCCESS', registered };
}

export function register(values) {
  var headers = new Headers();
  headers.set('Content-Type', 'application/x-www-form-urlencoded');
  const referringUrl = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referURL = urlParams.get('referring_url');

    return referURL;
  };
  values.referring_url = referringUrl();
  var queryString = Object.keys(values)
    .map(
      key =>
        encodeURIComponent('registration_form[' + key + ']') +
        '=' +
        encodeURIComponent(values[key])
    )
    .join('&');

  return dispatch => {
    dispatch(loading(true));

    return fetch('/register', { method: 'POST', body: queryString, headers })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => dispatch(success(retrieved)))
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));

    // clear out the existing data to allow multiple register and logout actions quickly after redirects
    dispatch(success(null));
  };
}
