import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { HOMEPAGE_URL } from '../../config/entrypoint';
import { logout } from '../../utils/auth';

class Logout extends Component {
  render() {
    logout();

    if (HOMEPAGE_URL) {
      window.location.href = HOMEPAGE_URL;

      // the redirect above takes a moment, so show the login form just in case
      return <Redirect to="/login" />;
    }

    return <Redirect to="/" />;
  }
}

export default Logout;
