import React from 'react';

import {
  Create,
  List,
  Show,
  Update,
  UpdateTree
} from '../components/category/';
//import { Route } from 'react-router-dom';
import { PrivateLayout as Route } from '../components/PrivateLayout';

export default [
  <Route path="/categories/create" component={Create} exact key="create" />,
  <Route
    path="/categories/edit/root"
    component={UpdateTree}
    exact
    key="update_tree"
  />,
  <Route path="/categories/edit/:id" component={Update} exact key="update" />,
  <Route path="/categories/show/:id" component={Show} exact key="show" />,
  <Route path="/categories/" component={List} exact strict key="list" />,
  <Route path="/categories/:page" component={List} exact strict key="page" />
];
