import React, { Component } from 'react';

import { fetch } from '../utils/dataAccess';

class SecureFile extends Component {
  handleClick = event => {
    let file = this.props.file;

    let fileHdrs = new Headers();
    fileHdrs.set('Accept', file.filetype);
    fileHdrs.set('Content-Type', file.filetype);

    fetch(file.location + '/get', {
      headers: fileHdrs
    })
      .then(response => response.blob())
      .then(blob => {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.props.file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  render() {
    return (
      <span
        {...this.props.attributes}
        id={this.props.id}
        onClick={event => this.handleClick(event)}
      >
        Download {this.props.file.name}
      </span>
    );
  }
}

export default SecureFile;
