// https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
import { connect } from 'react-redux';

import {
  isAdminForOrganization,
  isManagerForOrganization
} from '../utils/auth';
import { canCreatePurchasableProcedures } from '../utils/ecommerce';
import { isActiveCurrentOrganizationSubscriptionForAccount } from '../utils/subscription';

const rules = {
  'category:create': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'category:delete': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'category:edit': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'category:view': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'procedure:clone': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'procedure:create': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'procedure:delete': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'procedure:edit': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'procedure:view': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'purchasableProcedure:create': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    canCreatePurchasableProcedures(retrieved) &&
    isAdminForOrganization(getCurrentOrganizationId(retrieved)),
  'tag:create': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'tag:delete': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'tag:edit': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'tag:view': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'usedprocedure:create': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'usedprocedure:delete': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'usedprocedure:edit': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'usedprocedure:view': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'user:create': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'user:delete': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'user:edit': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'user:edit_attributes': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    isAdminForOrganization(getCurrentOrganizationId(retrieved)),
  'user:invite': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved))),
  'user:view': ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    (isAdminForOrganization(getCurrentOrganizationId(retrieved)) ||
      isManagerForOrganization(getCurrentOrganizationId(retrieved)))
};

const getCurrentOrganizationId = account =>
  (account && account['@_organizationCurrentId']) || '';

const check = (rules, action, props) => {
  const permissions = rules[action];

  if (permissions) {
    return permissions(props);
  }

  return false;
};

const CurrentUserCan = props =>
  check(rules, props.perform, props) ? props.yes() : props.no();

CurrentUserCan.defaultProps = {
  yes: () => null,
  no: () => null
};

export const checkCurrentUserCan = (
  account,
  perform,
  returnYes = true,
  returnNo = false,
  props = {}
) =>
  CurrentUserCan({
    retrieved: account,
    perform,
    yes: () => returnYes,
    no: () => returnNo,
    ...props
  });

const mapStateToProps = state => ({
  retrieved: state.account.show.retrieved
});

const mapDispatchToProps = dispatch => ({
  //retrieve: id => dispatch(retrieve(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentUserCan);
