import React from 'react';
import { Popover } from 'react-bootstrap';

import { getCurrentOrganizationForAccount } from './subscription';

export function canCreatePurchasableProcedures(account) {
  let canCreatePurchasableProcedures = false;
  const currentOrganization = getCurrentOrganizationForAccount(account);

  if (
    !!currentOrganization &&
    !!currentOrganization.canCreatePurchasableProcedures
  ) {
    canCreatePurchasableProcedures = true;
  }

  return canCreatePurchasableProcedures;
}

export const emptyCartTooltipOverlay = (
  <Popover id="popover-basic">
    <Popover.Title as="h3">Your cart is empty.</Popover.Title>
    <Popover.Content>
      Please add an item to your cart before continuing.
    </Popover.Content>
  </Popover>
);

export function formatTotalToDollars(total) {
  total = parseFloat(total);

  let dollarUSLocale = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return dollarUSLocale.format(total);
}
