import React from 'react';

import { Account, Login, Logout, Register } from '../components/account/';
//import { Route } from 'react-router-dom';
import { LoginLayout as Route } from '../components/LoginLayout';
import { PrivateLayout as PrivateRoute } from '../components/PrivateLayout';

export default [
  <Route path="/login" component={Login} exact key="login" />,
  <Route path="/logout" component={Logout} exact key="logout" />,
  <Route path="/register" component={Register} exact key="register" />,
  <PrivateRoute path="/account" component={Account} exact key="account" />
];
