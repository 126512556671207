import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  isAdminForOrganization,
  isManagerForOrganization
} from '../../utils/auth';
import {
  isSubscriptionStatusAtLimit,
  isSubscriptionStatusBelowLimit,
  isSubscriptionStatusInvalid,
  isSubscriptionStatusTrialEnded
} from '../../utils/subscription';

class UserSubscriptionStatus extends Component {
  render() {
    const subscription = this.props.currentOrganizationSubscription;
    const organization = this.props.currentOrganization;
    const organizationId = organization['@id'] || null;

    return (
      <span className={'user-subscription-status mr-3'}>
        {/* at limit */}
        {isAdminForOrganization(organizationId) &&
          isSubscriptionStatusAtLimit(subscription) && (
            <span>
              Your subscription allows {subscription.quantity} seat
              {subscription.quantity === 1 ? '' : 's'}. Please{' '}
              <Link to={'/account'}>click here</Link> if you'd like to purchase
              additional seats.
            </span>
          )}
        {!isAdminForOrganization(organizationId) &&
          isManagerForOrganization(organizationId) &&
          isSubscriptionStatusAtLimit(subscription) && (
            <span>
              All your organization's seats are used. Please contact an admin if
              you need more seats.
            </span>
          )}
        {/* below limit */}
        {isAdminForOrganization(organizationId) &&
          isSubscriptionStatusBelowLimit(subscription) && (
            <span>
              Your subscription includes {subscription.quantity} seat
              {subscription.quantity === 1 ? '' : 's'}. Feel free to invite
              additional users.
            </span>
          )}
        {/* trial ended */}
        {isAdminForOrganization(organizationId) &&
          isSubscriptionStatusTrialEnded(subscription) && (
            <span>
              Your trial subscription has ended. Please{' '}
              <Link to={'/account'}>click here</Link> to add at least one seat.
            </span>
          )}
        {/* invalid subscription, i.e. declined / expired CC, or previously cancelled */}
        {isAdminForOrganization(organizationId) &&
          isSubscriptionStatusInvalid(subscription) && (
            <span>
              Your subscription is not currently active. Please{' '}
              <Link to={'/account'}>click here</Link> to add at least one seat.
            </span>
          )}
      </span>
    );
  }
}

export default UserSubscriptionStatus;
