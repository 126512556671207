import {
  extractHubURL,
  fetch,
  normalize,
  mercureSubscribe as subscribe
} from '../../utils/dataAccess';
import { getCurrentOrganizationForAccount } from '../../utils/subscription';
import { success as deleteSuccess } from './delete';

export function error(error) {
  return { type: 'CART_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'CART_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'CART_LIST_SUCCESS', retrieved };
}

export function list(page = 'carts', account = null) {
  return async dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    return await fetch(page)
      .then(response =>
        response
          .json()
          .then(retrieved => ({ retrieved, hubURL: extractHubURL(response) }))
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);
        const currentOrganization = getCurrentOrganizationForAccount(account);
        if (retrieved['hydra:member'] && retrieved['hydra:member'].length > 0) {
          retrieved['hydra:member'] = retrieved['hydra:member'].filter(
            cart =>
              cart.customer === account['@id'] &&
              cart.organization === currentOrganization['@id']
          );
          retrieved['hydra:totalItems'] = retrieved['hydra:member'].length || 0;
        }

        dispatch(loading(false));
        dispatch(success(retrieved));

        if (hubURL && retrieved['hydra:member'].length)
          dispatch(
            mercureSubscribe(
              hubURL,
              retrieved['hydra:member'].map(i => i['@id'])
            )
          );

        return retrieved;
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'CART_LIST_RESET' });
    dispatch(deleteSuccess(null));
  };
}

export function mercureSubscribe(hubURL, topics) {
  return dispatch => {
    const eventSource = subscribe(hubURL, topics);
    dispatch(mercureOpen(eventSource));
    eventSource.addEventListener('message', event =>
      dispatch(mercureMessage(normalize(JSON.parse(event.data))))
    );
  };
}

export function mercureOpen(eventSource) {
  return { type: 'CART_LIST_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved) {
  return dispatch => {
    if (1 === Object.keys(retrieved).length) {
      dispatch({ type: 'CART_LIST_MERCURE_DELETED', retrieved });
      return;
    }

    dispatch({ type: 'CART_LIST_MERCURE_MESSAGE', retrieved });
  };
}
