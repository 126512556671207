import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { connect } from 'react-redux';

import { reset, retrieve } from '../../actions/account/show';
import { del } from '../../actions/organization/delete';
import { switchActive } from '../../actions/organization/switchActive';
import CreateOrganization from '../../components/organization/Create';
import UpdateOrganization from '../../components/organization/Update';
import { getCurrentUserId, isAdminForOrganization } from '../../utils/auth';
import CurrentUserCan from '../CurrentUserCan';
//import { Link } from 'react-router-dom';
import { VersionLink as Link } from '../VersionLink';

class UserNavigation extends Component {
  state = {
    organizationModals: {}
  };

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    const userId = getCurrentUserId();
    this.props.retrieve(decodeURIComponent(userId));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = item => {
    if (window.confirm('Are you sure you want to delete this organization?')) {
      this.props.del(item);
      // update user-navigation
      const userId = getCurrentUserId();
      this.props.retrieve(decodeURIComponent(userId));
    }
  };

  delNow = (item, event) => {
    event.target.disabled = true;
    event.target.innerHTML += '<span class="fa fa-spinner fa-spin"></span>';

    let delItem = this.props.del(item);

    if (typeof delItem.then === 'function') {
      delItem.then(() => {
        /*
        // update user-navigation
        const userId = getCurrentUserId();
        this.props.retrieve(decodeURIComponent(userId));

        this.handleClose({ '@id': 'delete_' + item['@id'] });
        */
        window.location.reload();
      });
    }
  };

  setShow = (organization, value) => {
    this.setState({
      organizationModals: {
        ...this.state.organizationModals,
        [organization['@id']]: value
      }
    });
  };

  handleClose = organization => this.setShow(organization, false);

  handleShow = organization => this.setShow(organization, true);

  inModalComplete = (organization, isCreate) => {
    // wrap the state updates in setTimeout so that the render() calls in other components will work
    let timeout = 0; // the value is zero to be as fast as possible
    setTimeout(() => {
      // close any modal
      this.handleClose({ '@id': 'create_org' });
      this.handleClose(organization);
      if (isCreate) {
        // immediately switch to the new organization
        this.handleSwitchActive(organization);
      } else {
        // update user-navigation
        const userId = getCurrentUserId();
        this.props.retrieve(decodeURIComponent(userId));
      }
    }, timeout);

    return <span>{''}</span>;
  };

  handleSwitchActive = organization => {
    let switched = this.props.switchActive(organization);

    if (typeof switched.then === 'function') {
      switched.then(() => {
        // update user-navigation
        const userId = getCurrentUserId();
        this.props.retrieve(decodeURIComponent(userId));
      });
    } else {
      // update user-navigation
      const userId = getCurrentUserId();
      this.props.retrieve(decodeURIComponent(userId));
    }

    setTimeout(() => {
      // redirect
      window.location.pathname = '/procedures/';
    }, 500);
  };

  render() {
    const item = this.props.retrieved;

    return (
      <div className={'user-navigation'}>
        {this.props.loading && (
          <div
            className="pull-right"
            role="status"
            style={{ minWidth: '175px' }}
          >
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                Loading...
              </div>
            </div>
          </div>
        )}
        {this.props.error && (
          <div>
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        {item && (
          <NavDropdown
            title={
              'My Account'
              // 'Welcome ' +
              // item['name'] +
              // (item['organizationName']
              //   ? ' (' + item['organizationName'] + ')'
              //   : '')
            }
            id="collapsible-nav-dropdown"
          >
            {item.organizationUsers &&
              item.organizationUsers.map(organizationUser => (
                <div
                  className={
                    item['@_organizationCurrentId'] ===
                    organizationUser.organization['@id']
                      ? 'dropdown-item organization-link active-organization'
                      : 'organization-link dropdown-item'
                  }
                >
                  <Link
                    to={'.'}
                    onClick={() =>
                      this.handleSwitchActive(organizationUser.organization)
                    }
                  >
                    {organizationUser.organization.name ||
                      // organizationUser.organization['@id']}
                      item['name'] + "'s Org"}
                  </Link>
                  {isAdminForOrganization(
                    organizationUser.organization['@id']
                  ) && (
                    <>
                      <button
                        className={'btn ml-1 edit-organization'}
                        onClick={() =>
                          this.handleShow(organizationUser.organization)
                        }
                      >
                        <span className="fa fa-pencil" aria-hidden="true" />
                        <span className="sr-only">Edit</span>
                      </button>
                      <Modal
                        show={
                          this.state.organizationModals[
                            organizationUser.organization['@id']
                          ] || false
                        }
                        onHide={() =>
                          this.handleClose(organizationUser.organization)
                        }
                        centered
                        size="xl"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Organization</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <UpdateOrganization
                            match={{
                              params: {
                                id: organizationUser.organization['@id']
                              }
                            }}
                            inModal={true}
                            inModalComplete={this.inModalComplete}
                          />
                        </Modal.Body>
                      </Modal>
                    </>
                  )}
                  {isAdminForOrganization(
                    organizationUser.organization['@id']
                  ) && (
                    <>
                      <button
                        className={'btn ml-1 delete-organization'}
                        onClick={() =>
                          this.handleShow({
                            '@id':
                              'delete_' + organizationUser.organization['@id']
                          })
                        }
                      >
                        <span className="fa fa-trash" aria-hidden="true" />
                        <span className="sr-only">Delete</span>
                      </button>
                      <Modal
                        show={
                          this.state.organizationModals[
                            'delete_' + organizationUser.organization['@id']
                          ] || false
                        }
                        onHide={() =>
                          this.handleClose({
                            '@id':
                              'delete_' + organizationUser.organization['@id']
                          })
                        }
                        centered
                        size="xl"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            Are you sure you want to delete{' '}
                            {organizationUser.organization.name ||
                              'this Organization'}
                            ?
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>This will delete:</p>
                          <ul>
                            <li>
                              All User links to{' '}
                              {organizationUser.organization.name ||
                                'this Organization'}
                            </li>
                            {/* <li>All Procedures in this Organization</li> */}
                            <li>
                              {/*All Templates in{' '}*/}
                              All Case Workflows in{' '}
                              {organizationUser.organization.name ||
                                'this Organization'}
                            </li>
                            <li>
                              All records linked to{' '}
                              {organizationUser.organization.name ||
                                'this Organization'}
                            </li>
                          </ul>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            className={'btn'}
                            onClick={() =>
                              this.handleClose({
                                '@id':
                                  'delete_' +
                                  organizationUser.organization['@id']
                              })
                            }
                          >
                            Cancel
                          </button>
                          <button
                            className={'btn btn-danger'}
                            onClick={event =>
                              this.delNow(organizationUser.organization, event)
                            }
                          >
                            Delete Organization
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </>
                  )}
                </div>
              ))}
            <>
              <button
                className={'dropdown-item'}
                onClick={() => this.handleShow({ '@id': 'create_org' })}
              >
                Add New Organization
              </button>
              <Modal
                show={this.state.organizationModals['create_org'] || false}
                onHide={() => this.handleClose({ '@id': 'create_org' })}
                centered
                size="xl"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add New Organization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className={'row'}>
                    <div className={'col-md-6'}>
                      <CreateOrganization
                        inModal={true}
                        inModalComplete={this.inModalComplete}
                        item={{ ownerEmail: item.email }}
                      />
                    </div>
                    <div className={'col-md-6'}>
                      <div className={'pt-2 pl-2 pr-2'}>
                        <p>
                          {/* If you would like to manage procedures on behalf of */}
                          {/*If you would like to manage templates on behalf of*/}
                          If you would like to manage case workflows on behalf of
                          more than one company, or even other departments
                          within your current organization, you can name them
                          here. Each organization gets its own free trial and
                          will be billed separately.
                        </p>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </>
            <NavDropdown.Divider />
            <CurrentUserCan
              perform={'tag:view'}
              yes={() => (
                <Link to="/tags/" className={'dropdown-item'}>
                  Manage Tags
                </Link>
              )}
            />
            <CurrentUserCan
              perform={'user:view'}
              yes={() => (
                <Link to="/users/" className={'dropdown-item'}>
                  Manage Users
                </Link>
              )}
            />
            {isAdminForOrganization(item['@_organizationCurrentId'] || '') && (
              <Link to="/account" className={'dropdown-item'}>
                Manage Account
              </Link>
            )}
            <Link to="/logout" className={'dropdown-item'}>
              Log Out
            </Link>
          </NavDropdown>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.account.show.retrieved,
  error: state.account.show.error,
  loading: state.account.show.loading,
  eventSource: state.account.show.eventSource
});

const mapDispatchToProps = dispatch => ({
  switchActive: item => dispatch(switchActive(item)),
  del: item => dispatch(del(item)),
  retrieve: id => dispatch(retrieve(id)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserNavigation);
