import type { TreeData } from '@atlaskit/tree/types';
import { cloneDeep } from 'lodash';

import TreeBuilder from './TreeBuilder';

export const generateStepTree: TreeBuilder = (
  items,
  rootId,
  data,
  visited,
  fields,
  fieldIndexes,
  fieldExpanded
) => {
  let tree = new TreeBuilder(rootId, data);

  items.map(item => {
    let idNumber = item['@id'].replace(/[^\d]*/, '');

    if (
      visited.indexOf(item['@id']) === -1 &&
      item.children &&
      item.children.length > 0
    ) {
      visited.push(item['@id']);

      tree.withSubTree(
        generateStepTree(
          item.children,
          idNumber,
          {
            field: fields[item['@id']],
            isRemoved: false,
            isExpanded: fieldExpanded[item['@id']],
            originalFieldIndex: fieldIndexes[item['@id']],
            originalFieldValue: null, // placeholder for TreeBuilder
            originalId: item['@id'],
            originalData: item
          },
          visited,
          fields,
          fieldIndexes,
          fieldExpanded
        )
      );
    } else if (visited.indexOf(item['@id']) === -1) {
      visited.push(item['@id']);

      tree.withLeaf(idNumber, {
        field: fields[item['@id']],
        isRemoved: false,
        isExpanded: fieldExpanded[item['@id']],
        originalFieldIndex: fieldIndexes[item['@id']],
        originalFieldValue: null, // placeholder for TreeBuilder
        originalId: item['@id'],
        originalData: item
      });
    }

    return item;
  });

  return tree;
};

export const getStepTree: TreeData = (
  inputItems,
  fieldCallback,
  removedFields = [],
  expansionMap = {}
) => {
  let items = [];
  if (Array.isArray(inputItems)) {
    items = inputItems;
  } else {
    Object.keys(inputItems).map((key, index) => {
      items.push(inputItems[key]);
      return key;
    });
  }

  let expansions = {};
  Object.keys(expansionMap).map(expansionMapItemId => {
    // the last number in the itemId should match the database ID
    let itemIdNumber = expansionMapItemId.split('-').pop();
    expansions = {
      ...expansions,
      [itemIdNumber]: expansionMap[expansionMapItemId]
    };
    return expansionMapItemId;
  });

  let existingIndexes = [];

  let removedIndexes = [];
  removedFields.map(removedField => {
    let removed = parseInt(Object.keys(removedField)[0]);
    if (removedIndexes.indexOf(removed) === -1) {
      removedIndexes = [...removedIndexes, removed];
      existingIndexes = [...existingIndexes, removed];
    }
    return removedField;
  });

  let fields = [];
  let fieldIndexes = [];
  let fieldExpanded = [];
  // maintain original index from database ordered fields
  items.map((item, index) => {
    let countIndex = 0;
    while (existingIndexes.indexOf(countIndex) !== -1) {
      countIndex++;
    }
    existingIndexes = [...existingIndexes, countIndex];
    //let fieldIndex = countIndex;

    let idNumber = item['@id'].replace(/[^\d]*/, '');
    let fieldIndex = idNumber;

    fields[item['@id']] = fieldCallback(item, fieldIndex);
    fieldIndexes[item['@id']] = fieldIndex;
    fieldExpanded[item['@id']] = expansions.hasOwnProperty(idNumber)
      ? expansions[idNumber]
      : true;
    return item;
  });

  let orderedItems = cloneDeep(items);

  orderedItems = expandChildrenIdsToObjects(orderedItems, orderedItems);

  nestedSort(orderedItems);

  let tree = generateStepTree(
    orderedItems,
    0,
    {},
    [],
    fields,
    fieldIndexes,
    fieldExpanded
  );

  return tree.build();
};

const expandChildrenIdsToObjects = (items, originalItems) => {
  return items.map(item => {
    if ('string' === typeof item) {
      let result = originalItems.find(
        element => element['@id'] && element['@id'] === item
      );
      if (result) {
        item = result;
      }
    }

    if (item.children && item.children.length > 0) {
      item.children = expandChildrenIdsToObjects(item.children, originalItems);
    }

    return item;
  });
};

const nestedSort = items => {
  items.map(item => {
    if (item.children && item.children.length > 0) {
      nestedSort(item.children);
    }

    return item;
  });

  items.sort((a, b) => {
    if (a.level > b.level) {
      return 1;
    }
    if (a.level < b.level) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    if (a.position < b.position) {
      return -1;
    }

    return 0;
  });
};

export const removeFieldWithinTree = (
  obj,
  fieldNames = [],
  skipClone = false
) => {
  if (skipClone) {
    // do nothing
  } else {
    obj = cloneDeep(obj);
  }
  Object.keys(obj).forEach(
    key =>
      ((key === 'field' ||
        key === 'originalData' ||
        fieldNames.indexOf(key) !== -1) &&
        delete obj[key]) ||
      (obj[key] &&
        typeof obj[key] === 'object' &&
        removeFieldWithinTree(obj[key], fieldNames, true))
  );

  return obj;
};
