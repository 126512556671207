import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { Field, reduxForm } from 'redux-form';

import { fetch } from '../../utils/dataAccess';

class Form extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group`}>
        {/*
        <label
          htmlFor={`procedureFilter_${data.input.name}`}
          className="form-control-label"
        >
          {data.input.name}
        </label>
        */}
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`procedureFilter_${data.input.name}`}
          onKeyDown={() => this.handleKeyDown()}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderSelectField = data => {
    return (
      <div className={`form-group procedureFilter-${data.input.name}-wrapper`}>
        <AsyncSelect
          {...data.input}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`procedureFilter_${data.input.name}`}
          className={`procedureFilter-${data.input.name}`}
          classNamePrefix={`procedureFilter-${data.input.name}`}
          defaultOptions
          onBlur={event => {
            event.preventDefault(); // https://stackoverflow.com/questions/48419984/how-to-have-searchable-dropdown-in-redux-form
            this.handleBlur();
          }}
          onChange={(newValue, actionMeta) => {
            this.handleChange(newValue, actionMeta);
            data.input.onChange(newValue, actionMeta);
          }}
          isMulti={data.isMulti || false}
          isClearable={true}
          loadOptions={data.loadOptions}
          // setting the value explicitly so that it will appear on page load
          value={
            data.input.value === ''
              ? undefined
              : //: [{ label: data.input.value, value: data.input.value }]
                data.input.value
          }
          getOptionLabel={this.getOptionLabel}
          getOptionValue={this.getOptionValue}
          ref={ref => this.attachSelectField(ref, data.input.name)}
        />
      </div>
    );
  };

  renderCheckboxField = data => {
    data.input.className = ''; //'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    return (
      <div className={`form-group`}>
        <label
          htmlFor={`procedureFilter_${data.input.name}`}
          className="form-control-label"
        >
          <input
            {...data.input}
            type={data.type || 'checkbox'}
            step={data.step}
            required={data.required}
            placeholder={data.placeholder}
            id={`procedureFilter_${data.input.name}`}
            onClick={() => this.handleClick()}
          />{' '}
          {data.placeholder}
        </label>
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  renderHiddenField = data => {
    return (
      <input
        {...data.input}
        type={'hidden'}
        step={data.step}
        id={`procedureFilter_${data.input.name}`}
      />
    );
  };

  attachNode = node => {
    this._button = node;
  };

  getButtonComponent = () => {
    return this._button;
  };

  handleClick = () => {
    setTimeout(() => {
      this.getButtonComponent().click();
    }, 100);
  };

  handleKeyDown = debounce(
    () => {
      this.getButtonComponent().click();
    },
    100,
    { maxWait: 500 }
  );

  handleBlur = () => {
    this.getButtonComponent().click();
  };

  handleChange = (newValue, actionMeta) => {
    if (
      ((Array.isArray(newValue) && newValue.length > 0) || !!newValue) &&
      (actionMeta.action === 'create-option' ||
        actionMeta.action === 'set-value' ||
        actionMeta.action === 'select-option')
    ) {
      this.handleClick();
    }

    if (
      ((Array.isArray(newValue) && newValue.length <= 0) || !newValue) &&
      (actionMeta.action === 'deselect-option' ||
        actionMeta.action === 'remove-value' ||
        actionMeta.action === 'pop-value' ||
        actionMeta.action === 'clear')
    ) {
      this.handleClick();
    }
  };

  getUserOptions = inputValue => {
    return new Promise(dispatch => {
      return dispatch(
        fetch(
          inputValue
            ? '/users?useFilter=1&name=' + inputValue
            : '/users?useFilter=1'
        )
          .then(response => response.json())
          .then(retrieved => {
            let userOptions = [];

            retrieved['hydra:member'].map(item => {
              userOptions.push({
                label: item['name'],
                value: item['@id']
              });
              return item;
            });

            return userOptions;
          })
          .catch(e => {
            // do nothing
          })
      );
    });
  };

  getOptionLabel = option => {
    // seems like nested object values due to renderSelectField
    if (
      option.label &&
      Array.isArray(option.label) &&
      option.label[0] &&
      option.label[0].label
    ) {
      return option.label[0].label;
    }
    if (option.label && option.label.label) {
      return option.label.label;
    }

    return option.label;
  };

  getOptionValue = option => {
    // seems like nested object values due to renderSelectField
    if (
      option.value &&
      Array.isArray(option.value) &&
      option.value[0] &&
      option.value[0].value
    ) {
      return option.value[0].value;
    }
    if (option.value && option.value.value) {
      return option.value.value;
    }

    return option.value;
  };

  pressFormSubmit = () => {
    if (typeof this.props.pressFormSubmit === 'function') {
      this.props.pressFormSubmit();
    }
  };

  pressFormReset = () => {
    this.props.reset();

    setTimeout(() => {
      this.getSelectFieldComponent('author') &&
        this.getSelectFieldComponent('author').select.select.clearValue();
    }, 1000);

    if (typeof this.props.pressFormReset === 'function') {
      this.props.pressFormReset();
    }
  };

  _selectField = [];

  attachSelectField = (node, index) => {
    this._selectField[index] = node;
  };

  getSelectFieldComponent = index => {
    return this._selectField[index];
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <button
          type="submit"
          id={'hidden-submit-filter-button'}
          className="pull-right"
          style={{
            border: 0,
            height: 0,
            margin: 0,
            padding: 0,
            overflow: 'hidden',
            width: 0
          }}
          ref={this.attachNode.bind(this)}
        >
          Submit
        </button>
        <button
          onClick={this.pressFormSubmit}
          type="submit"
          className="btn btn-primary pull-right"
          disabled={this.props.submitting || this.props.pristine}
        >
          {this.props.submitting
            ? 'Filtering...'
            : this.props.pristine
            ? 'Filtered'
            : 'Filter'}
        </button>
        {/*
        <Field
          component={this.renderField}
          name="search_text"
          type="text"
          placeholder="Search by title or author"
        />
        */}
        <Field
          component={this.renderField}
          name="name"
          type="text"
          placeholder="Search by title"
        />
        {this.props.displayAuthorFilter && (
          <Field
            component={this.renderSelectField}
            name="author"
            type="select"
            placeholder="Author"
            loadOptions={this.getUserOptions}
          />
        )}
        <Field
          component={this.renderHiddenField}
          name="category.id"
          type="hidden"
        />
        {this.props.displayTemplatesIveAuthoredFilter && (
          <Field
            component={this.renderCheckboxField}
            name="is_my_procedure"
            type="checkbox"
            // placeholder="Procedures I've authored"
            // placeholder="Templates I've authored"
            placeholder="Case Workflows I've authored"
          />
        )}
        {/*{this.props.displayResetSearch && */}
        <button
          onClick={this.pressFormReset}
          type="reset"
          className="btn btn-info mr-1"
          //disabled={this.props.submitting || this.props.pristine}
        >
          {this.props.submitting
            ? 'Resetting...'
            : this.props.pristine
            ? 'Reset Search'
            : 'Reset Search'}
        </button>
        {/*}*/}
      </form>
    );
  }
}

Form.defaultProps = {
  displayAuthorFilter: true,
  displayTemplatesIveAuthoredFilter: true,
  displayResetSearch: true
};

export default reduxForm({
  form: 'procedureFilter',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Form);
