import React from 'react';
import { Redirect } from 'react-router-dom';

import CurrentUserActiveSubscription from './account/CurrentUserActiveSubscription';
import Footer from './Footer';
import PrivateHeader from './PrivateHeader';
import { PrivateRoute } from './PrivateRoute';

const isAccountPage = props => {
  if (!!props.match && !!props.match.path && props.match.path === '/account') {
    return true;
  }

  return false;
};

export const PrivateLayout = ({ component: Component, ...rest }) => {
  return (
    <PrivateRoute
      {...rest}
      component={matchProps => (
        <div className="PrivateLayout">
          <PrivateHeader />

          <div className={'custom-container'}>
            <CurrentUserActiveSubscription
              perform={'is_active_and_valid'}
              loading={() => (
                <div className={'checking-subscription'}>
                  {/*<p>Checking Subscription...</p>*/}
                </div>
              )}
              yes={() => (
                <Component
                  isCurrentUserActiveAndValidSubscription={true}
                  {...matchProps}
                />
              )}
              no={() =>
                isAccountPage(matchProps) ? (
                  <Component
                    isCurrentUserActiveAndValidSubscription={false}
                    {...matchProps}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: '/account',
                      state: { from: matchProps.location }
                    }}
                  />
                )
              }
            />
          </div>
          <Footer />
        </div>
      )}
    />
  );
};
