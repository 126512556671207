import React from 'react';

const KeyboardShortcuts = () => {
  var sidebarContainer = document.getElementById(
    'keyboard-shortcut-legend-container-id'
  );

  function toggleSidebarLegend() {
    // let sidebarContainer = document.getElementById(
    //   'keyboard-shortcut-legend-container-id'
    // );
    let sidebarUl = document.getElementById('keyboard-shortcut-legend-ul-id');
    let legendParentContainer = document.getElementById('procedureSteps-id');
    if (!legendParentContainer) {
      return;
    }
    let pricingCard = document.getElementById(
      'purchasable-procedure-container-id'
    );
    let pricingAndLegendSidebarParentContainer = document.getElementById(
      'pricing-legend-sidebar-container'
    );

    legendParentContainer.classList.toggle('toggle-procedure-steps');
    pricingAndLegendSidebarParentContainer.classList.toggle('flex-basis-20');

    if (pricingCard) {
      sidebarContainer.classList.add('has-pricing-card');
      // legend was collapsed, will expand
      if (sidebarContainer.classList.contains('toggle-legend-container')) {
        pricingAndLegendSidebarParentContainer.classList.add('flex-basis-20');
        legendParentContainer.classList.remove('toggle-procedure-steps');
      }

      // Pricing card is collapsed, legend will collapse
      if (
        pricingCard.classList.contains('pricing-card-collapsed') &&
        !sidebarContainer.classList.contains('toggle-legend-container')
      ) {
        pricingAndLegendSidebarParentContainer.classList.remove(
          'flex-basis-20'
        );
        legendParentContainer.classList.add('toggle-procedure-steps');
      }

      // Pricing card is expanded, legend will collapse
      if (
        !pricingCard.classList.contains('pricing-card-collapsed') &&
        !sidebarContainer.classList.contains('toggle-legend-container')
      ) {
        pricingAndLegendSidebarParentContainer.classList.add('flex-basis-20');
        legendParentContainer.classList.remove('toggle-procedure-steps');
      }
    }

    sidebarContainer.classList.toggle('toggle-legend-container');
    sidebarUl.classList.toggle('toggle-legend-ul');

    if (
      sidebarContainer.classList.contains('toggle-legend-container') === true &&
      sidebarContainer.classList.contains('legend-fixed') === true
    ) {
      sidebarContainer.classList.add('legend-fixed-collapsed');
      document
        .getElementById('procedureSteps-id')
        .classList.add('tree-wrapper-width-toggle');
    } else {
      sidebarContainer.classList.remove('legend-fixed-collapsed');
      document
        .getElementById('procedureSteps-id')
        .classList.remove('tree-wrapper-width-toggle');
    }
  }

  window.addEventListener('scroll', function (ev) {
    let legendParentContainer = document.getElementById('procedureSteps-id');
    if (!legendParentContainer) {
      return;
    }

    if (window.scrollY >= 310) {
      if (sidebarContainer) {
        if (sidebarContainer.classList.contains('legend-fixed') === false) {
          sidebarContainer.classList.add('legend-fixed');
          document
            .getElementById('procedureSteps-id')
            .classList.add('tree-wrapper-width-fixed');
        }

        if (
          sidebarContainer.classList.contains('toggle-legend-container') ===
          true
        ) {
          sidebarContainer.classList.add('legend-fixed-collapsed');
        }

        if (
          sidebarContainer.classList.contains('toggle-legend-container') ===
            true &&
          sidebarContainer.classList.contains('legend-fixed') === true
        ) {
          document
            .getElementById('procedureSteps-id')
            .classList.add('tree-wrapper-width-toggle');
        }
      }
    } else {
      if (sidebarContainer) {
        if (sidebarContainer.classList.contains('legend-fixed') === true) {
          sidebarContainer.classList.remove('legend-fixed');
          sidebarContainer.classList.remove('legend-fixed-collapsed');
          document
            .getElementById('procedureSteps-id')
            .classList.remove('tree-wrapper-width-fixed');
        }

        if (
          sidebarContainer.classList.contains('toggle-legend-container') ===
          true
        ) {
          sidebarContainer.classList.remove('legend-fixed-collapsed');
          document
            .getElementById('procedureSteps-id')
            .classList.remove('tree-wrapper-width-fixed');
        }
      }
    }
  });

  return (
    <div
      onClick={toggleSidebarLegend}
      // add "toggle-legend-container" css class to close it by default
      className={'keyboard-shortcut-legend-container toggle-legend-container'}
      id={'keyboard-shortcut-legend-container-id'}
    >
      <i className="fa fa-keyboard-o" title={'Keyboard Shortcuts'}></i>
      <ul
        // add "toggle-legend-ul" css class to close it by default
        className={'keyboard-shortcut-legend-ul toggle-legend-ul'}
        id={'keyboard-shortcut-legend-ul-id'}
      >
        <li>
          <h5>Navigation</h5>
        </li>
        <li>
          <span>Up Arrow</span>Move to step above.
        </li>
        <li>
          <span>Down Arrow</span>Move to step below.
        </li>
        <li>
          <h5>Create</h5>
        </li>
        <li>
          <span>Enter</span>Create new step.
        </li>
        <li>
          <span>Tab</span>Turn step into sub-step.
        </li>

        <li>
          <span>Shift-Tab</span>Move step back to previous level.
        </li>
        <li>
          <span>Escape</span>Delete step with no value
        </li>
        <li>
          <h5>Move Steps Around</h5>
        </li>
        {/*
        <li>
          <span>Shift-Left Arrow : </span>Move step back to previous level.
        </li>
        <li>
          <span>Shift-Right Arrow : </span>Turn step into sub-step
        </li>
        */}
        <li>
          <span>Shift + Up Arrow</span>Move step up.
        </li>
        <li>
          <span>Shift + Down Arrow</span>Move step down.
        </li>
        <li>
          <h5>Icons</h5>
        </li>
        <li>
          <span className="icon-container">
            <i className="fa fa-info-circle float-none"></i>
          </span>{' '}
          Click to add notes to your step that will show up when using this
          procedure.
        </li>
        <li className="mt-1">
          <span className="icon-container">
            <i className="fa fa-paperclip float-none"></i>
          </span>{' '}
          Click to add files to your step that will show up when using this
          procedure.
        </li>
      </ul>
    </div>
  );
};

export default KeyboardShortcuts;
