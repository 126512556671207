import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { reset, resettingReset, retrieve } from '../../actions/resetting/reset';
import { isLoggedIn } from '../../utils/auth';
import ResettingResetForm from './ResettingResetForm';

class ResettingReset extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    retrieveLoading: PropTypes.bool.isRequired,
    retrieveError: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    resetConfirmed: PropTypes.object,
    retrieve: PropTypes.func.isRequired,
    resettingReset: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.token));
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    if (this.props.resetConfirmed || isLoggedIn())
      return <Redirect to="/procedures/" />;

    const item = this.props.resetConfirmed
      ? this.props.resetConfirmed
      : this.props.retrieved;

    return (
      <Card className={'mt-5'}>
        <Card.Body>
          <Card.Title>Enter New Password</Card.Title>

          {this.props.loading && (
            <div className="alert alert-info" role="status">
              Loading...
            </div>
          )}
          {this.props.error && (
            <div className="alert alert-danger" role="alert">
              <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
              {this.props.error}
            </div>
          )}
          {this.props.retrieveLoading && (
            <div className="alert alert-info" role="status">
              Loading...
            </div>
          )}
          {this.props.retrieveError && (
            <div className="alert alert-danger" role="alert">
              <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
              {this.props.retrieveError}
            </div>
          )}

          {item && (
            <ResettingResetForm
              onSubmit={values =>
                this.props.resettingReset(item, {
                  ...values,
                  confirmationToken: this.props.match.params.token
                })
              }
              values={item}
            />
          )}
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  const {
    resetConfirmed,
    error,
    loading,
    retrieved,
    retrieveError,
    retrieveLoading
  } = state.resetting.resettingReset;
  return {
    resetConfirmed,
    error,
    loading,
    retrieved,
    retrieveError,
    retrieveLoading
  };
};

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  resettingReset: (item, values) => dispatch(resettingReset(item, values)),
  reset: () => dispatch(reset())
});

export default connect(mapStateToProps, mapDispatchToProps)(ResettingReset);
