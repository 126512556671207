import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { list, reset } from '../../actions/cart/list';
import { del as deleteCartItem } from '../../actions/cartitem/delete';
import imageUpload from '../../assets/img/temp-upload-image.png';
import { emptyCartTooltipOverlay } from '../../utils/ecommerce';
import SecureImage from '../SecureImage';

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    account: PropTypes.object,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleteCartItem: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page),
      this.props.account.show.retrieved && this.props.account.show.retrieved
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page),
        this.props.account.show.retrieved
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  handleDeleteCartItem = async item => {
    await this.props.deleteCartItem(item);

    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page),
      this.props.account.show.retrieved
    );
  };

  getPriceOrRenewalPrice = item => {
    if (!item.purchasableProcedure.latestLastPurchasedDate) {
      return item.purchasableProcedure.price;
    }

    return item.purchasableProcedure.renewalDiscountPrice;
  };

  getSubtotal = () => {
    if (
      this.props.retrieved &&
      this.props.retrieved['hydra:member'] &&
      this.props.retrieved['hydra:member'][0].cartItems.length > 0
    ) {
      const subtotal = this.props.retrieved['hydra:member'][0].cartItems.reduce(
        (accumulator, item) => {
          return accumulator + parseFloat(this.getPriceOrRenewalPrice(item));
        },
        0
      );

      let dollarUSLocale = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });

      return dollarUSLocale.format(subtotal);
    }

    return '$0.00';
  };

  render() {
    return (
      <div className={'mt-3 page-list page-cart-list'}>
        <div className={'page-list col-md-9'}>
          {this.props.error && (
            <div className="alert alert-danger">{this.props.error}</div>
          )}
          {this.props.retrieved &&
          this.props.retrieved['hydra:member'] &&
          this.props.retrieved['hydra:member'][0].cartItems.length > 0 ? (
            <div className="page-cart-table-wrapper">
              <table className="table table-responsive table-striped table-hover">
                <thead>
                  <tr>
                    {/*<th>Template</th>*/}
                    <th>Case Workflow</th>
                    <th>Price</th>
                    <th colSpan={2}></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.retrieved &&
                    this.props.retrieved['hydra:member'][0].cartItems.map(
                      item => {
                        return (
                          <tr>
                            <th
                              scope="row"
                              key={item['@id']}
                              draggable={false}
                              onDragStart={() => {}}
                              id={item['@id']}
                            >
                              {item.purchasableProcedure &&
                                item.purchasableProcedure
                                  .purchasableProcedureFile && (
                                  <SecureImage
                                    file={
                                      item.purchasableProcedure
                                        .purchasableProcedureFile
                                    }
                                    zoom={true}
                                    alt={'Preview Image'}
                                    src={imageUpload}
                                    id={`purchasable-procedure-file-${item.purchasableProcedure.purchasableProcedureFile.token}`}
                                    attributes={{
                                      width: 30,
                                      // height: 30,
                                      style: { cursor: 'pointer' }
                                    }}
                                    border={true}
                                  />
                                )}

                              {item.purchasableProcedure['procedure']['name']}
                            </th>
                            <td className={'text-left'}>
                              <span>${this.getPriceOrRenewalPrice(item)}</span>
                            </td>
                            <td className={'text-right'}>
                              <button
                                onClick={() => this.handleDeleteCartItem(item)}
                              >
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={'no-results'}>
              <p>Your cart is empty.</p>
            </div>
          )}

          {/*{this.pagination()}*/}
        </div>
        <div className={'checkout-box justify-content-center col-md-3'}>
          <div
            className={'d-flex flex-column align-content-center text-center'}
          >
            <h3>Subtotal</h3>
            <p className={'price'}>{this.getSubtotal()}</p>
            {this.props.retrieved &&
            this.props.retrieved['hydra:member'] &&
            this.props.retrieved['hydra:member'][0].cartItems.length > 0 ? (
              <Link
                to={'/checkout'}
                className={'d-flex flex-column text-decoration-none'}
              >
                <button className={'btn-action mt-3'}>Checkout</button>
              </Link>
            ) : (
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={emptyCartTooltipOverlay}
              >
                <button className={'btn-action mt-3'}>Checkout</button>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
    );
  }

  pagination() {
    const view = this.props.retrieved && this.props.retrieved['hydra:view'];
    if (!view) return;

    const {
      'hydra:first': first,
      'hydra:previous': previous,
      'hydra:next': next,
      'hydra:last': last
    } = view;

    return (
      <nav aria-label="Page navigation">
        <Link
          to="."
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&lArr;</span> First
        </Link>
        <Link
          to={
            !previous || previous === first ? '.' : encodeURIComponent(previous)
          }
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&larr;</span> Previous
        </Link>
        <Link
          to={next ? encodeURIComponent(next) : '#'}
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Next <span aria-hidden="true">&rarr;</span>
        </Link>
        <Link
          to={last ? encodeURIComponent(last) : '#'}
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Last <span aria-hidden="true">&rArr;</span>
        </Link>
      </nav>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource, deletedItem } =
    state.cart.list;
  return {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
    account: state.account
  };
};

const mapDispatchToProps = dispatch => ({
  list: (page, account) => dispatch(list(page, account)),
  reset: eventSource => dispatch(reset(eventSource)),
  deleteCartItem: item => dispatch(deleteCartItem(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
