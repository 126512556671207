import React from 'react';

import { Create, List, Show, Update } from '../components/organization/';
//import { Route } from 'react-router-dom';
import { PrivateLayout as Route } from '../components/PrivateLayout';

export default [
  <Route path="/organizations/create" component={Create} exact key="create" />,
  <Route
    path="/organizations/edit/:id"
    component={Update}
    exact
    key="update"
  />,
  <Route path="/organizations/show/:id" component={Show} exact key="show" />,
  <Route path="/organizations/" component={List} exact strict key="list" />,
  <Route path="/organizations/:page" component={List} exact strict key="page" />
];
