import React from 'react';

import { Invite, InviteConfirm } from '../components/invite/';
//import { Route } from 'react-router-dom';
import { PrivateLayout as Route } from '../components/PrivateLayout';
import { PublicLayout as PublicRoute } from '../components/PublicLayout';

export default [
  <Route path="/invite" component={Invite} exact key="invite" />,
  <PublicRoute
    path="/invite/confirm/:token"
    component={InviteConfirm}
    exact
    key="inviteConfirm"
  />
];
