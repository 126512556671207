// https://simonsmith.io/reusing-layouts-in-react-router-4
import React from 'react';
import { Route } from 'react-router-dom';

import Footer from './Footer';
import PublicHeader from './PublicHeader';

export const PublicLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <div className="PublicLayout">
          <PublicHeader />

          <div className={'custom-container'}>
            <Component {...matchProps} />
          </div>
          <Footer />
        </div>
      )}
    />
  );
};
