import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { list, reset } from '../../actions/checkout/checkout';
import {
  addPaymentMethod,
  detachPaymentMethodFromCustomer,
  listPayments
} from '../../actions/checkout/payment';
import { submitOrder } from '../../actions/order/create';
import imageUpload from '../../assets/img/temp-upload-image.png';
import { formatTotalToDollars } from '../../utils/ecommerce';
import SecureImage from '../SecureImage';
import CardPaymentForm from './CardPaymentForm';

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    listCart: PropTypes.func.isRequired
  };

  state = {
    paymentModal: false,
    paymentsLoading: null,
    paymentsError: null,
    userPaymentMethods: [],
    selectedPaymentMethod: null,
    orderSubmitLoading: null,
    orderSubmitSuccess: null,
    orderSubmitError: null,
    orderError: null
  };

  setShow = value => {
    this.setState({
      paymentModal: { value }
    });
  };

  handleClose = () => this.setState({ paymentModal: false });

  handleShow = () => this.setState({ paymentModal: true });

  handleSubmit = () => {
    this.setState({
      orderSubmitLoading: true
    });

    this.props
      .submitOrder(this.state.selectedPaymentMethod)
      .then(response => {
        this.props.history.push(
          `/orders/show/${encodeURIComponent(response.created.order.id)}`
        );
        return this.setState({
          orderSubmitLoading: true
        });
      })
      .catch(e => {
        this.setState({
          orderSubmitError: true,
          orderError: e.message
        });
      });
  };

  componentDidMount() {
    this.props.listCart(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
    this.listPayments();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  listPayments = () => {
    this.setState({ paymentsLoading: true });
    this.props
      .listPayments()
      .then(retrieved =>
        this.setState({
          userPaymentMethods: retrieved,
          paymentsLoading: false
        })
      )
      .then(() => this.checkIfDefaultPaymentExists())
      .catch(e =>
        this.setState({
          paymentsSuccess: null,
          paymentsError: e.message,
          paymentsLoading: null
        })
      );
  };

  checkIfDefaultPaymentExists = () => {
    const paymentsList = this.state.userPaymentMethods;
    const foundDefault = paymentsList.find(
      payment => payment.default && payment.default === true
    );
    if (foundDefault) {
      this.setState({ selectedPaymentMethod: foundDefault.id });
    }
    return;
  };

  del = item => {
    if (window.confirm('Are you sure you want to delete this card?')) {
      this.props
        .detachPaymentMethodFromCustomer(item)
        .then(() => this.listPayments());
    }
  };

  selectPaymentMethod = item => {
    this.setState({
      selectedPaymentMethod: item
    });
  };

  render() {
    return (
      <div id="lista-checkout">
        <Row>
          <Col
            className="mt-5"
            xs={{ span: 11, offset: 0 }}
            sm={{ span: 10, offset: 1 }}
            md={{ span: 10, offset: 1 }}
            lg={{
              span: 6,
              offset: 1
            }}
          >
            <div id="payments">
              <div className="d-flex mb-4">
                <div className="list-icon payment-list-icon"></div>
                <h2 style={{ alignSelf: 'flex-end' }}>Payment Methods</h2>
              </div>

              {this.state.paymentsLoading && (
                <div className="alert alert-info">Loading...</div>
              )}
              {this.state.paymentsError && (
                <div className="alert alert-danger">
                  {this.state.paymentsError}
                </div>
              )}
              {this.state.userPaymentMethods &&
                this.state.userPaymentMethods.map((payment, index) => {
                  return (
                    <>
                      <label
                        className={'select-payment mb-3'}
                        key={`payment-method-${index + 1}`}
                      >
                        <div
                          className={
                            'payment-profile clearfix bg-light border-dark rounded shadow-sm p-3 '
                          }
                          id={`payment-${index}`}
                        >
                          <input
                            className={'pull-left mt-3'}
                            type="radio"
                            checked={
                              payment.id === this.state.selectedPaymentMethod
                            }
                            name="payment-method"
                            onChange={() => {
                              this.selectPaymentMethod(payment.id);
                            }}
                          ></input>
                          <div className={'pull-right'}>
                            <button
                              className={'btn btn-link btn-lg'}
                              onClick={() => this.del(payment.id)}
                            >
                              X
                            </button>
                          </div>

                          {payment.card_brand && (
                            <span
                              className={
                                'payment-icon pull-left ml-4 mr-4 fa fa-3x fa-cc-' +
                                payment.card_brand
                              }
                            >
                              {''}
                            </span>
                          )}
                          <div className={'payment-info card-title'}>
                            <span>{payment.card_brand} </span>
                            <span className={'text-nowrap'}>
                              **** {payment.last4}
                            </span>
                          </div>

                          {payment.card_exp_month && payment.card_exp_year && (
                            <small className={'payment-expiration muted'}>
                              Expires {payment.card_exp_month}/
                              {payment.card_exp_year}
                            </small>
                          )}
                        </div>
                      </label>
                    </>
                  );
                })}
              <button
                className="btn btn-cta create-btn mt-2"
                onClick={() => this.handleShow(true)}
              >
                <span className="fa fa-plus" aria-hidden="true" />
                <span>&nbsp;&nbsp;&nbsp;Add New Card</span>
              </button>
              <Modal
                id="payment-modal"
                show={this.state.paymentModal}
                onHide={this.handleClose}
              >
                <Modal.Header className="payment-header" closeButton>
                  <Modal.Title className="payment-title">
                    Add New Card
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="modal-information">
                    <p>
                      Enter new card details below to <b>add</b> new payment
                      method
                    </p>
                  </div>
                  <CardPaymentForm
                    listPayments={this.listPayments}
                    attachPaymentMethod={this.props.attachPaymentMethod}
                    handleClose={this.handleClose}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </Col>
          <Col
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{
              span: 5,
              offset: 0
            }}
          >
            <div
              className="card bg-light px-5 pr-5"
              style={{ width: '100%', minHeight: '90vh' }}
            >
              {this.props.retrieved &&
                this.props.retrieved.cartItems.map((item, index) => (
                  <div
                    className={'d-flex my-5 px-4 pt-5'}
                    key={`cart-item-${index}`}
                    style={{ justifyContent: 'center' }}
                  >
                    <SecureImage
                      file={item.procedureFile}
                      src={imageUpload}
                      zoom={false}
                      alt={'Upload Preview Image'}
                      id={'purchasable-procedure-file' + item.procedureFile.id}
                      attributes={{
                        width: 75,
                        style: { cursor: 'pointer' }
                      }}
                    />
                    <div className="ml-3 d-flex">
                      <h6
                        className="mb-0 font-weight-bold"
                        style={{ alignSelf: 'center' }}
                      >
                        {item.name}
                      </h6>
                    </div>
                    <div className="ml-auto d-flex">
                      <h6 className="mb-0" style={{ alignSelf: 'center' }}>
                        $
                        {item.renewing === true
                          ? item.renewalDiscountPrice
                          : item.price}
                      </h6>
                    </div>
                  </div>
                ))}
              <hr className="mx-2 mb-3" />
              {this.props.retrieved && (
                <>
                  <div className="d-flex my-2 px-4">
                    <p>Subtotal</p>
                    <h6 className="ml-auto">
                      {formatTotalToDollars(
                        this.props.retrieved.cartTotals.subTotal
                      )}
                    </h6>
                  </div>
                  <hr className="mx-2 my-2" />
                  <div className="d-flex mt-3  mb-5 px-4">
                    <h5 className="font-weight-bold">Total</h5>
                    <h4 className="total font-weight-bold ml-auto">
                      {formatTotalToDollars(
                        this.props.retrieved.cartTotals.grandTotal
                      )}
                    </h4>
                  </div>
                  <div className={'d-flex'} style={{ justifyContent: 'end' }}>
                    {!this.state.selectedPaymentMethod ? (
                      <OverlayTrigger
                        placement={'bottom'}
                        overlay={
                          <Tooltip id={'tooltip-description'}>
                            Select a Payment Method
                          </Tooltip>
                        }
                      >
                        <button
                          className={'btn btn-danger mb-5'}
                          variant="secondary"
                          style={{ width: '50%' }}
                        >
                          Place Order
                        </button>
                      </OverlayTrigger>
                    ) : (
                      <button
                        className={'btn btn-danger mb-5'}
                        variant="secondary"
                        onClick={this.handleSubmit}
                        style={{ width: '50%' }}
                      >
                        Place Order
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource, deletedItem } =
    state.checkout.list;
  // const { retrieved: retrievedOrder }= state.checkout.submitOrder;
  return { retrieved, loading, error, eventSource, deletedItem };
};

const mapDispatchToProps = dispatch => ({
  attachPaymentMethod: paymentMethod =>
    dispatch(addPaymentMethod(paymentMethod)),
  detachPaymentMethodFromCustomer: paymentMethod =>
    dispatch(detachPaymentMethodFromCustomer(paymentMethod)),
  submitOrder: paymentMethod => dispatch(submitOrder(paymentMethod)),
  listPayments: () => dispatch(listPayments()),
  listCart: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
