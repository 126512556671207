import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
/*
import Modal from 'react-bootstrap/Modal';

//import ProcedureShare from './assets/img/lista-procedure-share.png';
import PlayButton from './assets/img/lista-play-button.png';
import VideoCreateProcedure from './assets/video/lista-create-procedure.mp4';
import VideoUseProcedure from './assets/video/lista-use-procedure.mp4';
import Footer from './components/Footer';
import PrivateHeader from './components/PrivateHeader';
import PublicHeader from './components/PublicHeader';
//import { Link } from 'react-router-dom';
import { VersionLink as Link } from './components/VersionLink';
import { isLoggedIn } from './utils/auth';
*/

const Home = () => {
  return <Redirect to="/login" />;
};
/*
const Home = () => {
  const [showCreateProcedure, setShowCreateProcedure] = useState(false);
  const handleShowCreateProcedure = () => setShowCreateProcedure(true);
  const handleCloseCreateProcedure = () => {
    setTimeout(() => {
      setShowCreateProcedure(false);
    }, 2000);
  };

  const [showUseProcedure, setShowUseProcedure] = useState(false);
  const handleShowUseProcedure = () => setShowUseProcedure(true);
  const handleCloseUseProcedure = () => {
    setTimeout(() => {
      setShowUseProcedure(false);
    }, 2000);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="homepage" id="lista-homepage">
      <div className="PublicLayout">
        {isLoggedIn() ? <PrivateHeader /> : <PublicHeader />}
        <div className="covid-notice-container">
          <button
            variant="primary"
            onClick={handleShow}
            className={'covid-notice'}
          >
            COVID-19 NOTICE
          </button>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>COVID-19 NOTICE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              We know that adapting to remote work can be difficult. Having
              Lista available for case roadmaps and case tracking is now more
              valuable than ever for increasing efficiencies and reducing errors
              while working remotely. Here at Lista, our team is busy improving
              our own procedures. Working together on this core project while
              we’re remote has been a perfect way to stay connected around a
              common goal of establishing procedures that encompass best
              practices for now and in the future!
            </p>
            <p>The Lista team hopes you stay safe and well.</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={'btn-primary'}
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <div className="jumbotron-fluid" id="hero-banner">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-5">
                <h1>Bring efficiency to your business processes</h1>
              </div>
              <div class="col-md-5">
                <h2 className="white-text">
                  Document and use your procedures with Lista
                </h2>
                <Link
                  to="/register"
                  className={'btn btn-lg btn-primary yellow-button'}
                >
                  Get started with Lista
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="jumbotron-fluid" id="row6">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-6">
                <h2 className="white-text">
                  Need a better way to manage and implement your standard
                  procedures?
                </h2>
                <ul className="yellow-text">
                  <li>Are they stuck in someone's memory?</li>
                  <li>Are they lost in hard-to-find folders?</li>
                  <li>
                    Do you wish you or your co-workers could easily find and use
                    them?
                  </li>
                  <li>Do you want to track progress?</li>
                  <li>
                    Are you ready to increase efficiency and productivity?
                  </li>
                </ul>
              </div>
              <div className="col-md-6 repeatable-processes-wrapper">
                <h2 className="yellow-text">You will love Lista!</h2>
                <p className="white-text">
                  Quickly create and use procedures &mdash; road maps that make
                  the way forward clear.
                </p>
                <ul id="repeatable-processes">
                  <lh>Repeatable Processes</lh>
                  <li>
                    <span>Prepare</span> your procedures with our drag-n-drop
                    interface
                  </li>
                  <li>
                    <span>Invite</span> team members to the system
                  </li>
                  <li>
                    <span>Use</span> your procedures each time they're needed
                  </li>
                  <li>
                    <span>Track</span> progress each time they're used
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="jumbotron-fluid" id="row2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-9">
                <h2 class="white-text">
                  Enter Lista, a software platform for easily organizing, using,
                  and tracking your standard operating procedures
                </h2>
                <div class="lista-definition">
                  <p class="yellow-text">
                    <strong>Lista</strong> /'lista/{' '}
                    <em>(Spanish, adjective, feminine singular)</em>
                  </p>
                  <ol class="yellow-text">
                    <li>clever, smart</li>
                    <li>ready</li>
                    <li>alert</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="jumbotron-fluid" id="row3">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-sm-5">
                <h2>Easy to set up</h2>
                <p>
                  Simply document your step-by-step procedures with our easy and
                  elegant editor. Continually refine and improve your process
                  templates over time.
                </p>
              </div>
              <div className="col-sm-5 text-center">
                <button
                  type="button"
                  className="play-button"
                  data-toggle="modal"
                  data-target="#CreateProcedureModal"
                  onClick={handleShowCreateProcedure}
                >
                  <img src={PlayButton} alt="Play" />
                </button>
                <Modal
                  show={showCreateProcedure}
                  onHide={handleCloseCreateProcedure}
                  centered
                  size="xl"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Creating a procedure in Lista</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <video
                      width="100%"
                      height="auto"
                      controls
                      autoplay="autoplay"
                      onEnded={handleCloseCreateProcedure}
                    >
                      <source src={VideoCreateProcedure} type="video/mp4" />
                    </video>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        <div className="jumbotron-fluid" id="row4">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-sm-5 order-sm-last">
                <h2>Easy to use</h2>
                <p>
                  Quickly find and use standard procedures. Label procedures
                  using tags for easy search and organization. Monitor and track
                  progress.
                </p>
              </div>
              <div className="col-sm-5 text-center order-sm-first">
                <button
                  type="button"
                  className="play-button"
                  data-toggle="modal"
                  data-target="#UseProcedureModal"
                  onClick={handleShowUseProcedure}
                >
                  <img src={PlayButton} alt="Play" />
                </button>
                <Modal
                  show={showUseProcedure}
                  onHide={handleCloseUseProcedure}
                  centered
                  size="xl"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Using a procedure in Lista</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <video
                      width="100%"
                      height="auto"
                      controls
                      autoplay="autoplay"
                      onEnded={handleCloseUseProcedure}
                    >
                      <source src={VideoUseProcedure} type="video/mp4" />
                    </video>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        <div className="jumbotron-fluid" id="row5">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-5">
                <h2 className="white-text">
                  Increase the value of your company
                </h2>
                <ul className="yellow-text">
                  <li>Record your company's institutional knowledge</li>
                  <li>Save time and increase efficiency</li>
                  <li>Reduce errors</li>
                  <li>Continuously improve your processes</li>
                  <li>Increase client satisfaction</li>
                  <li>Grow your business</li>
                </ul>
                <Link
                  to="/register"
                  className={'btn btn-lg btn-primary yellow-button'}
                >
                  Get started with Lista
                </Link>
              </div>
              <div className="col-md-5 text-center">
                <h2>Ready to transform your procedure management?</h2>
                <Link to="/register" className={'btn btn-lg btn-primary'}>
                  Get started with Lista
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
*/

export default Home;
