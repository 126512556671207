import { fetch } from '../dataAccess';

const { Plugin } = require('@uppy/core');
const { h } = require('preact');

class FileActions extends Plugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.type = 'acquirer';
    this.id = opts.id || 'FileActions';
    this.title = opts.title || 'File Actions';
  }

  // @see node_modules/@uppy/dashboard/src/components/FileItem/FileInfo/index.js renderAcquirerIcon()
  icon = () => {
    return h('span', {
      class: 'uppy-action-container',
      children: [
        h('span', {
          class: 'uppy-download-button-icon-wrapper',
          children: [
            h('span', {
              class:
                'uppy-download-button-icon uppy-download-button-icon_' +
                this.uppy.opts.id,
              title: 'Download File',
              children: [
                h('span', {
                  class: 'fa fa-download',
                  children: [
                    h('span', {
                      class: 'sr-only',
                      children: ['Download File']
                    })
                  ]
                })
              ]
            })
          ]
        }),
        h('span', {
          class: 'uppy-remove-button-icon-wrapper',
          children: [
            h('span', {
              class:
                'uppy-remove-button-icon uppy-remove-button-icon_' +
                this.uppy.opts.id,
              title: 'Remove File',
              children: [
                h('span', {
                  class: 'fa fa-trash',
                  children: [
                    h('span', {
                      class: 'sr-only',
                      children: ['Remove File']
                    })
                  ]
                })
              ]
            })
          ]
        })
      ]
    });
  };

  onMount() {
    setTimeout(() => {
      this.applyJs();
    }, 1000);
  }

  afterUpdate() {
    setTimeout(() => {
      this.applyJs();
    }, 1000);
  }

  applyJs = () => {
    let downloadElems = document.querySelectorAll(
      '.uppy-download-button-icon_' +
        this.uppy.opts.id +
        ':not(.uppy-download-button-icon-js)'
    );

    if (downloadElems.length > 0) {
      for (let i = 0; i < downloadElems.length; ++i) {
        this.applyJsToDownloadElement(downloadElems[i]);
      }
    }

    let removeElems = document.querySelectorAll(
      '.uppy-remove-button-icon_' +
        this.uppy.opts.id +
        ':not(.uppy-remove-button-icon-js)'
    );

    if (removeElems.length > 0) {
      for (let i = 0; i < removeElems.length; ++i) {
        this.applyJsToRemoveElement(removeElems[i]);
      }
    }
  };

  applyJsToDownloadElement = elem => {
    //elem.onclick = event => this.handleDownloadClick(event);
    elem.parentNode.onclick = event => this.handleDownloadClick(event);
    elem.parentNode.classList.remove('uppy-download-button-icon-wrapper');
    elem.parentNode.classList.remove('uppy-download-button-icon-js');
    elem.parentNode.className +=
      ' uppy-download-button-icon-wrapper uppy-download-button-icon-js';
    elem.classList.remove('uppy-download-button-icon-js');
    elem.className += ' uppy-download-button-icon-js';
  };

  applyJsToRemoveElement = elem => {
    //elem.onclick = event => this.handleRemoveClick(event);
    elem.parentNode.onclick = event => this.handleRemoveClick(event);
    elem.parentNode.classList.remove('uppy-remove-button-icon-wrapper');
    elem.parentNode.classList.remove('uppy-remove-button-icon-js');
    elem.parentNode.className +=
      ' uppy-remove-button-icon-wrapper uppy-remove-button-icon-js';
    elem.classList.remove('uppy-remove-button-icon-js');
    elem.className += ' uppy-remove-button-icon-js';
  };

  applyJsToFileItem = fileId => {
    let fileItem = document.getElementById('uppy_' + fileId);
    if (fileItem) {
      let downloadElem = fileItem.querySelector('.uppy-download-button-icon');
      if (downloadElem) {
        this.applyJsToDownloadElement(downloadElem);
      }
      let removeElem = fileItem.querySelector('.uppy-remove-button-icon');
      if (removeElem) {
        this.applyJsToRemoveElement(removeElem);
      }
    }
  };

  handleDownloadClick = event => {
    let elem = event.target;
    // fa-trash / uppy-remove-button-icon / uppy-remove-button-icon-wrapper / uppy-action-container / span / uppy-DashboardItem-sourceIcon / uppy-DashboardItem-status / uppy-DashboardItem-fileInfo / uppy-DashboardItem-fileInfoAndButtons / uppy-DashboardItem
    let fileItem =
      elem.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode.parentNode;

    let fileId = fileItem.id;
    fileId = fileId.replace('uppy_', '');
    let file = this.uppy.getFile(fileId);

    if (file) {
      let url = file.uploadURL || file.meta.location;
      if (url) {
        // @see SecureFile.js
        let fileHdrs = new Headers();
        fileHdrs.set('Accept', file.filetype);
        fileHdrs.set('Content-Type', file.filetype);

        fetch(url + '/get', {
          headers: fileHdrs
        })
          .then(response => response.blob())
          .then(blob => {
            let link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      }
    }
  };

  handleRemoveClick = event => {
    let elem = event.target;
    // fa-trash / uppy-remove-button-icon / uppy-remove-button-icon-wrapper / uppy-action-container / span / uppy-DashboardItem-sourceIcon / uppy-DashboardItem-status / uppy-DashboardItem-fileInfo / uppy-DashboardItem-fileInfoAndButtons / uppy-DashboardItem
    let fileItem =
      elem.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode.parentNode;

    let fileId = fileItem.id;
    fileId = fileId.replace('uppy_', '');
    let file = this.uppy.getFile(fileId);

    if (file) {
      let url = file.uploadURL || file.meta.location;
      if (url) {
        if (!window.confirm('Are you sure you want to delete this file?')) {
          return;
        }
        fetch(url, { method: 'DELETE' })
          .then(response => {
            this.uppy.removeFile(fileId);
          })
          .catch(e => {
            this.uppy.removeFile(fileId);
          });
      }
    }
  };

  render(state) {
    return h('div', {
      class: 'uppy-file-actions',
      children: ['']
    });
  }
}

//module.exports = FileActions;

export default FileActions;
