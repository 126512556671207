import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { del } from '../../actions/tag/delete';
import { reset, retrieve, update } from '../../actions/tag/update';
import { VersionLink as Link } from '../VersionLink';
import Form from './Form';

class Update extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    retrieveLoading: PropTypes.bool.isRequired,
    retrieveError: PropTypes.string,
    updateLoading: PropTypes.bool.isRequired,
    updateError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleteError: PropTypes.string,
    updated: PropTypes.object,
    deleted: PropTypes.object,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  render() {
    if (this.props.deleted) return <Redirect to=".." />;

    if (this.props.updated) return <Redirect to=".." />;

    const item = this.props.updated ? this.props.updated : this.props.retrieved;

    return (
      <div className="edit-tag-wrapper">
        <div className={'page-actions-wrapper'}>
          <div className={'page-actions'}>
            <button onClick={this.del} className="btn btn-primary btn-yellow">
              Delete
            </button>
            <Link to=".." className="btn btn-primary btn-yellow">
              Back to list
            </Link>
          </div>
        </div>
        <div
          className={
            'edit-tag-container col-lg-4 col-md-6 col-sm-8 offset-lg-4 offset-md-3 offset-sm-2'
          }
        >
          {/*
        <h1>Edit {item && item['@id']}</h1>
        */}
          <h1 className={'text-center'}>Edit Tag</h1>

          {this.props.created && (
            <div className="alert alert-success" role="status">
              {this.props.created['@id']} created.
            </div>
          )}
          {this.props.updated && (
            <div className="alert alert-success" role="status">
              {this.props.updated['@id']} updated.
            </div>
          )}
          {(this.props.retrieveLoading ||
            this.props.updateLoading ||
            this.props.deleteLoading) && (
            <div className="alert alert-info" role="status">
              Loading...
            </div>
          )}
          {this.props.retrieveError && (
            <div className="alert alert-danger" role="alert">
              <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
              {this.props.retrieveError}
            </div>
          )}
          {this.props.updateError && (
            <div className="alert alert-danger" role="alert">
              <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
              {this.props.updateError}
            </div>
          )}
          {this.props.deleteError && (
            <div className="alert alert-danger" role="alert">
              <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
              {this.props.deleteError}
            </div>
          )}

          {item && (
            <Form
              onSubmit={values => this.props.update(item, values)}
              initialValues={item}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.tag.update.retrieved,
  retrieveError: state.tag.update.retrieveError,
  retrieveLoading: state.tag.update.retrieveLoading,
  updateError: state.tag.update.updateError,
  updateLoading: state.tag.update.updateLoading,
  deleteError: state.tag.del.error,
  deleteLoading: state.tag.del.loading,
  eventSource: state.tag.update.eventSource,
  created: state.tag.create.created,
  deleted: state.tag.del.deleted,
  updated: state.tag.update.updated
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(Update);
