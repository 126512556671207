export function handleKeyDown(event, data, component, fieldName, addElementId) {
  if (
    data.input &&
    data.input.name &&
    data.input.name.indexOf(fieldName) !== -1
  ) {
    let hierarchyLevelLimit = 20;
    let overrideBrowserKeyboardNavigation = true;
    let allowEnterKey = true;
    let allowShiftArrowLeftKey = false;
    let allowShiftArrowRightKey = false;

    if (
      typeof component.props.allowEnterKey !== 'undefined' &&
      !component.props.allowEnterKey
    ) {
      allowEnterKey = false;
    }

    if (allowEnterKey && event.target.tagName !== 'INPUT') {
      allowEnterKey = false;
    }

    if (
      allowEnterKey &&
      event.target.value !== '' &&
      (event.keyCode === 13 || event.key === 'Enter')
    ) {
      // Enter key (on an input that has a value)
      event.preventDefault();
      // add new item
      let currentId = '';
      let currentParentId = '';
      let currentParentIndex = 0;
      let currentLevel = 0;

      if (
        event.target &&
        event.target.parentNode &&
        event.target.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode.parentNode
      ) {
        // input / form-group / procedure-step / tree-item-field / draggable
        let element = event.target.parentNode.parentNode.parentNode.parentNode;
        currentId = element.dataset.itemId;
        currentLevel = parseInt(element.dataset.level);
      }

      if (!currentId) {
        Object.keys(component.getTreeComponentTree().items).forEach(key => {
          let item = component.getTreeComponentTree().items[key];
          if (
            data.originalData &&
            item.data.originalId === data.originalData['@id']
          ) {
            currentId = item.id;
          }
        });
      }

      if (currentId) {
        Object.keys(component.getTreeComponentTree().items).forEach(key => {
          let item = component.getTreeComponentTree().items[key];
          if (item.children.indexOf(currentId) !== -1) {
            currentParentId = item.id;
            currentParentIndex = item.children.indexOf(currentId);
          }
        });
      }

      // This is necessary for step creation to avoid data loss in other steps. DO NOT REMOVE.
      component.getButtonComponent().click(); // submit now

      if (
        event.shiftKey &&
        currentId &&
        currentLevel < hierarchyLevelLimit - 1
      ) {
        // Shift + Enter
        // add a new sub-item
        component.addTreeItemForParentAtIndex(currentId, 0);
      } else if (currentParentId) {
        // Enter
        // add a new item after the current one
        component.addTreeItemForParentAtIndex(
          currentParentId,
          currentParentIndex + 1
        );
      } else {
        // Enter
        // add a new item
        component.addTreeItem();
      }
    }

    if (
      (allowShiftArrowLeftKey &&
        event.shiftKey &&
        (event.keyCode === 37 || event.key === 'ArrowLeft')) ||
      (allowEnterKey &&
        event.target.value === '' &&
        (event.keyCode === 13 || event.key === 'Enter')) ||
      (overrideBrowserKeyboardNavigation &&
        event.shiftKey &&
        (event.keyCode === 9 || event.key === 'Tab')) ||
      (event.target.value === '' &&
        event.shiftKey &&
        (event.keyCode === 9 || event.key === 'Tab'))
    ) {
      // Shift + (ArrowLeft)
      // Enter key (on an input with no value)
      // Shift + Tab (if browser keyboard navigation is overridden)
      // Shift + Tab (on an input with no value)
      event.preventDefault();
      // move back one level
      if (
        event.target &&
        event.target.parentNode &&
        event.target.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode.parentNode
      ) {
        // This should not be necessary if the TreeBuilder getFieldValueAtIndex() is used
        //component.getButtonComponent().click(); // submit now

        component.setIsMovingTreeField(true);

        // input / form-group / procedure-step / tree-item-field / draggable
        let element = event.target.parentNode.parentNode.parentNode.parentNode;
        let currentId = element.dataset.itemId;

        component
          .getTreeComponent()
          .moveItemBackOneLevelAtIndex(currentId, 'existing');

        component.setIsMovingTreeField(false);

        event.target.focus();
      }
    }

    if (event.shiftKey && (event.keyCode === 38 || event.key === 'ArrowUp')) {
      // Shift + (ArrowUp)
      event.preventDefault();
      // move up one row
      if (
        event.target &&
        event.target.parentNode &&
        event.target.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode.parentNode
      ) {
        let inputName = event.target.name;

        component.setIsMovingTreeField(true);

        // input / form-group / procedure-step / tree-item-field / draggable
        let element = event.target.parentNode.parentNode.parentNode.parentNode;

        let sequence = [];
        sequence.push(32); // space, pick up draggable
        sequence.push(38); // arrow up, move draggable to entry immediately above
        sequence.push(38); // arrow up, move draggable again, past entry immediately above
        sequence.push(32); // space, put down draggable

        pressSequence(element, sequence, 50, 0);

        component.setIsMovingTreeField(false);

        event.target.focus();

        setTimeout(() => {
          let inputElem = document.getElementsByName(inputName)[0];
          inputElem.focus();

          if (!isVisible(inputElem)) {
            inputElem.scrollIntoView();
          }
        }, 1000);
      }
    }

    if (!event.shiftKey && (event.keyCode === 38 || event.key === 'ArrowUp')) {
      // ArrowUp (without Shift key)
      event.preventDefault();
      // move focus up one row
      if (
        event.target &&
        event.target.parentNode &&
        event.target.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode.parentNode
      ) {
        // input / form-group / procedure-step / tree-item-field / draggable
        let element = event.target.parentNode.parentNode.parentNode.parentNode;

        let previousSibling = element.previousSibling;

        if (
          previousSibling &&
          previousSibling.children &&
          previousSibling.children.length > 0 &&
          previousSibling.children[3] &&
          previousSibling.children[3].children &&
          previousSibling.children[3].children.length > 0 &&
          previousSibling.children[3].children[0] &&
          previousSibling.children[3].children[0].children &&
          previousSibling.children[3].children[0].children.length > 0 &&
          previousSibling.children[3].children[0].children[1] &&
          previousSibling.children[3].children[0].children[1].children &&
          previousSibling.children[3].children[0].children[1].children.length >
            0 &&
          previousSibling.children[3].children[0].children[1].children[0]
        ) {
          // draggable / tree-item-field / procedure-step / form-group / input
          previousSibling.children[3].children[0].children[1].children[0].focus();
        }
      }
    }

    if (
      (allowShiftArrowRightKey &&
        event.shiftKey &&
        (event.keyCode === 39 || event.key === 'ArrowRight')) ||
      (overrideBrowserKeyboardNavigation &&
        !event.shiftKey &&
        (event.keyCode === 9 || event.key === 'Tab')) ||
      (event.target.value === '' &&
        !event.shiftKey &&
        (event.keyCode === 9 || event.key === 'Tab'))
    ) {
      // Shift + (ArrowRight)
      // Tab (if browser keyboard navigation is overridden, without Shift key)
      // Tab (on an input with no value, without Shift key)
      event.preventDefault();
      // move forward one level
      if (
        event.target &&
        event.target.parentNode &&
        event.target.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode.parentNode
      ) {
        // This should not be necessary if the TreeBuilder getFieldValueAtIndex() is used
        //component.getButtonComponent().click(); // submit now

        component.setIsMovingTreeField(true);

        // input / form-group / procedure-step / tree-item-field / draggable
        let element = event.target.parentNode.parentNode.parentNode.parentNode;
        let currentId = element.dataset.itemId;
        let currentLevel = parseInt(element.dataset.level) || 0;

        if (currentLevel >= hierarchyLevelLimit - 1) {
          return;
        }

        component
          .getTreeComponent()
          .moveItemForwardOneLevelAtIndex(currentId, 'existing');

        component.setIsMovingTreeField(false);

        event.target.focus();
      }
    }

    if (event.shiftKey && (event.keyCode === 40 || event.key === 'ArrowDown')) {
      // Shift + (ArrowDown)
      event.preventDefault();
      // move down one row
      if (
        event.target &&
        event.target.parentNode &&
        event.target.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode.parentNode
      ) {
        let inputName = event.target.name;

        component.setIsMovingTreeField(true);

        // input / form-group / procedure-step / tree-item-field / draggable
        let element = event.target.parentNode.parentNode.parentNode.parentNode;

        let sequence = [];
        sequence.push(32); // space, pick up draggable
        sequence.push(40); // arrow down, move draggable to entry immediately below
        sequence.push(40); // arrow down, move draggable again, past entry immediately below
        sequence.push(32); // space, put down draggable

        pressSequence(element, sequence, 50, 0);

        component.setIsMovingTreeField(false);

        event.target.focus();

        setTimeout(() => {
          let inputElem = document.getElementsByName(inputName)[0];
          inputElem.focus();

          if (!isVisible(inputElem)) {
            inputElem.scrollIntoView();
          }
        }, 1000);
      }
    }

    if (
      !event.shiftKey &&
      (event.keyCode === 40 || event.key === 'ArrowDown')
    ) {
      // ArrowDown (without Shift key)
      event.preventDefault();
      // move focus down one row
      if (
        event.target &&
        event.target.parentNode &&
        event.target.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode.parentNode
      ) {
        // input / form-group / procedure-step / tree-item-field / draggable
        let element = event.target.parentNode.parentNode.parentNode.parentNode;

        let nextSibling = element.nextSibling;

        if (
          nextSibling &&
          nextSibling.children &&
          nextSibling.children.length > 0 &&
          nextSibling.children[3] &&
          nextSibling.children[3].children &&
          nextSibling.children[3].children.length > 0 &&
          nextSibling.children[3].children[0] &&
          nextSibling.children[3].children[0].children &&
          nextSibling.children[3].children[0].children.length > 0 &&
          nextSibling.children[3].children[0].children[1] &&
          nextSibling.children[3].children[0].children[1].children &&
          nextSibling.children[3].children[0].children[1].children.length > 0 &&
          nextSibling.children[3].children[0].children[1].children[0]
        ) {
          // draggable / tree-item-field / procedure-step / form-group / input
          nextSibling.children[3].children[0].children[1].children[0].focus();
        }
      }
    }

    if (
      event.target.value === '' &&
      (event.keyCode === 27 || event.key === 'Escape')
    ) {
      // Esc key (on an input with no value)
      event.preventDefault();
      // remove item
      if (
        event.target &&
        event.target.parentNode &&
        event.target.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode &&
        event.target.parentNode.parentNode.parentNode.parentNode
      ) {
        component.setIsMovingTreeField(true);

        // input / form-group / procedure-step / tree-item-field / draggable
        let element = event.target.parentNode.parentNode.parentNode.parentNode;
        let currentId = element.dataset.itemId;

        let previousSibling = element.previousSibling;

        component.delTreeItem(
          data.originalData,
          data.originalIndex,
          false,
          currentId,
          event
        );

        component.setIsMovingTreeField(false);

        if (
          previousSibling &&
          previousSibling.children &&
          previousSibling.children.length > 0 &&
          previousSibling.children[3] &&
          previousSibling.children[3].children &&
          previousSibling.children[3].children.length > 0 &&
          previousSibling.children[3].children[0] &&
          previousSibling.children[3].children[0].children &&
          previousSibling.children[3].children[0].children.length > 0 &&
          previousSibling.children[3].children[0].children[1] &&
          previousSibling.children[3].children[0].children[1].children &&
          previousSibling.children[3].children[0].children[1].children.length >
            0 &&
          previousSibling.children[3].children[0].children[1].children[0]
        ) {
          // draggable / tree-item-field / procedure-step / form-group / input
          previousSibling.children[3].children[0].children[1].children[0].focus();
        } else {
          let elem = document.getElementById(addElementId);
          if (elem) {
            elem.focus();
          }
          component.setHasFirstItem(false);
        }
      }
    }
  }
}

export function pressSequence(element, keyCodes, delay, index) {
  if (index > keyCodes.length) {
    return;
  }
  setTimeout(() => {
    pressKey(element, keyCodes[index]);
    pressSequence(element, keyCodes, delay, index + 1);
  }, delay);
}

export function pressKey(el, keyCode, modifier) {
  var eventObj = document.createEventObject
    ? document.createEventObject()
    : document.createEvent('Events');

  if (eventObj.initEvent) {
    eventObj.initEvent('keydown', true, true);
  }

  eventObj.keyCode = keyCode;
  eventObj.which = keyCode;

  if ('alt' === modifier) {
    eventObj.altKey = true;
  }
  if ('ctrl' === modifier) {
    eventObj.ctrlKey = true;
  }
  if ('meta' === modifier) {
    eventObj.metaKey = true;
  }
  if ('shift' === modifier) {
    eventObj.shiftKey = true;
  }

  return el.dispatchEvent
    ? el.dispatchEvent(eventObj)
    : el.fireEvent('onkeydown', eventObj);
}

// https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
export function isVisible(elem) {
  const style = getComputedStyle(elem);

  if (style.display === 'none') {
    return false;
  }
  if (style.visibility !== 'visible') {
    return false;
  }
  if (style.opacity === 0) {
    return false;
  }

  if (
    elem.offsetWidth +
      elem.offsetHeight +
      elem.getBoundingClientRect().height +
      elem.getBoundingClientRect().width ===
    0
  ) {
    return false;
  }

  const elementPoints = {
    center: {
      x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
      y: elem.getBoundingClientRect().top + elem.offsetHeight / 2
    },
    topLeft: {
      x: elem.getBoundingClientRect().left,
      y: elem.getBoundingClientRect().top
    },
    topRight: {
      x: elem.getBoundingClientRect().right,
      y: elem.getBoundingClientRect().top
    },
    bottomLeft: {
      x: elem.getBoundingClientRect().left,
      y: elem.getBoundingClientRect().bottom
    },
    bottomRight: {
      x: elem.getBoundingClientRect().right,
      y: elem.getBoundingClientRect().bottom
    }
  };

  const docWidth = document.documentElement.clientWidth || window.innerWidth;
  const docHeight = document.documentElement.clientHeight || window.innerHeight;

  if (elementPoints.topLeft.x > docWidth) {
    return false;
  }
  if (elementPoints.topLeft.y > docHeight) {
    return false;
  }
  if (elementPoints.bottomRight.x < 0) {
    return false;
  }
  if (elementPoints.bottomRight.y < 0) {
    return false;
  }

  for (let index in elementPoints) {
    const point = elementPoints[index];
    let pointContainer = document.elementFromPoint(point.x, point.y);
    if (pointContainer !== null) {
      do {
        if (pointContainer === elem) {
          return true;
        }
      } while ((pointContainer = pointContainer.parentNode));
    }
  }

  return false;
}
