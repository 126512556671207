import React, { Component } from 'react';

import { fetch } from '../utils/dataAccess';

class SecureImage extends Component {
  state = {
    ...this.props
  };

  componentDidMount() {
    let file = this.state.file;

    let fileHdrs = new Headers();
    fileHdrs.set('Accept', file.filetype);
    fileHdrs.set('Content-Type', file.filetype);

    fetch(file.location + '/get', {
      headers: fileHdrs
    })
      .then(response => response.blob())
      .then(blob => {
        let reader = new FileReader();
        reader.addEventListener('load', () => {
          let elem = document.getElementById(this.props.id);
          if (elem) {
            elem.src = reader.result;
          }
        });
        reader.readAsDataURL(blob);
      });
  }

  render() {
    // data-action 'zoom' allows vanilla-zoom.js to attach itself to this image
    const zoomDataAction =
      this.props?.zoom && this.props.zoom === true ? 'zoom' : '';

    return (
      <img
        {...this.props.attributes}
        id={this.props.id}
        src={this.props.src}
        alt={this.props.alt}
        data-action={zoomDataAction}
      />
    );
  }
}

export default SecureImage;
