import { connect } from 'react-redux';

import { retrieveSubscription } from '../../actions/account/subscription';
import {
  getCurrentOrganizationForAccount,
  getCurrentOrganizationSubscriptionForAccount,
  isActiveCurrentOrganizationSubscriptionForAccount,
  isSubscriptionStatusInvalid
} from '../../utils/subscription';

const rules = {
  is_active: ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved),
  is_active_and_valid: ({ retrieved }) =>
    isActiveCurrentOrganizationSubscriptionForAccount(retrieved) &&
    !isSubscriptionStatusInvalid(
      getCurrentOrganizationSubscriptionForAccount(retrieved)
    ),
  is_active_with_statistics: props =>
    isActiveCurrentOrganizationSubscriptionForAccount(props.retrieved)
      ? getSubscriptionStatistics(props)
      : false
};

const getCurrentOrganizationId = account =>
  (account && account['@_organizationCurrentId']) || '';

const getSubscriptionStatistics = props => {
  const currentOrganization = getCurrentOrganizationForAccount(props.retrieved);
  const currentOrganizationSubscription =
    getCurrentOrganizationSubscriptionForAccount(props.retrieved);

  return {
    currentAccount: props.retrieved,
    currentOrganization,
    currentOrganizationSubscription,
    ...props
  };

  // return value must be immediate and not Promise
  /*
  if (!props.retrieveSubscription) {
    return false;
  }

  return props.retrieveSubscription(currentOrganization).then(subscription => {
    return subscription;
  })
  */
};

const check = (rules, action, props) => {
  const permissions = rules[action];

  if (permissions) {
    return permissions(props);
  }

  return false;
};

const CurrentUserActiveSubscription = props => {
  if (typeof props.retrieved === 'undefined' || props.retrieved === null) {
    return props.loading();
  }

  const result = check(rules, props.perform, props);

  return !!result ? props.yes(result) : props.no(result);
};

CurrentUserActiveSubscription.defaultProps = {
  loading: () => null,
  yes: () => null,
  no: () => null
};

export const checkCurrentUserActiveSubscription = (
  account,
  perform,
  returnLoading = null,
  returnYes = true,
  returnNo = false,
  props = {}
) =>
  CurrentUserActiveSubscription({
    retrieved: account,
    perform,
    loading: loadingProps =>
      typeof returnLoading === 'function'
        ? returnLoading(loadingProps)
        : returnLoading,
    yes: yesProps =>
      typeof returnYes === 'function' ? returnYes(yesProps) : returnYes,
    no: noProps =>
      typeof returnNo === 'function' ? returnNo(noProps) : returnNo,
    ...props
  });

const mapStateToProps = state => ({
  retrieved: state.account.show.retrieved
});

const mapDispatchToProps = dispatch => ({
  retrieveSubscription: item => dispatch(retrieveSubscription(item))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentUserActiveSubscription);
