import { combineReducers } from 'redux';

import { login } from '../../utils/auth';

export function error(state = null, action) {
  switch (action.type) {
    case 'ACCOUNT_REGISTER_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'ACCOUNT_REGISTER_LOADING':
      return action.loading;

    default:
      return state;
  }
}

export function registered(state = null, action) {
  switch (action.type) {
    case 'ACCOUNT_REGISTER_SUCCESS':
      if (action.registered && action.registered.token) {
        login(action.registered.token); // The JWT token is stored in the browser's local storage
      }
      return action.registered;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, registered });
