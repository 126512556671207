import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { Component } from 'react';

import { STRIPE_PUBLISHABLE_KEY } from '../../config/stripe';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const withStripeForm = (
  WrappedComponent,
  StripeFormComponent,
  additionalProps = {}
) => {
  return class StripeForm extends Component {
    state = {
      paymentUpdateSuccess: null,
      paymentUpdateError: null,
      paymentUpdateLoading: null
    };

    _wrappedFormComponent = null;
    _stripeFormComponent = null;

    attachWrappedFormComponent = node => {
      this._wrappedFormComponent = node;
    };

    getWrappedFormComponent = () => {
      return this._wrappedFormComponent;
    };

    attachStripeFormComponent = node => {
      this._stripeFormComponent = node;
    };

    getStripeFormComponent = () => {
      return this._stripeFormComponent;
    };

    onPaymentSubmit = event => {
      this.setState({
        paymentUpdateSuccess: null,
        paymentUpdateError: null,
        paymentUpdateLoading: true
      });
    };

    paymentUpdated = (data, paymentMethod) => {
      this.setState({
        paymentUpdateSuccess: true,
        paymentUpdateError: null,
        paymentUpdateLoading: null
      });

      return { paymentUpdated: data, paymentMethod };
    };

    paymentNotUpdated = (error, paymentMethod) => {
      this.setState({
        paymentUpdateSuccess: null,
        paymentUpdateError: error,
        paymentUpdateLoading: null
      });

      return { paymentNotUpdated: error, paymentMethod };
    };

    render() {
      return (
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ elements, stripe }) => (
              <WrappedComponent
                {...this.props}
                {...additionalProps}
                stripeElements={elements}
                stripe={stripe}
                stripeFormComponent={
                  <StripeFormComponent
                    onPaymentSubmit={this.onPaymentSubmit}
                    paymentUpdated={this.paymentUpdated}
                    paymentNotUpdated={this.paymentNotUpdated}
                    isPaymentSubmitting={() => this.state.paymentUpdateLoading}
                    isPaymentSuccess={() => this.state.paymentUpdateSuccess}
                    isPaymentError={() => this.state.paymentUpdateError}
                    {...this.props}
                    {...additionalProps}
                    stripeElements={elements}
                    stripe={stripe}
                    getWrappedFormComponent={this.getWrappedFormComponent}
                    ref={this.attachStripeFormComponent.bind(this)}
                  />
                }
                getStripeFormComponent={this.getStripeFormComponent}
                ref={this.attachWrappedFormComponent.bind(this)}
              />
            )}
          </ElementsConsumer>
        </Elements>
      );
    }
  };
};

export default withStripeForm;
