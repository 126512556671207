import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { trackCardSubmission } from '../../utils/tracking';
import StripeCreditCardForm from '../subscription/StripeCreditCardForm';
import withStripeForm from '../subscription/WithStripeForm';

class CardPaymentForm extends Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    listPayments: PropTypes.func.isRequired
  };

  state = {
    localSubscription: {},
    showPaymentForm: false
  };

  componentDidMount() {}

  handleLocalSubmit = async event => {
    event.preventDefault();

    let stripeData = null;
    if (
      typeof this.props.getStripeFormComponent === 'function' &&
      !!this.props.getStripeFormComponent() &&
      typeof this.props.getStripeFormComponent().handleSubmit === 'function'
    ) {
      stripeData = await this.props
        .getStripeFormComponent()
        .handleSubmit(event)
        .then(() => {
          this.props.handleClose();
          this.props.listPayments();
        });
    }

    if (
      !stripeData ||
      (!!stripeData &&
        (!stripeData.paymentMethod ||
          (!!stripeData.paymentMethod && !stripeData.paymentMethod.id)))
    ) {
      // error
      return;
    }
  };

  getIsPaymentUpdateSubmitting = () => {
    if (
      typeof this.props.getStripeFormComponent === 'function' &&
      !!this.props.getStripeFormComponent() &&
      typeof this.props.getStripeFormComponent().isPaymentSubmitting ===
        'function'
    ) {
      return this.props.getStripeFormComponent().isPaymentSubmitting();
    }

    return false;
  };

  getIsPaymentUpdateSuccess = () => {
    if (
      typeof this.props.getStripeFormComponent === 'function' &&
      !!this.props.getStripeFormComponent() &&
      typeof this.props.getStripeFormComponent().isPaymentSuccess === 'function'
    ) {
      return this.props.getStripeFormComponent().isPaymentSuccess();
    }

    return false;
  };

  render() {
    const isPaymentUpdateSubmitting = this.getIsPaymentUpdateSubmitting();
    return (
      <>
        <div className={'payment-method-form-wrapper'} id={'payment-form'}>
          <form className={'form'} onSubmit={this.handleLocalSubmit}>
            <div className={'form-row'}>
              <div className={'form-group col'}>
                <label htmlFor={'name-on-card'} className={'sr-only'}>
                  Name
                </label>
                <input
                  id={'payment-name'}
                  name={'name'}
                  placeholder={'Name on Card'}
                  required={true}
                  className={'form-control'}
                />
              </div>
            </div>
            <div className={'form-row'}>
              <div className={'form-group col'}>
                <label htmlFor={'payment-city'} className={'sr-only'}>
                  City
                </label>
                <input
                  id={'payment-city'}
                  name={'city'}
                  placeholder={'City'}
                  required={true}
                  className={'form-control'}
                />
              </div>
              <div className={'form-group col'}>
                <label htmlFor={'payment-state'} className={'sr-only'}>
                  State
                </label>
                <input
                  id={'payment-state'}
                  name={'state'}
                  placeholder={'State'}
                  required={true}
                  className={'form-control'}
                />
              </div>
              <div className={'form-group col'}>
                <label htmlFor={'payment-postal-code'} className={'sr-only'}>
                  Postal Code
                </label>
                <input
                  id={'payment-postal-code'}
                  name={'postal_code'}
                  placeholder={'Postal Code'}
                  required={true}
                  className={'form-control'}
                />
              </div>
            </div>
            <div className={'form-row'}>
              <div className={'form-group col'}>
                {this.props.stripeFormComponent}
              </div>
            </div>

            <div className={'pull-right ml-2 mb-1'}>
              <button
                className={'btn btn-outline-danger mr-2'}
                variant="secondary"
                onClick={this.props.handleClose}
              >
                Cancel
              </button>
              <button
                className={'btn btn-primary submit-card'}
                type="submit"
                disabled={isPaymentUpdateSubmitting}
              >
                {isPaymentUpdateSubmitting ? (
                  <span>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    >
                      {''}
                    </span>{' '}
                    Submitting...
                  </span>
                ) : (
                  'Submit Card'
                )}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default withStripeForm(CardPaymentForm, StripeCreditCardForm, {
  onPreAttachPaymentMethod: (event, paymentMethod, component) => {
    trackCardSubmission(event, paymentMethod);
  },
  cardElementOptions: () => {
    return {
      hidePostalCode: true
    };
  },
  cardElementContainerProps: defaultProps => {
    return {
      className: 'form-control h-auto'
    };
  }
});
