import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import logo from '../assets/img/logo-white.png';
import { HOMEPAGE_URL } from '../config/entrypoint';
//import { Link } from 'react-router-dom';
import { VersionLink as Link } from './VersionLink';

const PublicHeader = () => {
  return (
    <Navbar expand="lg">
      <div className={'custom-container'}>
        {HOMEPAGE_URL ? (
          <a href={HOMEPAGE_URL} className={'navbar-brand'}>
            <img src={logo} width={176} height={80} alt={'Lista'} />
          </a>
        ) : (
          <Link to="/" className={'navbar-brand'}>
            <img src={logo} width={176} height={80} alt={'Lista'} />
          </Link>
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <Link to="/login" className={'btn btn-outline-secondary mr-2'}>
              Login
            </Link>
            <Link to="/register" className={'btn yellow-button'}>
              Register
            </Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default PublicHeader;
