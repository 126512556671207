//import { /*Link,*/ Redirect } from 'react-router-dom';
//import { VersionLink as Link } from '../VersionLink';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import { connect } from 'react-redux';

import { requestReset, reset } from '../../actions/resetting/request';
import ResettingRequestForm from './ResettingRequestForm';

class ResettingRequest extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    requested: PropTypes.object,
    requestReset: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    //if (this.props.requested) return <Redirect to="/login" />;

    return (
      <div className="resettingRequest-user-wrapper">
        {/*
        <div className={'page-actions-wrapper'}>
          <div className={'page-actions resettingRequest-user-action'}>
            <Link to="/login" className="btn btn-primary">
              Back to Login
            </Link>
          </div>
        </div>
        */}
        <Card
          className={
            'mt-5 col-lg-4 col-md-6 col-sm-8 offset-lg-4 offset-md-3 offset-sm-2'
          }
        >
          <Card.Body>
            <Card.Title>Password Reset</Card.Title>

            {this.props.requested && (
              <div className="alert alert-success" role="status">
                <p>
                  An email has been sent. It contains a link you must click to
                  reset your password.
                </p>
                <p>
                  Note: You can only request a new password once within 24
                  hours.
                </p>
                <p>
                  If you don't get an email check your spam folder or try again.
                </p>
              </div>
            )}

            {this.props.loading && (
              <div className="alert alert-info" role="status">
                Loading...
              </div>
            )}
            {this.props.error && (
              <div className="alert alert-danger" role="alert">
                <span
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                />{' '}
                {this.props.error}
              </div>
            )}

            <ResettingRequestForm
              onSubmit={this.props.requestReset}
              values={this.props.item}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { requested, error, loading } = state.resetting.resettingRequest;
  return { requested, error, loading };
};

const mapDispatchToProps = dispatch => ({
  requestReset: values => dispatch(requestReset(values)),
  reset: () => dispatch(reset())
});

export default connect(mapStateToProps, mapDispatchToProps)(ResettingRequest);
