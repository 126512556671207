import React from 'react';
import { Route } from 'react-router-dom';

import { Create, List, Show, Update } from '../components/cartitem/';

export default [
  <Route path="/cart_items/create" component={Create} exact key="create" />,
  <Route path="/cart_items/edit/:id" component={Update} exact key="update" />,
  <Route path="/cart_items/show/:id" component={Show} exact key="show" />,
  <Route path="/cart_items/" component={List} exact strict key="list" />,
  <Route path="/cart_items/:page" component={List} exact strict key="page" />
];
