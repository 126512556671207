import type { ItemId, TreeItem } from '@atlaskit/tree/types';
import { cloneDeepWith } from 'lodash';

export default class TreeBuilder {
  rootId: ItemId;

  items: { [ItemId]: TreeItem };

  constructor(rootId: ItemId, data, preBuilt, useClone, getFieldValueAtIndex) {
    const rootItem = this._createItem(`${rootId}`, data);
    this.rootId = rootItem.id;
    this.items = {
      [rootItem.id]: rootItem
    };

    if (preBuilt) {
      this.rootId = rootId;
      if (!!useClone) {
        this.items = cloneDeepWith(data, (value, key, object, stack) => {
          if ('originalFieldValue' === key) {
            if (
              typeof getFieldValueAtIndex === 'function' &&
              !isNaN(object.originalFieldIndex)
            ) {
              return getFieldValueAtIndex(object.originalFieldIndex);
            }
          }
          if ('field' === key) {
            // most likely the "field" is a React element, so just return it rather than continuing to deep clone it
            // @see generateStepTree()
            return value;
          }
        });
      } else {
        this.items = data;
      }
    }
  }

  withLeaf(id: number, data) {
    const leafItem = this._createItem(`${this.rootId}-${id}`, data);
    this._addItemToRoot(leafItem.id);
    this.items[leafItem.id] = leafItem;
    return this;
  }

  withSubTree(tree: TreeBuilder) {
    const subTree = tree.build();
    this._addItemToRoot(`${this.rootId}-${subTree.rootId}`);
    Object.keys(subTree.items).forEach(itemId => {
      const finalId = `${this.rootId}-${itemId}`;
      this.items[finalId] = {
        ...subTree.items[itemId],
        id: finalId,
        children: subTree.items[itemId].children.map(i => `${this.rootId}-${i}`)
      };
    });
    return this;
  }

  withLeafForParentAtIndex(id: number, parentId, index, data) {
    const leafItem = this._createItem(`${parentId}-${id}`, data);

    const parentItem = this.items[parentId];
    parentItem.children.splice(index, 0, leafItem.id);
    parentItem.isExpanded = true;
    parentItem.hasChildren = true;

    this.items[leafItem.id] = leafItem;

    return this;
  }

  moveItemBackOneLevelAtIndex(sourceItemId, index) {
    if (!sourceItemId) {
      return;
    }

    // get source
    const sourceItem = this.items[sourceItemId];

    // get parent of source
    let sourceParentItemId = '';
    Object.keys(this.items).forEach(itemId => {
      if (this.items[itemId].children.indexOf(sourceItem.id) !== -1) {
        sourceParentItemId = itemId;
      }
    });

    if (!sourceParentItemId) {
      return;
    }

    const sourceParentItem = this.items[sourceParentItemId];

    const sourceIndex = sourceParentItem.children.indexOf(sourceItem.id);

    // get parent of that source parent
    let destinationParentItemId = '';
    Object.keys(this.items).forEach(itemId => {
      if (this.items[itemId].children.indexOf(sourceParentItem.id) !== -1) {
        destinationParentItemId = itemId;
      }
    });

    if (!destinationParentItemId) {
      return;
    }

    // move siblings of source to children of source
    let sourceSiblings = [];
    sourceParentItem.children.forEach((itemId, itemIndex) => {
      if (itemIndex > sourceIndex) {
        sourceSiblings.push(itemId);
      }
    });
    sourceSiblings.forEach(itemId => {
      sourceParentItem.children = sourceParentItem.children.filter(el => {
        return el !== itemId;
      });
      sourceParentItem.hasChildren = sourceParentItem.children.length > 0;
      sourceItem.children.splice(sourceItem.children.length, 0, itemId);
      sourceItem.hasChildren = sourceItem.children.length > 0;
    });
    // end move siblings

    const destinationParentItem = this.items[destinationParentItemId];
    const sourceParentIndex = destinationParentItem.children.indexOf(
      sourceParentItem.id
    );

    // remove child from source parent
    sourceParentItem.children = sourceParentItem.children.filter(el => {
      return el !== sourceItem.id;
    });
    sourceParentItem.hasChildren = sourceParentItem.children.length > 0;

    // add child to parent of source parent at index
    let destIndex = index;
    if (isNaN(destIndex)) {
      // move just after old parent
      destIndex = sourceParentIndex + 1;
    }
    const destinationParentItemChildrenPreviousCount =
      destinationParentItem.children.length;
    const destinationIndex =
      destinationParentItemChildrenPreviousCount < destIndex
        ? destinationParentItemChildrenPreviousCount
        : destIndex > 0
        ? destIndex
        : 0;
    destinationParentItem.children.splice(destinationIndex, 0, sourceItem.id);
    destinationParentItem.isExpanded = true;
    destinationParentItem.hasChildren = true;

    return {
      tree: this.build(),
      source: {
        parentId: sourceParentItem.id,
        index: sourceIndex
      },
      destination: {
        parentId: destinationParentItem.id,
        index: destinationIndex
      }
    };
  }

  moveItemForwardOneLevelAtIndex(sourceItemId, index) {
    if (!sourceItemId) {
      return;
    }

    // get source
    const sourceItem = this.items[sourceItemId];

    // get parent of source
    let sourceParentItemId = '';
    Object.keys(this.items).forEach(itemId => {
      if (this.items[itemId].children.indexOf(sourceItem.id) !== -1) {
        sourceParentItemId = itemId;
      }
    });

    if (!sourceParentItemId) {
      return;
    }

    const sourceParentItem = this.items[sourceParentItemId];

    const sourceIndex = sourceParentItem.children.indexOf(sourceItem.id);

    if (sourceIndex <= 0) {
      // the source item is the first element, so it cannot be moved forward under another item
      return;
    }

    // get previous sibling of source in order to set it as the new parent of source
    const previousSiblingIndex = sourceIndex - 1;
    const previousSiblingId = sourceParentItem.children[previousSiblingIndex];

    // get new parent of the source
    let destinationParentItemId = previousSiblingId;

    if (!destinationParentItemId) {
      return;
    }

    const destinationParentItem = this.items[destinationParentItemId];

    // remove child from source parent
    sourceParentItem.children = sourceParentItem.children.filter(el => {
      return el !== sourceItem.id;
    });
    sourceParentItem.hasChildren = sourceParentItem.children.length > 0;

    // add child to new parent of source at index
    let destIndex = index;
    if (isNaN(destIndex)) {
      // keep current index
      destIndex = sourceIndex;
    }
    const destinationParentItemChildrenPreviousCount =
      destinationParentItem.children.length;
    const destinationIndex =
      destinationParentItemChildrenPreviousCount < destIndex
        ? destinationParentItemChildrenPreviousCount
        : destIndex > 0
        ? destIndex
        : 0;
    destinationParentItem.children.splice(destinationIndex, 0, sourceItem.id);
    destinationParentItem.isExpanded = true;
    destinationParentItem.hasChildren = true;

    return {
      tree: this.build(),
      source: {
        parentId: sourceParentItem.id,
        index: sourceIndex
      },
      destination: {
        parentId: destinationParentItem.id,
        index: destinationIndex
      }
    };
  }

  removeItem(sourceItemId) {
    if (!sourceItemId) {
      return;
    }

    // get source
    const sourceItem = this.items[sourceItemId];
    if (!sourceItem) {
      return;
    }

    // get parent of source
    let sourceParentItemId = '';
    Object.keys(this.items).forEach(itemId => {
      if (this.items[itemId].children.indexOf(sourceItem.id) !== -1) {
        sourceParentItemId = itemId;
      }
    });

    if (!sourceParentItemId) {
      return;
    }

    const sourceParentItem = this.items[sourceParentItemId];

    // remove child from source parent
    sourceParentItem.children = sourceParentItem.children.filter(el => {
      return el !== sourceItem.id;
    });
    sourceParentItem.hasChildren = sourceParentItem.children.length > 0;

    // remove item
    delete this.items[sourceItemId];

    return this;
  }

  build() {
    return {
      rootId: this.rootId,
      items: this.items
    };
  }

  _addItemToRoot(id: string) {
    const rootItem = this.items[this.rootId];
    rootItem.children.push(id);
    //rootItem.isExpanded = true;
    rootItem.hasChildren = true;
  }

  _createItem = (id: string, data) => {
    let defaultExpanded = !!data.isExpanded;

    return {
      id: `${id}`,
      children: [],
      hasChildren: false,
      isExpanded: defaultExpanded,
      isChildrenLoading: false,
      data: {
        //title: `Title ${id}`,
        ...data
      }
    };
  };
}
