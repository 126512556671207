/*  Used to calculate a character width to subtract from the width of a usedProcedureStep
    tree-field-container to prevent overlapping with a completedBy name */
export function getUsedProcedureStepWidthOffset(data, index) {
  let widthOffset = 0;
  if (data && data['@type'] === 'UsedProcedureStep') {
    const MAX_NAME_LENGTH_BEFORE_OFFSET_REQUIRED = 13;
    const MAX_NAME_DISPLAY_LENGTH = 29;
    const MAX_WIDTH_OFFSET = 8;

    if (
      data.completedByName &&
      data.completedByName.length > MAX_NAME_LENGTH_BEFORE_OFFSET_REQUIRED
    ) {
      data.completedByName =
        data.completedByName.length >= MAX_NAME_DISPLAY_LENGTH
          ? data.completedByName.slice(0, MAX_NAME_DISPLAY_LENGTH - 3) + '...'
          : data.completedByName;
      widthOffset =
        data.completedByName.length - MAX_NAME_LENGTH_BEFORE_OFFSET_REQUIRED;
      widthOffset =
        widthOffset > MAX_WIDTH_OFFSET ? MAX_WIDTH_OFFSET : widthOffset;
    }
  }
  return widthOffset;
}
