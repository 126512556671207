import { SubmissionError } from 'redux-form';

import { fetch } from '../../utils/dataAccess';

export function addPaymentMethod(paymentMethod) {
  return dispatch => {
    return fetch('/payment-methods/add', {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/ld+json' }),
      body: JSON.stringify({ paymentMethod })
    })
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        return retrieved;
      })
      .catch(e => {
        if (e instanceof SubmissionError) {
          throw e;
        }
      });
  };
}

export function detachPaymentMethodFromCustomer(paymentMethod) {
  return dispatch => {
    return fetch('/payment-method/remove', {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/ld+json' }),
      body: JSON.stringify({ paymentMethod })
    })
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        return retrieved;
      })
      .catch(e => {
        if (e instanceof SubmissionError) {
          throw e;
        }
      });
  };
}

export function listPayments() {
  return dispatch => {
    return fetch('/payment-methods/list', {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/ld+json' })
    })
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        return retrieved;
      })
      .catch(e => {
        console.log('list payment error', e);
        throw e;
      });
  };
}
