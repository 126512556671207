import React from 'react';

import { PublicLayout as Route } from './PublicLayout';

export const LoginLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={matchProps => (
        <div className="LoginLayout">
          <Component {...matchProps} />
        </div>
      )}
    />
  );
};
