import { combineReducers } from 'redux';

import clone from './clone';
import create from './create';
import del from './delete';
import list from './list';
import show from './show';
import update from './update';

export default combineReducers({ list, create, update, del, show, clone });
