// https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object
export function filterSerialize(obj, prefix, withDotNotation) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix
          ? withDotNotation && withDotNotation(prefix, p)
            ? prefix + '.' + p + ''
            : prefix + '[' + p + ']'
          : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === 'object'
          ? filterSerialize(v, k, withDotNotation)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v)
      );
    }
  }
  return str.join('&');
}

export function getFilterValues(key) {
  return JSON.parse(localStorage.getItem(key) || '{}') || {};
}

export function setFilterValues(key, values) {
  localStorage.setItem(key, JSON.stringify(values));
}

export function getPageWithFilterValues(key, page, defaultPage) {
  let filtered = getFilterValues(key);

  if (filtered) {
    if (!page) {
      page = defaultPage;
    }
    let queryString = filterSerialize(filtered, null, (prefix, p) => {
      if (prefix.indexOf('tags') !== -1) {
        return false;
      }
      return true;
    });
    if (page.indexOf(queryString) === -1) {
      let url = page + (page.indexOf('?') === -1 ? '?' : '&') + queryString;

      return url;
    }
  }

  return page;
}
