export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const STRIPE_SUBSCRIPTION_TRIAL_DAYS =
  process.env.REACT_APP_STRIPE_SUBSCRIPTION_TRIAL_DAYS;
export const STRIPE_DATA_REGISTRATION_ENABLED =
  !!process.env.REACT_APP_STRIPE_DATA_REGISTRATION_ENABLED;
export const STRIPE_SUBSCRIPTION_PRICE =
  process.env.REACT_APP_STRIPE_SUBSCRIPTION_PRICE || '';
export const STRIPE_PROMOTION_CODE_ENABLED =
  process.env.REACT_APP_STRIPE_PROMOTION_CODE_ENABLED;
