import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class DeleteTreeItemWithModal extends Component {
  state = {
    showModal: false
  };

  confirmOpenClickHandler = event => {
    if (this.props.inputName) {
      // if an item input has an empty value, no need to confirm the deletion
      let inputName = this.props.inputName;
      let inputElems = document.getElementsByName(inputName);
      if (inputElems.length > 0 && inputElems[0] && !inputElems[0].value) {
        this.confirmNowClickHandler(event);

        return;
      }
    }

    this.setState({
      showModal: true
    });

    if (typeof this.props.confirmOpenClickHandler === 'function') {
      this.props.confirmOpenClickHandler(event);
    }
  };

  confirmCloseClickHandler = event => {
    this.setState(
      {
        showModal: false
      },
      () => {
        // the state doesn't seem to update (and doesn't re-render) when just cancelling, so it is being forced here
        this.state.showModal = false;
        this.forceUpdate();
      }
    );

    if (typeof this.props.confirmCloseClickHandler === 'function') {
      this.props.confirmCloseClickHandler(event);
    }
  };

  confirmNowClickHandler = event => {
    if (event.target.className.indexOf('action-confirm') > -1) {
      event.target.disabled = true;
      event.target.innerHTML += '<span class="fa fa-spinner fa-spin"></span>';
    }

    if (typeof this.props.confirmNowClickHandler === 'function') {
      this.props.confirmNowClickHandler(event);
    }

    setTimeout(() => {
      this.confirmCloseClickHandler(event);
    }, 2000);
  };

  render() {
    return (
      <span
        onClick={this.confirmOpenClickHandler}
        className="delete-tree-item pull-right with-modal"
      >
        <OverlayTrigger
          placement={'top'}
          overlay={<Tooltip id={'tooltip-delete'}>Delete</Tooltip>}
        >
          <span className={'fa fa-trash'}>
            <span className={'sr-only'}>Delete</span>
          </span>
        </OverlayTrigger>
        <Modal
          show={this.state.showModal}
          onHide={this.confirmCloseClickHandler}
          centered
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {/* Are you sure you want to delete this Procedure Step? */}
              {/*Are you sure you want to delete this Template Step?*/}
              Are you sure you want to delete this Case Workflow Step?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>This will delete:</p>
            <ul>
              {/* <li>This Procedure Step</li>
              <li>All Steps that are children of this Procedure Step</li> */}
              {/*<li>This Template Step</li>*/}
              {/*<li>All Steps that are children of this Template Step</li>*/}
              <li>This Case Workflow Step</li>
              <li>All Steps that are children of this Case Workflow Step</li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <span className={'btn'} onClick={this.confirmCloseClickHandler}>
              Cancel
            </span>
            <span
              className={'btn btn-danger action-confirm'}
              onClick={this.confirmNowClickHandler}
            >
              {/* Delete Procedure Step */}
              {/*Delete Template Step*/}
              Delete Case Workflow Step
            </span>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

export default DeleteTreeItemWithModal;
