import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { connect } from 'react-redux';

import { retrieve as retrieveAccount } from '../../actions/account/show';
import { resendInvitation } from '../../actions/invite/resendInvitation';
import { activateOrDeactivate } from '../../actions/user/activateOrDeactivate';
import { del } from '../../actions/user/delete';
import { list, reset } from '../../actions/user/list';
import { getCurrentUserId, isAdminForOrganization } from '../../utils/auth';
import {
  isInviteWithinThresholdForInviteStatus,
  isSubscriptionStatusAtLimit,
  isSubscriptionStatusInvalid,
  isSubscriptionStatusTrialEnded
} from '../../utils/subscription';
import CurrentUserActiveSubscription from '../account/CurrentUserActiveSubscription';
import UserSubscriptionStatus from '../account/UserSubscriptionStatus';
import CurrentUserCan from '../CurrentUserCan';
//import { Link } from 'react-router-dom';
import { VersionLink as Link } from '../VersionLink';

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = item => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      this.props.del(item);
      this.props.list(
        this.props.match.params.page &&
          decodeURIComponent(this.props.match.params.page)
      );
    }
  };

  resendInvitation = item => {
    this.props.resendInvitation(item);
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  };

  activateOrDeactivate = item => {
    const activateOrDeactivate = this.props.activateOrDeactivate(item);

    if (typeof activateOrDeactivate.then === 'function') {
      activateOrDeactivate.then(retrieved => {
        // make sure the current user and subscription data is up-to-date
        const userId = getCurrentUserId();
        this.props.retrieveAccount(decodeURIComponent(userId));
      });
    }

    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  };

  getCurrentEnabledStatus = item => {
    if (item['@_organizationCurrentEnabledStatus']) {
      return 'Active';
    }

    return isInviteWithinThresholdForInviteStatus(
      item.createdAtDate,
      item.createdAtDateTime
    )
      ? 'Invited'
      : 'Deactivated';
  };

  render() {
    return (
      <div className={'page-list page-user-list mt-3'}>
        <div className={'page-actions-wrapper'}>
          <div className={'page-actions'}>
            {/*
            <Link to="create" className="btn btn-primary">
              Create New User
            </Link>
            */}
            <CurrentUserCan
              perform={'user:invite'}
              yes={() => (
                <CurrentUserActiveSubscription
                  perform={'is_active_with_statistics'}
                  yes={statistics => (
                    <>
                      <UserSubscriptionStatus {...statistics} />
                      {isAdminForOrganization(
                        statistics.currentOrganization['@id'] || null
                      ) &&
                      (isSubscriptionStatusAtLimit(
                        statistics.currentOrganizationSubscription
                      ) ||
                        isSubscriptionStatusTrialEnded(
                          statistics.currentOrganizationSubscription
                        ) ||
                        isSubscriptionStatusInvalid(
                          statistics.currentOrganizationSubscription
                        )) ? (
                        <Link to="/account" className="btn btn-cta">
                          Purchase Seats
                        </Link>
                      ) : (
                        <Link to="/invite" className="btn btn-cta">
                          Invite User
                        </Link>
                      )}
                    </>
                  )}
                />
              )}
            />
          </div>
        </div>

        <nav className={'nav'}>
          <Link className={'nav-link h1 active'} to={'/users/'}>
            <h1>Users</h1>
          </Link>
        </nav>

        {/*
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        */}
        {this.props.loading && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                Loading...
              </div>
            </div>
          </div>
        )}
        {this.props.deletedItem && (
          <div className="alert alert-success">
            {this.props.deletedItem['@id']} deleted.
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        {this.props.userActivateOrDeactivateError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.userActivateOrDeactivateError}
          </div>
        )}

        {/*
        <p>
          <Link to="create" className="btn btn-primary">
            Create
          </Link>
        </p>
        */}

        <div className={'user-table-list-wrapper'}>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th colSpan={2}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.retrieved &&
                this.props.retrieved['hydra:member'].map(item => (
                  <tr key={item['@id']}>
                    <th scope="row">
                      <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                        {item['name']}
                      </Link>
                    </th>
                    <td>{item['email']}</td>
                    <td>{item['roleName']}</td>
                    {/*
                  <td>
                    <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                      <span className="fa fa-search" aria-hidden="true" />
                      <span className="sr-only">Show</span>
                    </Link>
                  </td>
                  */}
                    <td>
                      <span>
                        {this.getCurrentEnabledStatus(item)}
                        {'Deactivated' === (item['enabledName'] || '') && (
                          <span
                            className={'user-not-enabled'}
                            title={
                              'The user account is not enabled. This could mean the user has not confirmed their account yet or has been explicitly disabled.'
                            }
                          >
                            {' '}
                            *{' '}
                          </span>
                        )}
                      </span>
                    </td>
                    <td>
                      <CurrentUserCan
                        perform={'user:edit'}
                        yes={() => (
                          <Link
                            className={'primary-action'}
                            to={`edit/${encodeURIComponent(item['@id'])}`}
                          >
                            <span className="fa fa-pencil" aria-hidden="true" />{' '}
                            <span>Edit</span>
                          </Link>
                        )}
                      />
                    </td>
                    {/*
                  <td>
                    <Link to={`edit/${encodeURIComponent(item['@id'])}`}>
                      <span className="fa fa-pencil" aria-hidden="true" />
                      <span className="sr-only">Edit</span>
                    </Link>
                  </td>
                  */}
                    <td>
                      <DropdownButton
                        id={
                          'dropdown-basic-button-' +
                          item['@id'].replace(/[^\d]*/, '')
                        }
                        title={
                          <span className={'fa fa-ellipsis-v'}>
                            <span className={'sr-only'}>Actions</span>
                          </span>
                        }
                      >
                        <CurrentUserCan
                          perform={'user:invite'}
                          yes={() =>
                            item['withToken'] && (
                              <Link
                                className={'dropdown-item edit-action'}
                                to={'.'}
                                onClick={() => this.resendInvitation(item)}
                              >
                                <span
                                  className="fa fa-envelope"
                                  aria-hidden="true"
                                />{' '}
                                <span>Resend Invitation</span>
                              </Link>
                            )
                          }
                        />
                        <CurrentUserCan
                          perform={'user:edit'}
                          yes={() => (
                            <Link
                              className={'dropdown-item edit-action'}
                              to={'.'}
                              onClick={() => this.activateOrDeactivate(item)}
                            >
                              <span
                                className={
                                  !!item['@_organizationCurrentEnabledStatus']
                                    ? 'fa fa-lock'
                                    : 'fa fa-unlock'
                                }
                                aria-hidden="true"
                              />{' '}
                              <span>
                                {!!item['@_organizationCurrentEnabledStatus']
                                  ? 'Deactivate'
                                  : 'Activate'}
                              </span>
                            </Link>
                          )}
                        />
                        {/*
                        <CurrentUserCan
                          perform={'user:delete'}
                          yes={() => (
                          <Link
                            className={'dropdown-item delete-action'}
                            to={'.'}
                            onClick={() => this.del(item)}
                          >
                            <span className="fa fa-trash" aria-hidden="true" />{' '}
                            <span>Delete</span>
                          </Link>
                        )}
                        />
                        */}
                      </DropdownButton>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {this.pagination()}
      </div>
    );
  }

  pagination() {
    const view = this.props.retrieved && this.props.retrieved['hydra:view'];
    if (!view) return;

    const {
      'hydra:first': first,
      'hydra:previous': previous,
      'hydra:next': next,
      'hydra:last': last
    } = view;

    return (
      <nav aria-label="Page navigation">
        <Link
          to="."
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&lArr;</span> First
        </Link>
        <Link
          to={
            !previous || previous === first ? '.' : encodeURIComponent(previous)
          }
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&larr;</span> Previous
        </Link>
        <Link
          to={next ? encodeURIComponent(next) : '#'}
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Next <span aria-hidden="true">&rarr;</span>
        </Link>
        <Link
          to={last ? encodeURIComponent(last) : '#'}
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Last <span aria-hidden="true">&rArr;</span>
        </Link>
      </nav>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource, deletedItem } =
    state.user.list;
  const {
    userActivateOrDeactivateError,
    userActivateOrDeactivateLoading,
    userActivateOrDeactivateSuccess
  } = state.user.activateOrDeactivate;
  return {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem,
    userActivateOrDeactivateError,
    userActivateOrDeactivateLoading,
    userActivateOrDeactivateSuccess
  };
};

const mapDispatchToProps = dispatch => ({
  retrieveAccount: item => dispatch(retrieveAccount(item)),
  resendInvitation: item => dispatch(resendInvitation(item)),
  activateOrDeactivate: item => dispatch(activateOrDeactivate(item)),
  del: item => dispatch(del(item)),
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
