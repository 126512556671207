import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

/*
import Modal from 'react-bootstrap/Modal';

import iconDragDrop from './assets/img/icon-product-dragdrop.png';
import iconUseProcedure from './assets/img/icon-product-useprocedure.png';
import computerGraphic from './assets/img/welcome-hero-computer.png';
import Footer from './components/Footer';
import PrivateHeader from './components/PrivateHeader';
import PublicHeader from './components/PublicHeader';
import { VersionLink as Link } from './components/VersionLink';
import { isLoggedIn } from './utils/auth';
*/

const FirstExperience = () => <Redirect to="/dashboard" />;

/*
const FirstExperience = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="first-experience" id="first-experience">
      <div className="PublicLayout">
        {isLoggedIn() ? <PrivateHeader /> : <PublicHeader />}
        <div className="covid-notice-container">
          <button
            variant="primary"
            onClick={handleShow}
            className={'covid-notice'}
          >
            COVID-19 NOTICE
          </button>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>COVID-19 NOTICE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              We know that adapting to remote work can be difficult. Having
              Lista available for case roadmaps and case tracking is now more
              valuable than ever for increasing efficiencies and reducing errors
              while working remotely. Here at Lista, our team is busy improving
              our own procedures. Working together on this core project while
              we’re remote has been a perfect way to stay connected around a
              common goal of establishing procedures that encompass best
              practices for now and in the future!
            </p>
            <p>The Lista team hopes you stay safe and well.</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={'btn-primary'}
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <div className="container">
          <div className={'row'}>
            <div className={'col-md-9'}>
              <h1>
                Thank You and{' '}
                <span className={'blue-text'}>Welcome to Lista!</span>
              </h1>
              <div className={'callout callout-welcome'}>
                <h2>Please Note:</h2>
                <p>
                  Process templates are currently designed to be created on the
                  desktop or laptop computer only. If you are on a mobile device
                  right now, please come back when you are at your computer.
                </p>
              </div>
            </div>
            <div className={'col-md-3'}>
              <img src={computerGraphic} />
            </div>
          </div>
          <div className={'row lista-features'}>
            <div className={'col-md-6'}>
              <img className={'heading-icon'} src={iconDragDrop} />
              <h2>Create & Edit Processes</h2>
              <p>
                Admins or Managers will create and edit the processes your staff
                should use. These are called "Process Templates" in Lista. As a
                new user, we have set up a sample template for you which you
                will see on the next page.
              </p>
            </div>
            <div className={'col-md-6'}>
              <img className={'heading-icon'} src={iconUseProcedure} />
              <h2>Use Processes</h2>
              <p>
                Once you have created a template, you and your staff can "use"
                them. Simply click "Use" on the template you want, and a copy of
                that process will be made for you to begin working on.
              </p>
              <p>
                You can then check off the steps as you execute them, and you
                can also tag that instance of the process to find it easily next
                time. For instance, tag it with a customer name.
              </p>
            </div>
          </div>
          <div className={'row justify-content center'}>
            <div className={'col text-center'}>
              <Link
                to="/procedures/"
                className={'btn btn-lg btn-primary yellow-button'}
              >
                Get started with Lista
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
*/

export default FirstExperience;
