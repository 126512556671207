import { SubmissionError } from 'redux-form';

import { fetch } from '../../utils/dataAccess';
import { retrieveError, retrieveLoading, retrieveSuccess } from './update';

export function procedureRetrieve(id) {
  return dispatch => {
    dispatch(retrieveLoading(true));

    return fetch(id)
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        dispatch(retrieveLoading(false));
        // return result to allow the tree to be updated
        return dispatch(retrieveSuccess(retrieved));
      })
      .catch(e => {
        dispatch(retrieveLoading(false));
        dispatch(retrieveError(e.message));
      });
  };
}

export function error(error) {
  return { type: 'PROCEDURE_STEP_STORE_TREE_ERROR', error };
}

export function loading(loading) {
  return { type: 'PROCEDURE_STEP_STORE_TREE_LOADING', loading };
}

export function success(stored) {
  if (stored && stored['@id']) {
    return {
      type: 'PROCEDURE_UPDATE_UPDATE_SUCCESS',
      updated: stored,
      retrieved: stored
    };
  }
  return { type: 'PROCEDURE_STEP_STORE_TREE_SUCCESS', stored };
}

export function procedureStepStoreTree(values) {
  return dispatch => {
    dispatch(loading(true));

    return fetch('/procedure_steps/store_tree', {
      method: 'POST',
      body: JSON.stringify(values)
    })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => dispatch(success(retrieved)))
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
  };
}
