import { SubmissionError } from 'redux-form';

import { fetch } from '../../utils/dataAccess';

export function retrieveSubscriptionInvoices(item) {
  return dispatch => {
    return fetch(item['@id'] + '/subscription-invoices')
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        return retrieved;
      })
      .catch(e => {
        if (e instanceof SubmissionError) {
          throw e;
        }
      });
  };
}

export function paySubscriptionInvoice(item, invoiceId) {
  return dispatch => {
    return fetch(item['@id'] + '/subscription-invoices/' + invoiceId + '/pay')
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        return retrieved;
      })
      .catch(e => {
        if (e instanceof SubmissionError) {
          throw e;
        }
      });
  };
}

export function retrieveSubscription(item) {
  return dispatch => {
    return fetch(item['@id'] + '/subscription')
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        return retrieved;
      })
      .catch(e => {
        if (e instanceof SubmissionError) {
          throw e;
        }
      });
  };
}

export function updateSubscriptionQuantity(item, quantity) {
  return dispatch => {
    return fetch(item['@id'] + '/update-subscription-quantity', {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/ld+json' }),
      body: JSON.stringify({ item, quantity })
    })
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        return retrieved;
      })
      .catch(e => {
        // if (e instanceof SubmissionError) {
        //   throw e;
        // }
        throw e;
      });
  };
}

export function cancelSubscription(item) {
  return dispatch => {
    return fetch(item['@id'] + '/cancel-subscription', {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/ld+json' }),
      body: JSON.stringify({ item })
    })
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        return retrieved;
      })
      .catch(e => {
        if (e instanceof SubmissionError) {
          throw e;
        }
      });
  };
}

export function reactivateSubscription(paymentMethod) {
  return dispatch => {
    return fetch('/payment-method/attach', {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/ld+json' }),
      body: JSON.stringify({ paymentMethod })
    })
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        return retrieved;
      })
      .catch(e => {
        if (e instanceof SubmissionError) {
          throw e;
        }
      });
  };
}

export function attachPaymentMethod(paymentMethod) {
  return dispatch => {
    return fetch('/payment-method/attach', {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/ld+json' }),
      body: JSON.stringify({ paymentMethod })
    })
      .then(response => response.json().then(retrieved => ({ retrieved })))
      .then(({ retrieved }) => {
        return retrieved;
      })
      .catch(e => {
        if (e instanceof SubmissionError) {
          throw e;
        }
      });
  };
}
