import React, { Component } from 'react';

import {
  categoryRetrieve,
  categoryStoreTree
} from '../../actions/category/categoryStoreTree';
import { create as createCategory } from '../../actions/category/create';
import { del as delCategory } from '../../actions/category/delete';
import TreeFormFields from '../tree/TreeFormFields';

class PurchasableCategoryTreeFormFields extends Component {
  preventFormSubmit = event => {
    return this.getTreeComponent().preventFormSubmit(event);
  };

  attachTree = node => {
    this._categoryTree = node;
  };

  getTreeComponent = () => {
    return this._categoryTree;
  };

  hasTreeData = data => {
    if (data && data.rootChildren) {
      return true;
    }

    return false;
  };

  getTreeData = data => {
    if (this.hasTreeData(data)) {
      return data.rootChildren;
    }

    return [];
  };

  getInitialItemCreationData = objectId => {
    return { name: '', root: objectId };
  };

  actionCreateItem = values => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        resolve({});
      });
    };
  };

  actionRetrieveItem = id => {
    return categoryRetrieve(id);
  };

  actionDeleteItem = (item, index) => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        resolve({});
      });
    };
  };

  actionStoreTree = values => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        resolve({});
      });
    };
  };

  render() {
    return (
      <TreeFormFields
        initialValues={this.props.initialValues}
        change={this.props.change}
        dispatch={this.props.dispatch}
        getButtonComponent={this.props.getButtonComponent}
        hasFirstItem={this.props.hasFirstItem}
        setHasFirstItem={this.props.setHasFirstItem}
        hasTreeData={this.hasTreeData}
        getTreeData={this.getTreeData}
        getInitialItemCreationData={this.getInitialItemCreationData}
        actionCreateItem={this.actionCreateItem}
        actionRetrieveItem={this.actionRetrieveItem}
        actionDeleteItem={this.actionDeleteItem}
        actionStoreTree={this.actionStoreTree}
        fieldIdPrefix={'category'}
        fieldName={'rootChildren'}
        addElementId={'add-category'}
        addElementLabel={'Create Folder'}
        displayAddButton={this.props.displayAddButton}
        displayAddButtonOnTop={true}
        deleteItemConfirmMessage={
          'Are you sure you want to delete this category?'
        }
        fieldWrapperClassName={'category'}
        fieldPlaceholder={'Folder Name'}
        displayEditItemButton={this.props.displayEditItemButton}
        displayDeleteItemButton={this.props.displayDeleteItemButton}
        displaySelectItemButton={this.props.displaySelectItemButton}
        displayTitleInField={true}
        lastSelectedFieldIndex={this.props.lastSelectedFieldIndex}
        editTreeItem={this.props.editTreeItem}
        selectTreeItem={this.props.selectTreeItem}
        trackSelectTreeItem={this.props.trackSelectTreeItem}
        allowEnterKey={this.props.allowEnterKey}
        dragOverTreeItem={this.props.dragOverTreeItem}
        dropTreeItem={this.props.dropTreeItem}
        itemWidth={160 + 20}
        iconParentOpenClass={'fa fa-folder-open-o'}
        iconParentClosedClass={'fa fa-folder-o'}
        isDraggingEnabled={this.props.isDraggingEnabled}
        ref={this.attachTree.bind(this)}
      />
    );
  }
}

export default PurchasableCategoryTreeFormFields;
