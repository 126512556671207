const internalGa = (...args) => {
  if (typeof window === 'undefined' || typeof document === 'undefined')
    return false;
  if (!window.ga) return false;
  return window.ga(...args);
};

const internalHelpHero = (...args) => {
  if (typeof window === 'undefined' || typeof document === 'undefined')
    return false;
  if (!window.HelpHero) return false;
  return window.HelpHero(...args);
};

export function trackUserLogin(event, userId) {
  if (!!userId) {
    internalHelpHero('identify', userId);
  }
}

export function trackRegistrationClick(event) {
  internalGa(
    'send',
    'event',
    'registration_button',
    'clicked_created_new_account'
  );
}

export function trackCardSubmission(event, paymentMethod) {
  internalGa('send', 'event', 'upgrade_button', 'clicked_created_submit_card');
}
