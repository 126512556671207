import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { connect } from 'react-redux';

import { del } from '../../actions/tag/delete';
import { list, reset } from '../../actions/tag/list';
import {
  filterSerialize,
  getFilterValues,
  getPageWithFilterValues,
  setFilterValues
} from '../../utils/listFilter';
import CurrentUserCan from '../CurrentUserCan';
//import { Link } from 'react-router-dom';
import { VersionLink as Link } from '../VersionLink';
import FilterForm from './FilterForm';

class List extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.list(
      this.getPageWithFilterValues(
        this.props.match.params.page &&
          decodeURIComponent(this.props.match.params.page)
      )
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        this.getPageWithFilterValues(
          nextProps.match.params.page &&
            decodeURIComponent(nextProps.match.params.page)
        )
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = item => {
    if (window.confirm('Are you sure you want to delete this tag?')) {
      this.props.del(item);
      this.props.list(
        this.getPageWithFilterValues(
          this.props.match.params.page &&
            decodeURIComponent(this.props.match.params.page)
        )
      );
    }
  };

  pressFormSubmit = () => {
    //
  };

  pressFormReset = () => {
    this.setFilterValues({});
    this.props.list(
      this.getPageWithFilterValues(
        this.props.match.params.page &&
          decodeURIComponent(this.props.match.params.page)
      )
    );
  };

  getPageWithFilterValues = page => {
    return getPageWithFilterValues('tagFilterValues', page, '/tags');
  };

  getFilterValues = () => {
    return getFilterValues('tagFilterValues');
  };

  setFilterValues = values => {
    setFilterValues('tagFilterValues', values);
  };

  isFiltered = () => {
    let filtered = this.getFilterValues();

    if (!!filtered.name || !!filtered.author) {
      return true;
    }

    return false;
  };

  serialize = (obj, prefix) => {
    return filterSerialize(obj, prefix, (prefix, p) => {
      if (prefix.indexOf('tags') !== -1) {
        return false;
      }
      return true;
    });
  };

  filter = (filtered, values) => {
    this.setFilterValues(values);

    let queryString = this.serialize(values);

    this.props.list('/tags?' + queryString);
  };

  render() {
    let filtered = this.getFilterValues();

    return (
      <div className={'page-list page-tag-list mt-3'}>
        <div className={'page-actions-wrapper'}>
          <div className={'page-actions'}>
            <CurrentUserCan
              perform={'tag:create'}
              yes={() => (
                <Link to="create" className="btn btn-primary btn-cta">
                  Create New Tag
                </Link>
              )}
            />
          </div>
        </div>

        <nav className={'nav'}>
          <Link className={'nav-link h1 active'} to={'/tags/'}>
            <h1>Tags</h1>
          </Link>
        </nav>

        {/*
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        */}
        {this.props.loading && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                Loading...
              </div>
            </div>
          </div>
        )}
        {this.props.deletedItem && (
          <div className="alert alert-success">
            {this.props.deletedItem['@id']} deleted.
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        {/*
        <p>
          <Link to="create" className="btn btn-primary">
            Create
          </Link>
        </p>
        */}

        <div className={'filter-form'}>
          <FilterForm
            onSubmit={values => this.filter(filtered, values)}
            initialValues={filtered}
            pressFormSubmit={this.pressFormSubmit}
            pressFormReset={this.pressFormReset}
          />
        </div>

        <table className="table table-responsive table-striped table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Author</th>
              <th colSpan={2}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.props.retrieved &&
              this.props.retrieved['hydra:member'].map(item => (
                <tr key={item['@id']}>
                  <th scope="row">
                    <Link to={`edit/${encodeURIComponent(item['@id'])}`}>
                      {item['name']}
                    </Link>
                  </th>
                  <td>{item['createdByName']}</td>
                  {/*
                  <td>
                    <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                      <span className="fa fa-search" aria-hidden="true" />
                      <span className="sr-only">Show</span>
                    </Link>
                  </td>
                  */}
                  <td>
                    <CurrentUserCan
                      perform={'tag:edit'}
                      yes={() => (
                        <Link
                          className={'primary-action'}
                          to={`edit/${encodeURIComponent(item['@id'])}`}
                        >
                          <span className="fa fa-pencil" aria-hidden="true" />{' '}
                          <span>Edit</span>
                        </Link>
                      )}
                    />
                  </td>
                  {/*
                  <td>
                    <Link to={`edit/${encodeURIComponent(item['@id'])}`}>
                      <span className="fa fa-pencil" aria-hidden="true" />
                      <span className="sr-only">Edit</span>
                    </Link>
                  </td>
                  */}
                  <td>
                    <DropdownButton
                      id={
                        'dropdown-basic-button-' +
                        item['@id'].replace(/[^\d]*/, '')
                      }
                      title={
                        <span className={'fa fa-ellipsis-v'}>
                          <span className={'sr-only'}>Actions</span>
                        </span>
                      }
                    >
                      {/*
                      <Link
                        className={'dropdown-item view-action'}
                        to={`show/${encodeURIComponent(item['@id'])}`}
                      >
                        <span className="fa fa-eye" aria-hidden="true" />{' '}
                        <span>View</span>
                      </Link>
                      */}
                      <CurrentUserCan
                        perform={'tag:delete'}
                        yes={() => (
                          <Link
                            className={'dropdown-item delete-action'}
                            to={'.'}
                            onClick={() => this.del(item)}
                          >
                            <span className="fa fa-trash" aria-hidden="true" />{' '}
                            <span>Delete</span>
                          </Link>
                        )}
                      />
                    </DropdownButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {this.pagination()}
      </div>
    );
  }

  pagination() {
    const view = this.props.retrieved && this.props.retrieved['hydra:view'];
    if (!view) return;

    const {
      'hydra:first': first,
      'hydra:previous': previous,
      'hydra:next': next,
      'hydra:last': last
    } = view;

    if (!first && !previous && !next && !last) {
      return;
    }

    return (
      <nav aria-label="Page navigation">
        <Link
          to="."
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&lArr;</span> First
        </Link>
        <Link
          to={
            !previous || previous === first
              ? '.'
              : encodeURIComponent(this.getPageWithFilterValues(previous))
          }
          className={`btn btn-primary${previous ? '' : ' disabled'}`}
        >
          <span aria-hidden="true">&larr;</span> Previous
        </Link>
        <Link
          to={
            next ? encodeURIComponent(this.getPageWithFilterValues(next)) : '#'
          }
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Next <span aria-hidden="true">&rarr;</span>
        </Link>
        <Link
          to={
            last ? encodeURIComponent(this.getPageWithFilterValues(last)) : '#'
          }
          className={`btn btn-primary${next ? '' : ' disabled'}`}
        >
          Last <span aria-hidden="true">&rArr;</span>
        </Link>
      </nav>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => {
  const { retrieved, loading, error, eventSource, deletedItem } =
    state.tag.list;
  return { retrieved, loading, error, eventSource, deletedItem };
};

const mapDispatchToProps = dispatch => ({
  del: item => dispatch(del(item)),
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
