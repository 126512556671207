import React from 'react';

import { Create, List, Show, Update } from '../components/cart/';
// import { Route } from 'react-router-dom';
import { PrivateLayout as Route } from '../components/PrivateLayout';

export default [
  <Route path="/carts/create" component={Create} exact key="create" />,
  <Route path="/carts/edit/:id" component={Update} exact key="update" />,
  <Route path="/carts/show/:id" component={Show} exact key="show" />,
  <Route path="/carts/" component={List} exact strict key="list" />,
  <Route path="/carts/:page" component={List} exact strict key="page" />
];
