import { combineReducers } from 'redux';

export function userActivateOrDeactivateError(state = null, action) {
  switch (action.type) {
    case 'USER_ACTIVATE_OR_DEACTIVATE_ERROR':
      return action.userActivateOrDeactivateError;

    case 'USER_LIST_RESET':
    case 'USER_ACTIVATE_OR_DEACTIVATE_RESET':
      return null;

    default:
      return state;
  }
}

export function userActivateOrDeactivateLoading(state = false, action) {
  switch (action.type) {
    case 'USER_ACTIVATE_OR_DEACTIVATE_LOADING':
      return action.userActivateOrDeactivateLoading;

    case 'USER_LIST_RESET':
    case 'USER_ACTIVATE_OR_DEACTIVATE_RESET':
      return false;

    default:
      return state;
  }
}

export function userActivateOrDeactivateSuccess(state = null, action) {
  switch (action.type) {
    case 'USER_ACTIVATE_OR_DEACTIVATE_SUCCESS':
      return action.userActivateOrDeactivateSuccess;

    case 'USER_LIST_RESET':
    case 'USER_ACTIVATE_OR_DEACTIVATE_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({
  userActivateOrDeactivateError,
  userActivateOrDeactivateLoading,
  userActivateOrDeactivateSuccess
});
