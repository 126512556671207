import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { reset, retrieve } from '../../actions/account/show';
import {
  attachPaymentMethod,
  cancelSubscription,
  paySubscriptionInvoice,
  reactivateSubscription,
  retrieveSubscription,
  retrieveSubscriptionInvoices,
  updateSubscriptionQuantity
} from '../../actions/account/subscription';
import { getCurrentUserId } from '../../utils/auth';
import StripeSubscription from './StripeSubscription';

class Account extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.retrieveCurrentAccount();
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  retrieveCurrentAccount = () => {
    const userId = getCurrentUserId();
    this.props.retrieve(decodeURIComponent(userId));
  };

  render() {
    const item = this.props.retrieved;
    const location = this.props.location || {};

    return (
      <div className={'user-account'}>
        {/*{this.props.loading && <div>Loading...</div>}*/}
        {this.props.error && (
          <div>
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        {item && (
          <>
            {item.organizationUsers &&
              item.organizationUsers.map(organizationUser => {
                if (
                  organizationUser.organization['@id'] !==
                  item['@_organizationCurrentId']
                ) {
                  return <></>;
                }

                return (
                  <StripeSubscription
                    location={location}
                    user={item}
                    organization={organizationUser.organization}
                    stripeCustomerId={
                      organizationUser.organization.stripeCustomerIdentifier
                    }
                    stripePriceId={
                      organizationUser.organization.stripePriceIdentifier
                    }
                    stripeSubscriptionId={
                      organizationUser.organization.stripeSubscriptionIdentifier
                    }
                    retrieveCurrentAccount={this.retrieveCurrentAccount}
                    retrieveSubscriptionInvoices={
                      this.props.retrieveSubscriptionInvoices
                    }
                    paySubscriptionInvoice={this.props.paySubscriptionInvoice}
                    retrieveSubscription={this.props.retrieveSubscription}
                    updateSubscriptionQuantity={
                      this.props.updateSubscriptionQuantity
                    }
                    cancelSubscription={this.props.cancelSubscription}
                    reactivateSubscription={this.props.reactivateSubscription}
                    attachPaymentMethod={this.props.attachPaymentMethod}
                  />
                );
              })}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.account.show.retrieved,
  error: state.account.show.error,
  loading: state.account.show.loading,
  eventSource: state.account.show.eventSource
});

const mapDispatchToProps = dispatch => ({
  retrieveSubscriptionInvoices: item =>
    dispatch(retrieveSubscriptionInvoices(item)),
  paySubscriptionInvoice: (item, invoiceId) =>
    dispatch(paySubscriptionInvoice(item, invoiceId)),
  retrieveSubscription: item => dispatch(retrieveSubscription(item)),
  updateSubscriptionQuantity: (item, quantity) =>
    dispatch(updateSubscriptionQuantity(item, quantity)),
  cancelSubscription: item => dispatch(cancelSubscription(item)),
  reactivateSubscription: paymentMethod =>
    dispatch(reactivateSubscription(paymentMethod)),
  attachPaymentMethod: paymentMethod =>
    dispatch(attachPaymentMethod(paymentMethod)),
  retrieve: id => dispatch(retrieve(id)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
