import { SubmissionError } from 'redux-form';

import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'ACCOUNT_LOGIN_ERROR', error };
}

export function loading(loading) {
  return { type: 'ACCOUNT_LOGIN_LOADING', loading };
}

export function success(loggedin) {
  return { type: 'ACCOUNT_LOGIN_SUCCESS', loggedin };
}

export function login(values) {
  return dispatch => {
    dispatch(loading(true));

    return fetch('/authentication_token', {
      method: 'POST',
      body: JSON.stringify(values)
    })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => dispatch(success(retrieved)))
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));

    // clear login state to allow quick login/logout
    dispatch(success(null));
  };
}
