import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { create, reset } from '../../actions/procedure/create';
import Form from './CreateForm';

class Create extends Component {
  state = {
    isSticky: false
  };

  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    created: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.props.reset();
    window.removeEventListener('scroll', () => this.handleScroll);
  }

  handleScroll = () => {
    if (this._stickyHeader) {
      this.setState({
        isSticky: window.scrollY > 86
      });
    }
  };

  render() {
    if (this.props.created)
      return (
        <Redirect
          to={`edit/${encodeURIComponent(this.props.created['@id'])}`}
        />
      );

    return (
      <div className={'mt-3 create-name-procedure'}>
        {/*
        <h1>New Procedure</h1>
        */}

        {/*
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        */}
        {this.props.loading && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                Loading...
              </div>
            </div>
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        {/*<div*/}
        {/*  className={`form-actions-wrapper${*/}
        {/*    this.state.isSticky ? ' yellow-sticky' : ''*/}
        {/*  }`}*/}
        {/*  ref={ref => (this._stickyHeader = ref)}*/}
        {/*>*/}
        {/*  <div className={'form-actions'}>*/}
        {/*
            <Link to="." className="btn btn-primary pull-left">
              All Procedure Templates
            </Link>
            */}
        {/*  </div>*/}
        {/*</div>*/}

        <Form onSubmit={this.props.create} values={this.props.item} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { created, error, loading } = state.procedure.create;
  return { created, error, loading };
};

const mapDispatchToProps = dispatch => ({
  create: values => dispatch(create(values)),
  reset: () => dispatch(reset())
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
