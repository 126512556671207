import React from 'react';

//import { Route } from 'react-router-dom';
import { PrivateLayout as Route } from '../components/PrivateLayout';
import { Create, List, Show, Update } from '../components/procedurestep/';

export default [
  <Route
    path="/procedure_steps/create"
    component={Create}
    exact
    key="create"
  />,
  <Route
    path="/procedure_steps/edit/:id"
    component={Update}
    exact
    key="update"
  />,
  <Route path="/procedure_steps/show/:id" component={Show} exact key="show" />,
  <Route path="/procedure_steps/" component={List} exact strict key="list" />,
  <Route
    path="/procedure_steps/:page"
    component={List}
    exact
    strict
    key="page"
  />
];
